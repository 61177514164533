import React, { Component } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import LiveMatrix from "../../../components/liveMatrix";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import AdvertBox from "../../../components/advert/advert";
import "./toptraded.css";

export default class TopTraded extends Component {
  constructor() {
    super();
    this.state = {
      rankingdata: null,
      tab: "topindex",
    };
  }

  tabChange(tab) {
    this.setState({ tab });
  }

  componentDidMount() {
    this.onFetchData();
    document.title = "Top traded warrants | Singapore Warrants | Macquarie"
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("TopTraded");
    if (staticData !== null) {
      this.setState({ rankingdata: staticData });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("TopTraded")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    let rankingdata = null;
    const url = MQSGUtil.getAPIBasePath() + "/MarketDataJSON?type=ranking";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          rankingdata = result;
          this.setState({ rankingdata });
        },
        (error) => {
          this.setState({ rankingdata });
        }
      );
  }

  render() {
    return (
      <div id="toptradewarrant" className="pageobj">
        <BreadcrumbBar
          link="/home/warrantstats"
          group="Warrant statistics"
          title="Top traded warrants"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="topimg hidden-sm hidden-xs">
            <img src="/img/bg/top-toptradeddw.jpg" alt="" />
          </div>
          <div className="row">
            <div className="col-md-8" style={{ paddingLeft: 0 }}>
              <div className="section">
                <div className="page-header">
                  <h2>Top traded warrants</h2>
                </div>
                <p>
                  Shows the top traded warrants with the highest volume of
                  today.
                </p>
                <div className="tablist">
                  <div id="topTabs" className="tablist-left">
                    <table>
                      <tbody>
                        <tr>
                          <td
                            className={classNames({
                              active: this.state.tab === "topindex",
                            })}
                            onClick={() => this.tabChange("topindex")}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Top 10 index warrants
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tab === "topstock",
                            })}
                            onClick={() => this.tabChange("topstock")}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Top 10 stock warrants
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="indicator hidden-xs hidden-sm">
                  <ul className="indicator-list list-unstyled list-inline">
                    <li>
                      <div className="indicator-item small">
                        <span className="icon-liquidity indicator-item-icon"></span>
                        <span className="ttip" data-target="highestliquidity">
                          Highest liquidity
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="indicator-item small">
                        <span className="icon-expiry indicator-item-icon"></span>
                        <span className="ttip" data-target="nearexpiry">
                          Near expiry
                        </span>
                      </div>
                    </li>

                    <li>
                      <div className="indicator-item small">
                        <span className="icon-responsive indicator-item-icon"></span>
                        <span className="ttip" data-target="responsive">
                          Responsive
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="indicator-item small">
                        <span className="icon-hotdw indicator-item-icon"></span>
                        <span className="ttip" data-target="hotdw">
                          Trending warrants
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* 2tab */}
                <div className="tabcontent-holder" style={{ overflowX: "scroll" }}>
                  <TopWarrant
                    rankingdata={this.state.rankingdata}
                    tab={this.state.tab}
                  />
                </div>
                <div className="indicator visible-sm visible-xs">
                  <ul className="indicator-list list-unstyled list-inline">
                    <li style={{ paddingRight: "10px" }}>
                      <div className="indicator-item small">
                        <span className="icon-liquidity indicator-item-icon"></span>
                        <span className="ttip" data-target="highestliquidity">
                          Highest liquidity
                        </span>
                      </div>
                    </li>
                    <li style={{ paddingRight: "10px" }}>
                      <div className="indicator-item small">
                        <span className="icon-expiry indicator-item-icon"></span>
                        <span className="ttip" data-target="nearexpiry">
                          Near expiry
                        </span>
                      </div>
                    </li>

                    <li style={{ paddingRight: "10px" }}>
                      <div className="indicator-item small">
                        <span className="icon-responsive indicator-item-icon"></span>
                        <span className="ttip" data-target="responsive">
                          Responsive
                        </span>
                      </div>
                    </li>
                    <li style={{ paddingRight: "10px" }}>
                      <div className="indicator-item small">
                        <span className="icon-hotdw indicator-item-icon"></span>
                        <span className="ttip" data-target="hotdw">
                          Trending warrants
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <p className="small">
                  The above price is the indicative value.
                  <br />
                  <br />
                  All market data is delayed 15 mins unless otherwise indicated
                  by timestamp.
                </p>
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4" style={{ padding: 0 }}>
              <LiveMatrix />
              <Calculator />
              <AdvertBox />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class TopWarrant extends Component {
  getRows() {
    const rows = [];
    const tab = this.props.tab;
    const topdata = MQSGUtil.getData(this.props.rankingdata, tab, null);
    if (topdata !== null && Array.isArray(topdata)) {
      topdata.forEach((data, index) => {
        const ticker = MQSGUtil.getData(data, "ticker");
        const indicatorclassname = MQSGUtil.getIndicatorClassName(data);
        const indicatorclassnamespan = indicatorclassname.map((classname) => {
          return (
            <span
              className={"indicator-item-icon " + classname}
              key={classname}
            ></span>
          );
        });
        const underlyingticker = MQSGUtil.getData(data, "underlying_ticker");
        const underlyingtickerdisplayname =
          MQSGUtil.getUnderlyingTickerDisplayName(underlyingticker);
        const acvol1 = MQSGUtil.getNumber(data, "ACVOL_1", "", 0, 1000, true);
        const bid = MQSGUtil.getData(data, "BID");
        const type = MQSGUtil.getData(data, "type");
        const exerciseprice = MQSGUtil.getData(data, "exercise_price");
        const pctchng = MQSGUtil.getData(data, "BID_PCTCHNG");
        const pctchngString = MQSGUtil.signedString(pctchng, "", "");
        const pctchngupdown = MQSGUtil.getUpDownClass(pctchng);
        const lasttradingdate = MQSGUtil.getData(data, "last_trading_date");
        const issuercode = MQSGUtil.getData(data, "issuer_code");

        const column1 = (
          <td className="col ticker">
            <a href={"/tools/livematrix/" + ticker}>{ticker}</a>
          </td>
        );
        const column2 = (
          <td className="col indicator">{indicatorclassnamespan}</td>
        );
        const column3 = (
          <td className="col underlying_name hidden-xs">
            <a href={"/tools/underlying/" + underlyingticker}>
              {underlyingtickerdisplayname}
            </a>
          </td>
        );
        const column4 = <td className="col ACVOL_1  hidden-xs">{acvol1}</td>;
        const column5 = <td className="col BID">{bid}</td>;
        const column6 = <td className="col type">{type}</td>;
        const column7 = <td className="col exercise_price">{exerciseprice}</td>;
        const column8 = (
          <td className={"col BID_PCTCHNG hidden-xs " + pctchngupdown}>
            {pctchngString}
          </td>
        );
        const column9 = (
          <td className="col last_trading_date">{lasttradingdate}</td>
        );
        const column10 = (
          <td className="col issuer_code hidden-xs">{issuercode}</td>
        );

        const row = (
          <tr className="rowtmpl" key={this.props.tab + "_" + index}>
            {column1}
            {column2}
            {column3}
            {column4}
            {column5}
            {column6}
            {column7}
            {column8}
            {column9}
            {column10}
          </tr>
        );
        rows.push(row);
      });
    }
    return rows;
  }

  render() {
    const rows = this.getRows();
    return (
      <div id="topTbl">
        <table className="table table-striped table-closed">
          <thead>
            <tr className="rowtmpl_h">
              <th>
                Warrant
                <br />
                code
              </th>
              <th></th>
              <th className="hidden-xs">Underlying</th>
              <th className="hidden-xs">
                Traded
                <br />
                volume
                <br />
                ('000)
              </th>
              <th>
                Bid
                <br />
                (SGD)
              </th>
              <th>Type</th>
              <th>Ex. price</th>
              <th className="hidden-xs">
                Price
                <br />
                change
                <br />
                (%)
              </th>
              <th>
                Last
                <br />
                trading date
              </th>
              <th className="hidden-xs">Issuer</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

class Calculator extends Component {
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Warrant calculator</h2>
        </div>
        <p className="section-p">
          Estimates the theoretical price of a warrant based on your input of
          the underlying price, time, or implied volatility.
        </p>
        <div className="section-qlink-img section-qlink-dwcalculator">
          <Link
            to="/tools/warrantcalculator"
            className="section-qlink-achor"
          >
            <img src="/img/bullet/qlink-achor.png" alt="" />
          </Link>
        </div>
      </div>
    );
  }
}

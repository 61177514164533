import React, { Component } from "react";
import { Link } from "react-router-dom";
// import AmCharts from "@amcharts/amcharts3-react";
import classNames from "classnames";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import PageHeader from "../../../components/pageHeader/pageHeader";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import WarrantCalculator from "../warrantcalculator/calculator";
import AdvertBox from "../../../components/advert/advert";
import "./term.css";
// import "../livematrix/livematrix.css";
import highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import nodata from "highcharts/modules/no-data-to-display";
nodata(highcharts);
import DatePicker from "react-datepicker";

export default class Warrantterms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticker: null,
      tabNum: 4,
      selected: {
        period: "Y",
        underlyingric: null,
        underlyingcurr: "",
        ric: null,
        symbol: null,
        lastupdated: null,
      },
      symbolsdata: null,
      warrantdata: null,
      chartdata: null,
      isLoading: true,
      isLoadingSymbol: true,
      isLoadingChart: true,
      isLoadingWarrant: true,

    };
    this.logic = null;
    this.initRedirect(props, this.state);
  }

  componentDidMount() {
    this.onFetchSymbol();

    if (this.props.location.query) {
      this.setState({ tabNum: 1 });
    } else {
      var tabNum = sessionStorage.getItem('tab2');
      if (!tabNum) {
        tabNum = 4
      }
      this.setState({ tabNum });
    }
    document.title = "Warrant Terms | Singapore Warrants | Macquarie"
  }
  componentWillUnmount() {
    sessionStorage.removeItem('tab1')
    sessionStorage.removeItem('tab2')
    sessionStorage.removeItem('tab3')
  }
  onFetchData(ric) {
    if (MQSGUtil.isUsingStaticData("LiveMatrix")) {
      setTimeout(() => {
        this.onFetchStaticData(ric);
      }, 100); // pretend fetch
      return;
    }
    const isLoading = false;
    const url =
      MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=" + ric + "&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const livematrixdata = result;
          this.logic = new LivematrixLogic(
            livematrixdata.livematrix,
            livematrixdata.ric_data
          );
          this.setState({ livematrixdata, isLoading }, () => {
            // const underlyingric = this.state.livematrixdata.ric_data.underlying_ric;
            // this.onFetchChartData(
            //   underlyingric,
            //   ric,
            //   this.state.selected.period
            // );
          });
        },
        (error) => {
          this.setState({ livematrixdata: null, isLoading });
        }
      );
  }
  initRedirect(props, state) {
    const match = MQSGUtil.getData(props, "match", null);
    if (match !== null) {
      const params = MQSGUtil.getData(match, "params", null);
      if (params !== null) {
        const ticker = MQSGUtil.getData(params, "ticker", null);
        if (ticker !== null) {
          state["ticker"] = ticker.toUpperCase();
        }
      }
    }
  }

  onFetchStaticSymbol() {
    const isLoadingSymbol = false;
    const staticData = MQSGUtil.getStaticData("Warrantterms");
    this.setState({ symbolsdata: staticData.symbols, isLoadingSymbol });
    const symbol = { value: "AEML_tk.SI", label: "AEM MB eCW210302 (983W)" };
    this.onWarrantChange(symbol);
  }

  onFetchSymbol() {
    if (MQSGUtil.isUsingStaticData("Warrantterms")) {
      this.onFetchStaticSymbol();
      return;
    }
    const isLoadingSymbol = false;
    let symbolsdata = null;
    const url = MQSGUtil.getAPIBasePath() + "/SymbolsJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          if ("symbols" in result && Array.isArray(result.symbols)) {
            symbolsdata = result;
            const initSymbol = this.initSymbol(symbolsdata);
            this.onWarrantChange(initSymbol);
          }
          this.setState({ symbolsdata, isLoadingSymbol });
        },
        (error) => {
          this.setState({ symbolsdata, isLoadingSymbol });
        }
      );
  }

  onFetchStaticChartData(underlyingric, ric, period = "D") {
    const isLoadingChart = false;
    const staticData = MQSGUtil.getStaticData("Warrantterms");
    let chartdata = null;
    if (ric in staticData) {
      const termdataAll = staticData[ric];
      if (period in termdataAll) {
        chartdata = termdataAll[period];
      }
    }
    this.setState({ chartdata, isLoadingChart });
  }

  onFetchChartData(underlyingRic, ric, period = "D") {
    if (MQSGUtil.isUsingStaticData("Warrantterms")) {
      setTimeout(() => {
        this.onFetchStaticChartData(underlyingRic, ric, period);
      }, 100); // pretend fetch
      return;
    }
    const isLoadingChart = false;
    const ricString = underlyingRic + ";" + ric;
    let chartdata = null;
    const url =
      MQSGUtil.getAPIBasePath() +
      "/alert?TYPE=JSON&RIC=" +
      ricString +
      "&PERIODICITY=" +
      period +
      "&d=" +
      Date.now();
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          chartdata = result;
          this.setState({ chartdata, isLoadingChart });
        },
        (error) => {
          this.setState({ chartdata, isLoadingChart });
        }
      );
  }

  onFetchStaticWarrantData(ric) {
    const isLoadingWarrant = false;
    const staticData = MQSGUtil.getStaticData("Warrantterms");
    let warrantdata = null;
    if (ric in staticData) {
      warrantdata = staticData[ric];
      const underlyingric = this.getUnderlyingRic(ric, warrantdata);
      if (underlyingric !== null) {
        const selectedNew = { ...this.state.selected };
        selectedNew["underlyingric"] = underlyingric;
        selectedNew["lastupdated"] = this.getLastupdated(warrantdata);
        const underlyingcurr = this.getData(
          "underlying_curr",
          warrantdata[ric]
        );
        if (underlyingcurr !== null) {
          selectedNew["underlyingcurr"] = underlyingcurr;
        }
        this.setState({ selected: selectedNew, warrantdata, isLoadingWarrant });
        this.onFetchStaticChartData(
          underlyingric,
          ric,
          this.state.selected.period
        );
      }
    }
  }

  onFetchWarrantData(ric) {
    if (MQSGUtil.isUsingStaticData("Warrantterms")) {
      setTimeout(() => {
        this.onFetchStaticWarrantData(ric);
      }, 100); // pretend fetch
      return;
    }
    const isLoadingWarrant = false;
    let warrantdata = null;
    const url =
      MQSGUtil.getAPIBasePath() + "/MarketDataJSON?type=warrantdata&ric=" + ric;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          warrantdata = result;
          const underlyingric = this.getUnderlyingRic(ric, warrantdata);
          if (underlyingric !== null) {
            const selectedNew = { ...this.state.selected };
            selectedNew["underlyingric"] = underlyingric;
            selectedNew["lastupdated"] = this.getLastupdated(warrantdata);
            const underlyingcurr = this.getData(
              "underlying_curr",
              warrantdata[ric]
            );
            if (underlyingcurr !== null) {
              selectedNew["underlyingcurr"] = underlyingcurr;
            }
            this.setState({
              selected: selectedNew,
              warrantdata,
              isLoadingWarrant,
            });
            this.onFetchChartData(
              underlyingric,
              ric,
              this.state.selected.period
            );
          }
        },
        (error) => {
          this.setState({ warrantdata, isLoadingWarrant });
        }
      );
  }

  initSymbol(symbolsdata) {
    const symbols = symbolsdata.symbols;
    let lastselection = MQSGUtil.getLastSelection(this.page);
    let selected = null;
    if (symbols.length > 0) {
      if (this.state.ticker === null) {
        if (this.props.location.state !== undefined) {
          selected = {
            label:
              this.props.location.state.name +
              " (" +
              this.props.location.state.security_code +
              ")",
            value: this.props.location.state.ric,
          };
        } else if (lastselection != null) {
          symbols.forEach((symbol) => {
            if ("ric" in symbol) {
              if (lastselection === symbol.ric) {
                selected = {
                  label: symbol.name + " (" + symbol.security_code + ")",
                  value: symbol.ric,
                };
              }
            }
          });
        } else {
          selected = {
            label: symbols[0].name + " (" + symbols[0].security_code + ")",
            value: symbols[0].ric,
          };
        }
      } else {
        symbols.forEach((symbol) => {
          if (this.state.ticker === symbol.security_code) {
            selected = {
              label: symbol.name + " (" + symbol.security_code + ")",
              value: symbol.ric,
            };
          }
        });
        if (selected == null) {
          selected = {
            label: symbols[0].name + " (" + symbols[0].security_code + ")",
            value: symbols[0].ric,
          };
        }
      }
    }
    if (selected == null) {
      selected = {
        label: symbols[0].name + " (" + symbols[0].security_code + ")",
        value: symbols[0].ric,
      };
    }
    return selected;
  }

  getData(fieldName, data) {
    if (fieldName in data) {
      return data[fieldName];
    }
    return null;
  }

  getSymbols() {
    if (this.state.symbolsdata !== null) {
      if (
        "symbols" in this.state.symbolsdata &&
        Array.isArray(this.state.symbolsdata["symbols"])
      ) {
        return this.state.symbolsdata["symbols"];
      }
    }
    return [];
  }

  getWarrantData(ric) {
    if (this.state.warrantdata !== null) {
      if (ric in this.state.warrantdata) {
        return this.state.warrantdata[ric];
      }
    }
    return null;
  }

  getLastupdated(warrantdata) {
    if ("systemtime" in warrantdata) {
      return warrantdata["systemtime"];
    }
    return "";
  }

  getUnderlyingRic(ric, warrantdata) {
    if (warrantdata !== null) {
      if (ric in warrantdata) {
        const underlyingric = this.getData("underlying_ric", warrantdata[ric]);
        if (underlyingric !== null) {
          return underlyingric;
        }
      }
    }
    return null;
  }

  onWarrantChange(symbol) {
    const selectedNew = { ...this.state.selected };
    selectedNew["symbol"] = symbol;
    selectedNew["ric"] = symbol.value;
    this.setState({
      selected: selectedNew,
      isLoadingWarrant: true,
      warrantdata: null,
    });
    this.onFetchWarrantData(symbol.value);
    this.onFetchData(symbol.value);
  }

  onPeriodChange(period) {
    if (this.state.warrantdata !== null) {
      const ric = this.state.selected.ric;
      const underlyingric = this.state.selected.underlyingric;
      if (underlyingric !== null) {
        const selectedNew = { ...this.state.selected };
        selectedNew["period"] = period;
        this.setState({ selected: selectedNew, chartdata: null });
        this.onFetchChartData(underlyingric, ric, period);
      }
    }
  }
  setTab(i) {
    if (i != this.state.tabNum) {

      this.setState({
        tabNum: i
      }, () => {
        sessionStorage.setItem('tab2', i)
      })
    }
  }
  childMethod() {
    if (this.refs.child) {
      this.refs.child.onClickTradeWarrantsHere()
    }
  }
  render() {
    let ric = null;
    if (
      typeof this.state.selected !== "undefined" &&
      this.state.selected !== null
    ) {
      if ("ric" in this.state.selected) {
        ric = this.state.selected.ric;
      }
    }
    var isShow = false;
    if (this.state.tabNum == 2) {
      isShow = true
    } else {
      isShow = false
    }
    return (
      <div id="dwterms" className="pageobj" onClick={() => this.childMethod()}>
        <BreadcrumbBar
          link="/home"
          group="Warrant tools"
          title="Warrant terms"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <PageHeader title="Warrant terms" ref="child" />
                <p>
                  All the key parameters and terms about your selected warrants
                  are found here.
                </p>
                <Warrant
                  symbols={this.getSymbols()}
                  onWarrantChange={(selected) => this.onWarrantChange(selected)}
                />
                {/* 导航 */}
                <div className="tablist">
                  <div id="topTabs" className="tablist-left" style={{ overflowX: 'auto' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 1,
                            })}
                            onClick={() => this.setTab(1)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Historical Performance
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 4,
                            })}
                            onClick={() => this.setTab(4)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Warrant terms
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 2,
                            })}
                            onClick={() => this.setTab(2)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Warrant Calculator
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 3,
                            })}
                            onClick={() => this.setTab(3)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Live matrix
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* summary */}
                {/* <div style={{ display: this.state.tabNum != 3 ? 'block' : 'none' }}> */}
                <TermsSummary
                  tabNum={this.state.tabNum}
                  warrantdata={this.getWarrantData(this.state.selected.ric)}
                />
                {/* </div> */}

                {/* <TableGeneral
                  warrantdata={this.getWarrantData(this.state.selected.ric)}
                /> */}
                {/* chart图区域 */}
                {/* <TermsChart
                  selected={this.state.selected}
                  chartdata={this.state.chartdata}
                  onPeriodChange={this.onPeriodChange.bind(this)}
                /> */}
                {/* 两块table数据 */}
                {/* <IndtableShow
                  warrantdata={this.getWarrantData(this.state.selected.ric)}
                /> */}
                <div style={{ display: this.state.tabNum == 1 ? 'block' : 'none' }}>
                  <TermsChart
                    selected={this.state.selected}
                    period={this.state.period}
                    chartdata={this.state.chartdata}
                    onPeriodChange={this.onPeriodChange.bind(this)}

                  />
                </div>
                {
                  // this.state.tabNum == 1
                  //   ? <TermsChart
                  //     selected={this.state.selected}
                  //     chartdata={this.state.chartdata}
                  //     onPeriodChange={this.onPeriodChange.bind(this)}
                  //   />
                  //   : 
                  this.state.tabNum == 3 ? <LiveMatrixTodayTable selected={this.state.selected} tabNum={this.state.tabNum} logic={this.logic} isLoading={this.state.isLoading} /> : this.state.tabNum == 4 ? <IndtableShow
                    warrantdata={this.getWarrantData(this.state.selected.ric)}
                  /> : null
                }
                {/* Calculator */}
                <div style={{ display: isShow ? 'block' : 'none' }} >
                  <WarrantCalculator isRightPanel={true} ric={ric} />
                </div>
                {/* 文字说明 */}
                <p className="small">
                  Last updated:{" "}
                  <span id="lastupdate">{this.state.selected.lastupdated}</span>
                </p>
                <div className="disclaimer hidden-xs hidden-sm">
                  <p className="small">
                    All market data is delayed 15 mins unless otherwise
                    indicated by timestamp.
                  </p>
                  <p className="small">
                    The warrant price displayed in prior, Open, High and low is
                    based on the "bid price for the warrant rather than the
                    "traded price". the intraday graph also uses the warrant bid
                    price to generate the warrant price history. We do this to
                    provide a more accurate representation of the warrant value
                    at each particular point in time and a more accurate
                    reflection of warrant price changes. Warrants do not trade
                    as frequently as shares and sometimes may not record a trade
                    for a long period of time though the bid/offer prices may
                    continue to move. Therefore using the bid price is usually
                    the best indicator of warrant value. For a more detailed
                    description&nbsp;
                    <Link
                      className="text-lowercase"
                      style={{
                        color: "#0675C2",
                        fontFamily: "HelveticaNeueMedium",
                      }}
                      target="_blank"
                      to="/InvestorAcademy"
                    >
                      Click here
                    </Link>
                    .
                  </p>
                  <hr className="hr-hmgbm" />
                </div>
                {/* 手机端展示 */}
                <PanelGroup />
              </div>
            </div>
            <div className="col-md-4 hidden-xs hidden-sm">
              <AdvertBox />
              {/* <WarrantCalculator isRightPanel={true} ric={ric} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class WarranttermsLogic {
  getData(data, fieldName, toNumber = false) {
    if (fieldName in data) {
      const value = data[fieldName];
      if (toNumber) {
        if (typeof value === "string") {
          return Number(value.replace(/,/g, ""));
        }
      }
      return value;
    }
    return null;
  }

  removeLeadingZeroValue(dataarr) {
    let firstNonZeroValue = "0.00";
    let firstNonZeroIndex = 0;
    if (dataarr.length > 0) {
      for (let i = 0; i < dataarr.length; i++) {
        let item = dataarr[i].split(";");
        let value = item[1];
        if (value && value.length > 1 && Number(value) !== 0) {
          firstNonZeroIndex = i;
          firstNonZeroValue = value;
          break;
        }
      }
      if (firstNonZeroIndex > 0) {
        for (let j = 0; j < firstNonZeroIndex; j++) {
          var oldItem = dataarr[j].split(";");
          dataarr[j] = oldItem[0] + ";" + firstNonZeroValue;
        }
      }
      return dataarr;
    }
    return null;
  }

  preprocess(data, period) {
    let dataarr = data.trim().split("=");
    if (period !== "D") {
      if (dataarr.length > 0) {
        dataarr = dataarr.slice(1, dataarr.length);
      }
    }
    return dataarr;
  }

  handleDatetimeString(datetimeString, ric, period) {
    var hr = 16;
    var min = 58;
    if (ric.endsWith(".HK")) {
      hr = 15;
      min = 58;
    } else if (ric.startsWith("HSI")) {
      hr = 16;
      min = 28;
    }

    let formattedDatatimeString = datetimeString;
    const d = new Date(datetimeString);
    if (period === "Y") {
      // Y case: 2020-02-17
      formattedDatatimeString =
        String("00" + d.getDate()).slice(-2) +
        "/" +
        String("00" + (d.getMonth() + 1)).slice(-2) +
        "/" +
        String("00" + d.getFullYear()).slice(-2);
    } else if (period !== "D") {
      // M case: 2021-01-18
      formattedDatatimeString =
        String("00" + d.getDate()).slice(-2) +
        "/" +
        String("00" + (d.getMonth() + 1)).slice(-2);
    } else {
      // D case: 09:00
      const time = datetimeString.split(":");
      if (
        parseInt(time[0]) > hr ||
        (parseInt(time[0]) === hr && parseInt(time[1]) > min)
      ) {
        formattedDatatimeString = null;
      }
    }
    return formattedDatatimeString;
  }

  handleMinMaxOpen(minMaxOpen, value) {
    minMaxOpen.gmin =
      minMaxOpen.gmin === 0 ? value : Math.min(minMaxOpen.gmin, value);
    minMaxOpen.gmax =
      minMaxOpen.gmax === 0 ? value : Math.max(minMaxOpen.gmax, value);
    minMaxOpen.gopen = minMaxOpen.gopen === 0 ? value : minMaxOpen.gopen;
  }

  parseAxisData(datapoints, ric, period, index, dataset) {
    const minMaxOpen = { gmin: 0, gmax: 0, gopen: 0 };
    datapoints.forEach((element) => {
      const dt = element.split(";");
      let dp = {};
      if (dt.length === 2) {
        const datetimeString = dt[0];
        const value = dt[1];
        const columnLabel = "column-" + (index + 1);
        if (datetimeString in dataset) {
          dp = dataset[datetimeString];
        } else {
          // dp["date"] = this.handleDatetimeString(datetimeString, ric, period);
          if (period === "D") {
            var time = "";
            var date = new Date();
            var YY = date.getFullYear() + "-";
            var MM =
              (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-";
            var DD =
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hh =
              (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
              ":";
            var mm =
              (date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes()) + ":";
            var ss =
              date.getSeconds() < 10
                ? "0" + date.getSeconds()
                : date.getSeconds();
            time = YY + MM + DD + " ";
            dp["date"] = new Date(time + datetimeString).getTime();
          } else {
            dp["date"] = new Date(datetimeString).getTime() + 3600000;
            dp["date"] = new Date(datetimeString).getTime();
          }
        }
        dp[columnLabel] = null;
        if (value !== "" && !isNaN(value) && value >= 0) {
          this.handleMinMaxOpen(minMaxOpen, value);
          dp[columnLabel] = value;
        }
        if (dp["date"] !== null && dp[columnLabel] !== null) {
          dataset[datetimeString] = dp;
        }
      }
    });
    minMaxOpen.gmin = minMaxOpen.gmin <= 0 ? 0 : minMaxOpen.gmin;
    const valueAxes = {
      maximum: minMaxOpen.gmax,
      minimum: minMaxOpen.gmin,
      open: minMaxOpen.gopen,
    };
    return valueAxes;
  }

  parseChartData(data, ric, period) {
    let result = null;
    if (data !== null) {
      const datasetorg = this.preprocess(data, period);
      if (datasetorg !== null) {
        result = {
          dataProvider: [],
          valueAxes: [],
        };
        const dataset = {};
        datasetorg.forEach((datasubset, index) => {
          const datapoints = this.removeLeadingZeroValue(datasubset.split("|"));
          const valueAxes = this.parseAxisData(
            datapoints,
            ric,
            period,
            index,
            dataset
          );
          if (valueAxes !== null) {
            result.valueAxes.push(valueAxes);
          }
        });
        const datetime = Object.keys(dataset);
        datetime.sort();
        for (var i = 0; i < datetime.length; i++) {
          result.dataProvider.push(dataset[datetime[i]]);
        }
      }
    }
    return result;
  }
}

class Warrant extends React.Component {
  onChange(selected) {
    // this.props.onWarrantChange(selected);
    window.location.href = '/tools/warrantterms/' + selected.security_code
  }

  getSymbol() {
    const options = [];
    if (this.props.symbols !== null && Array.isArray(this.props.symbols)) {
      this.props.symbols.forEach((symbol) => {
        if ("ric" in symbol && "name" in symbol && "security_code" in symbol) {
          const option = {
            label: symbol.name + " (" + symbol.security_code + ")",
            value: symbol.ric,
            security_code: symbol.security_code
          };
          options.push(option);
        }
      });
    }
    return options;
  }

  render() {
    const style = {}; //{margin:'20px 0 30px 0'}
    return (
      <div style={style}>
        <Select
          placeholder="Select warrant"
          options={this.getSymbol()}
          onChange={(selected) => this.onChange(selected)}
          styles={{
            option: (provided, state) => ({
              ...provided,
              textAlign: "center",
              backgroundColor: '#FFFFFF',
              color: '#000',
              ':hover': {
                backgroundColor: '#1A73E8',
              },
            }),

            valueContainer: (provided) => ({
              ...provided,
              justifyContent: 'center'
            })
          }}
        />
      </div>
    );
  }
}

class TermsSummary extends Component {
  getData(data, fieldName) {
    if (data !== null && fieldName in data) {
      return data[fieldName];
    }
    return "";
  }

  // Returning either "upval", "downval", or "" as the CSS class for displaying these up, down or no-change value
  getUpDownClass(nc) {
    if (nc) {
      const ncstring = nc.toString();
      const ncval = Number(nc);

      // Check if 0
      if (ncval === 0) {
        return "";
      }
      // If -nnn ?
      else if (ncstring.length > 1 && ncstring.startsWith("-")) {
        return "val down";
      }
      // If +nnn ?
      else if ((ncstring.length > 1 && ncstring.startsWith("+")) || ncval > 0) {
        return "val up";
      }
    }

    // Any other case, return ""
    return "";
  }

  render() {
    var show = 'table';
    if (this.props.tabNum == 1 || this.props.tabNum == 3) {
      show = 'none'
    }
    const warrantdata = this.props.warrantdata;
    const ticker =
      this.getData(warrantdata, "dsply_nmll") +
      " (" +
      this.getData(warrantdata, "ticker") +
      ")";
    const bidval = this.getData(warrantdata, "BID");
    const bidchg = this.getData(warrantdata, "BID_NETCHNG");
    const bidchgupdown = this.getUpDownClass(bidchg);
    const bidpchg = this.getData(warrantdata, "BID_PCTCHNG") + "%";
    const bidpchgupdown = this.getUpDownClass(bidpchg);

    return (
      <div id="dwsummary" style={{ position: "relative" }}>
        <div className="ricbar">
          <div id="ticker" className="tickerbar bgcolor-03">
            {ticker}
          </div>
          {/* <div>
            <a
              href="/tools/livematrix"
              id="viewlm"
              type="button"
              className="ricbar-btn btn btn-block btn-primary"
            >
              View live matrix
            </a>
          </div> */}
        </div>
        <table id="pricetbl" className="uppertbl" style={{ display: show }}>
          <colgroup>
            <col style={{ backgroundColor: "#E7E8E8", padding: "3px" }} />
            <col
              style={{
                backgroundColor: "#F5F6F7",
                padding: "3px",
                textAlign: "left",
              }}
            />
            <col
              style={{
                backgroundColor: "#F5F6F7",
                padding: "3px",
                textAlign: "right",
              }}
            />
          </colgroup>
          <tbody>
            <tr>
              <td className="uppertbl-col-bid" rowSpan="2" style={{ textAlign: 'left' }}>
                <div className="bidprice lbl">Bid price</div>
                <div id="bidval" className="bidprice val">
                  {bidval}
                </div>
              </td>
              <td className="uppertbl-col-chglbl">Change</td>
              <td id="bidchg" className={"uppertbl-col-chgval " + bidchgupdown}>
                {bidchg}
              </td>
            </tr>
            <tr>
              <td className="uppertbl-col-pchglbl">%Change</td>
              <td
                id="bidpchg"
                className={"uppertbl-col-pchgval " + bidpchgupdown}
              >
                {bidpchg}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

class TermsChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      minDate: new Date(),
      MaxDate: new Date(),
      chartData: null,
      warrantPrice: '-',
      Underlying: '-',
      day: '-',
      last: '-',
      last1: '-',
      from: '-',
      from1: '-',
      fixedStartDate: '-',
      fixedEndDate: '-',
      switch: false,
      lastRic: true
    };
  }
  onPeriodChange(period) {
    this.props.onPeriodChange(period);
  }
  componentWillReceiveProps(props) {
    // if (props.chartdata != null) {
    if (props.chartdata) {
      if (props.chartdata == this.props.chartdata) {
        return false;
      }
      if (props.chartdata.data.length == 0 && props.chartdata.data1.length == 0) {
        return false;
      }
      this.chart.current.chartRef.current.chart.xAxis[0].update({ min: null, max: null })
      var extremes = this.chart.current.chartRef.current.chart.xAxis[0].getExtremes();
      var arrMin = props.chartdata.data[0].time.replace(' 00:00', '');
      var arrMax = props.chartdata.data[props.chartdata.data.length - 1].time.replace(' 00:00', '');
      var arrMin2 = props.chartdata.data1.length > 0 ? props.chartdata.data1[0].time.replace(' 00:00', '') : '';
      var arrMax2 = props.chartdata.data1.length > 0 ? props.chartdata.data1[props.chartdata.data1.length - 1].time.replace(' 00:00', '') : '';
      var min = '';
      var max = '';
      // if (new Date(arrMin) < new Date(arrMin2)) {
      //   min = arrMin
      // } else {
      //   min = arrMin2
      // }
      // if (new Date(arrMax) < new Date(arrMax2)) {
      //   max = arrMax2
      // } else {
      //   max = arrMax
      // }
      // if (props.selected.period == 'Y') {
      //   min = arrMin2
      //   max = arrMax2
      // } else {
      // if (new Date(arrMin) < new Date(arrMin2)) {
      //   min = arrMin
      // } else {
      //   min = arrMin2
      // }
      if (props.chartdata.data1.length < 1) {
        min = arrMin
      } else {
        min = arrMin2
      }
      // min = arrMin2
      if (new Date(arrMax) < new Date(arrMax2)) {
        max = arrMax2
      } else {
        max = arrMax
      }
      // }
      var date = null;
      var currentDate = new Date(max);
      var lastRic = JSON.parse(sessionStorage.getItem('lastRic'))
      // if (props.selected.period == 'Y') {
      //   date = new Date(currentDate.setDate(currentDate.getDate() - 7));
      // } else {
      //   date = new Date(min)
      // }
      if (this.state.switch) {
        var parameterSet = {
          chartData: props.chartdata,
          startDate: this.state.startDate,
          // endDate: new Date(max),
          endDate: this.state.endDate,
          switch: false

        }
      } else {
        // var parameterSet = {
        //   chartData: props.chartdata,
        //   startDate: new Date(min),
        //   endDate: new Date(max),
        // }
        if (lastRic && this.state.lastRic && lastRic.ric == this.props.selected.ric) {
          var parameterSet = {
            chartData: props.chartdata,
            startDate: new Date(lastRic.startDate),
            endDate: new Date(lastRic.endDate),
            lastRic: false
          }
        } else {
          var parameterSet = {
            chartData: props.chartdata,
            startDate: new Date(min),
            endDate: new Date(max),
            lastRic: false
          }
        }
      }
      if (this.state.fixedStartDate == '-' && this.state.fixedEndDate == '-') {
        date = {
          minDate: new Date(min),
          MaxDate: new Date(max),
          fixedStartDate: new Date(min),
          fixedEndDate: new Date(max),
        }
        if (lastRic && lastRic.ric != this.props.selected.ric || lastRic == null) {
          window.setTimeout(() => {
            this.onPeriodChange('W')
          }, 300)
        }
      } else {
        date = {}
      }
      this.setState(Object.assign(parameterSet, date), () => {
        this.changeDate(props.chartdata)
      })
      // this.setState({
      //   chartData: props.chartdata,
      //   minDate: new Date(min),
      //   MaxDate: new Date(max),
      //   startDate: new Date(min),
      //   // startDate: date,
      //   endDate: new Date(max),
      // }, () => {
      //   this.changeDate(props.chartdata)
      // })
    }
  }
  changeDate(chartdata) {
    if (this.props.selected.period != "D") {
      // this.chart.current.chartRef.current.chart.xAxis[0].update({ max: date.getTime(), min: this.state.startDate.getTime() })

      var dataArr = this.chart.current.chartRef.current.chart.series[0].xData;
      var dataArr2 = this.chart.current.chartRef.current.chart.series[1].xData;
      var underlyingMax = undefined;
      var underlyingMin = undefined;
      var warrantMax = undefined;
      var warrantMin = undefined;
      if (dataArr.indexOf(this.state.endDate.getTime()) != -1) {
        underlyingMax = this.chart.current.chartRef.current.chart.series[0].yData[dataArr.indexOf(this.state.endDate.getTime())]
      }
      if (dataArr.indexOf(this.state.startDate.getTime()) != -1) {
        underlyingMin = this.chart.current.chartRef.current.chart.series[0].yData[dataArr.indexOf(this.state.startDate.getTime())]
      }
      if (dataArr2.indexOf(this.state.endDate.getTime()) != -1) {
        warrantMax = this.chart.current.chartRef.current.chart.series[1].yData[dataArr2.indexOf(this.state.endDate.getTime())]
      }
      if (dataArr2.indexOf(this.state.startDate.getTime()) != -1) {
        warrantMin = this.chart.current.chartRef.current.chart.series[1].yData[dataArr2.indexOf(this.state.startDate.getTime())]
      }
      var str = ''
      var str1 = ''
      if (((underlyingMax - underlyingMin) / underlyingMin).toFixed(2) * 100 == Infinity || isNaN(((underlyingMax - underlyingMin) / underlyingMin).toFixed(2) * 100)) {
        str = '-';
      } else {
        str = this.floatMultiply(((underlyingMax - underlyingMin) / underlyingMin).toFixed(3), 100) + '%';
      }
      if (((warrantMax - warrantMin) / warrantMin).toFixed(2) * 100 == Infinity || isNaN(((warrantMax - warrantMin) / warrantMin).toFixed(2) * 100)) {
        str1 = '-';
      } else {
        str1 = this.floatMultiply(((warrantMax - warrantMin) / warrantMin).toFixed(3), 100) + '%';
      }
      var day = (this.state.endDate.getTime() - this.state.startDate.getTime()) / (1000 * 60 * 60 * 24)
      // if (day == 0 || day > 0 && day < 1) {
      //   day = 1
      // }
      if (day < 1) {
        day = 1
      }
      this.setState({
        warrantPrice: str1,
        Underlying: str,
        last: warrantMin ? warrantMin : '-',
        last1: underlyingMin ? underlyingMin : '-',
        from: warrantMax ? warrantMax : '-',
        from1: underlyingMax ? underlyingMax : '-',
        day: Math.ceil(day)
      })
      this.chart.current.chartRef.current.chart.xAxis[0].update({ max: this.state.endDate.getTime(), min: this.state.startDate.getTime() })
    } else {
      var underlyingMax = undefined;
      var underlyingMin = undefined;
      var warrantMax = undefined;
      var warrantMin = undefined;
      underlyingMax = chartdata.data[0].close;
      underlyingMin = chartdata.data[chartdata.data.length - 1].close;
      warrantMax = chartdata.data1[0].close;
      warrantMin = chartdata.data1[chartdata.data1.length - 1].close;
      var str = ''
      var str1 = ''
      if (((underlyingMin - underlyingMax) / underlyingMax).toFixed(2) * 100 == Infinity || isNaN(((underlyingMin - underlyingMax) / underlyingMax).toFixed(2) * 100)) {
        str = '-';
      } else {
        str = this.floatMultiply(((underlyingMin - underlyingMax) / underlyingMax).toFixed(3), 100) + '%';
      }
      if (((warrantMin - warrantMax) / warrantMax).toFixed(2) * 100 == Infinity || isNaN(((warrantMin - warrantMax) / warrantMax).toFixed(2) * 100)) {
        str1 = '-';
      } else {
        str1 = this.floatMultiply(((warrantMin - warrantMax) / warrantMax).toFixed(3), 100) + '%';
      }
      var day = (this.state.endDate.getTime() - this.state.startDate.getTime()) / (1000 * 60 * 60 * 24)
      this.setState({
        warrantPrice: str1,
        Underlying: str,
        // last: warrantMin ? warrantMin : '-',
        // last1: underlyingMin ? underlyingMin : '-',
        // from: warrantMax ? warrantMax : '-',
        // from1: underlyingMax ? underlyingMax : '-',
        from: warrantMin ? warrantMin : '-',
        from1: underlyingMin ? underlyingMin : '-',
        last: warrantMax ? warrantMax : '-',
        last1: underlyingMax ? underlyingMax : '-',
        day: 1
      })
    }
  }
  componentDidMount() {
    this.chart = React.createRef();
    // this.onPeriodChange('Y')
    if (this.props.chartdata != null) {
      // this.changeDate(this.props.chartdata)
    }

  }
  setStartDate(date) {
    if (date != null) {
      if (this.state.startDate.getTime() == date.getTime()) {
        return false;
      }
      var endDate = '';
      var boolean = false;
      if (this.props.selected.period == "D") {
        endDate = this.state.MaxDate
        var time = new Date(date + '')
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var startDate = new Date(y + '-' + m + '-' + d + ' ' + '08:00:00')
      } else {
        endDate = this.state.endDate
        var startDate = date
      }
      if (this.props.selected.period != "Y") {
        this.onPeriodChange('Y')
        boolean = true
      }
      this.setState({
        startDate: startDate,
        switch: boolean,
        endDate: endDate
      }, () => {
        sessionStorage.setItem('lastRic', JSON.stringify({ ric: this.props.selected.ric, startDate: this.state.startDate, endDate: this.state.endDate }))
        this.changeDate(this.props.chartdata)
      })
    }

  }
  floatMultiply(arg1, arg2) {
    if (arg1 == null || arg2 == null) {
      return null;
    }
    var n1, n2;
    var r1, r2; // 小数位数
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    n1 = Number(arg1.toString().replace(".", ""));
    n2 = Number(arg2.toString().replace(".", ""));
    return n1 * n2 / Math.pow(10, r1 + r2);
  }
  setEndDate(date) {
    if (date != null) {
      var boolean = false;
      if (this.props.selected.period != "Y") {
        this.onPeriodChange('Y')
        boolean = true
      }
      this.setState({
        endDate: date,
        switch: boolean
      }, () => {
        sessionStorage.setItem('lastRic', JSON.stringify({ ric: this.props.selected.ric, startDate: this.state.startDate, endDate: this.state.endDate }))
        this.changeDate(this.props.chartdata)
      })
    }

  }
  render() {
    return (
      <div className="termschart" style={{ marginTop: '30px' }}>
        <table id="priceTable" style={{ fontSize: '14px' }}>
          <tbody>
            <tr>
              <td colSpan="4" style={{ backgroundColor: '#DFDBCF', lineHeight: '35px' }}>Warrant vs. Underlying Performance</td>
            </tr>
            <tr style={{ background: '#F5F6F7' }}>
              <td rowSpan="2" className="isShowPC">
                From
                &nbsp;
                <DatePicker
                  // selected={this.props.startDate}
                  selected={this.state.startDate}
                  onChange={(date) => this.setStartDate(date)}
                  minDate={this.state.minDate}
                  maxDate={this.state.endDate}
                  selectsStart
                // value=""
                // dayClassName={(date) =>
                //   this.getbgDate(date)
                //   // getDate(date) < Math.random() * 31 ? "random" : undefined
                // }
                />
              </td>
              <td className="isShowMobile">From</td>
              <td>Warrant Price</td>
              <td>Underlying</td>
              <td>Number of days</td>
            </tr>
            <tr style={{ background: '#F5F6F7' }}>
              <td className="isShowMobile">
                <DatePicker
                  // selected={this.props.startDate}
                  selected={this.state.startDate}
                  onChange={(date) => this.setStartDate(date)}
                  minDate={this.state.minDate}
                  maxDate={this.state.endDate}
                  selectsStart
                // value=""
                // dayClassName={(date) =>
                //   this.getbgDate(date)
                //   // getDate(date) < Math.random() * 31 ? "random" : undefined
                // }
                />
              </td>
              <td>{this.state.last}</td>
              <td>{this.state.last1}</td>
              <td></td>
            </tr>
            <tr>
              <td>
                To
                &nbsp;
                &nbsp;
                &nbsp;
                <DatePicker
                  // selected={this.props.startDate}
                  // selected={new Date()}
                  selectsEnd
                  selected={this.state.endDate}
                  onChange={(date) => this.setEndDate(date)}
                  minDate={this.state.startDate}
                  maxDate={this.state.MaxDate}
                // maxDate={new Date(this.props.chartdata.data[0].time)}
                // value=""
                // dayClassName={(date) =>
                //   this.getbgDate(date)
                //   // getDate(date) < Math.random() * 31 ? "random" : undefined
                // }
                />
              </td>
              <td>{this.state.from}</td>
              <td>{this.state.from1}</td>
              <td></td>
            </tr>
            <tr style={{ background: '#F5F6F7' }}>
              <td style={{ width: '36%' }}>
                {/* <DatePicker
                  // selected={this.props.startDate}
                  selected={this.state.startDate}
                  onChange={(date) => this.setStartDate(date)}
                  minDate={this.state.minDate}
                  maxDate={this.state.endDate}
                  selectsStart
                // value=""
                // dayClassName={(date) =>
                //   this.getbgDate(date)
                //   // getDate(date) < Math.random() * 31 ? "random" : undefined
                // }
                /> */}
                {/* &nbsp;to&nbsp; */}
                {/* <DatePicker
                  // selected={this.props.startDate}
                  // selected={new Date()}
                  selectsEnd
                  selected={this.state.endDate}
                  onChange={(date) => this.setEndDate(date)}
                  minDate={this.state.startDate}
                  maxDate={this.state.MaxDate}
                // maxDate={new Date(this.props.chartdata.data[0].time)}
                // value=""
                // dayClassName={(date) =>
                //   this.getbgDate(date)
                //   // getDate(date) < Math.random() * 31 ? "random" : undefined
                // }
                /> */}
              </td>
              <td style={{ color: this.state.warrantPrice.indexOf('-') != -1 ? 'red' : '#6CC263' }}>{this.state.warrantPrice}</td>
              <td style={{ color: this.state.Underlying.indexOf('-') != -1 ? 'red' : '#6CC263' }}>{this.state.Underlying}</td>
              <td style={{ color: 'red' }}>{this.state.day}</td>
            </tr>
          </tbody>
        </table>
        <table id="periodtab" className="ctab">
          <tbody>
            <tr>
              <td
                className={classNames(
                  { active: this.props.selected.period === "D" },
                  "ctab-item"
                )}
                onClick={() => this.onPeriodChange("D")}
              >
                1D
              </td>
              <td className="ctab-item-space">&nbsp;</td>
              <td
                className={classNames(
                  { active: this.props.selected.period === "W" },
                  "ctab-item"
                )}
                onClick={() => this.onPeriodChange("W")}
              >
                1W
              </td>
              <td className="ctab-item-space">&nbsp;</td>
              <td
                className={classNames(
                  { active: this.props.selected.period === "M" },
                  "ctab-item"
                )}
                onClick={() => this.onPeriodChange("M")}
              >
                1M
              </td>
              <td className="ctab-item-space">&nbsp;</td>
              <td
                className={classNames(
                  { active: this.props.selected.period === "Y" },
                  "ctab-item"
                )}
                onClick={() => this.onPeriodChange("Y")}
              >
                MAX
              </td>
            </tr>
          </tbody>
        </table>
        <div className="chart-legend">
          <div className="row hidden-xs">
            <div className="chart-legend-left col-sm-6">
              <div className="chart-legend-item">
                Warrant bid price (SGD)
                <span className="chart-legend-bar warrant"></span>
              </div>
            </div>
            <div className="chart-legend-right col-sm-6">
              <div className="chart-legend-item">
                Underlying price
                {/* (<span className="underlying_curr">HKD</span>) */}
                <span className="chart-legend-bar underlying"></span>
              </div>
            </div>
          </div>
          <div className="row visible-xs">
            <table className="chart-legend-tbl">
              <tbody>
                <tr>
                  <td>
                    Warrant bid price (<span className="SGD"></span>)
                  </td>
                  <td>
                    <span className="chart-legend-bar warrant"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Underlying price (
                    <span className="underlying_curr">HKD</span>)
                  </td>
                  <td>
                    <span className="chart-legend-bar underlying"></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="chartbox" className="chartbox">
          <Chart
            ric={this.props.selected.underlyingric}
            underlyingcurr={this.props.selected.underlyingcurr}
            period={this.props.selected.period}
            data={this.props.chartdata}
            ref={this.chart}
          />
        </div>
      </div>
    );
  }
}

class Chart extends Component {
  getData(data) {
    var chartData = {
      data1: [],
      data2: [],
      data1Axis: {
        min: null,
        max: null,
      },
      data2Axis: {
        min: null,
        max: null,
      },
    };
    if (data !== null) {
      var arr1 = [];
      var arr2 = [];
      for (var i = 0; i < data.data.length; i++) {
        // chartData.data1.push({
        //   x: new Date(data.data[i]["time"]).getTime(),
        //   y: Number(data.data[i]["close"]),
        // });
        chartData.data1.push([
          this.props.period === "D"
            ? new Date(data.data[i]["time"].replace(' 00:00', '')).getTime() + 28800000
            : new Date(data.data[i]["time"].replace(' 00:00', '')).getTime(),
          Number(data.data[i]["close"]),
        ]);
        arr1.push(Number(data.data[i]["close"]));
      }
      for (var j = 0; j < data.data1.length; j++) {
        // chartData.data2.push({
        //   x: new Date(data.data1[j]["time"]).getTime(),
        //   y: Number(data.data1[j]["close"]),
        // });
        chartData.data2.push([
          this.props.period === "D"
            ? new Date(data.data1[j]["time"].replace(' 00:00', '')).getTime() + 28800000
            : new Date(data.data1[j]["time"].replace(' 00:00', '')).getTime(),
          Number(data.data1[j]["close"]),
        ]);
        arr2.push(Number(data.data1[j]["close"]));
      }
      chartData.data1Axis.min = Math.min.apply(Math, arr1);
      chartData.data1Axis.max = Math.max.apply(Math, arr1);
      chartData.data2Axis.min = Math.min.apply(Math, arr2);
      chartData.data2Axis.max = Math.max.apply(Math, arr2);
      return chartData;
    } else {
      return chartData;
    }
  }
  floatMultiply(arg1, arg2) {
    if (arg1 == null || arg2 == null) {
      return null;
    }
    var n1, n2;
    var r1, r2; // 小数位数
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    n1 = Number(arg1.toString().replace(".", ""));
    n2 = Number(arg2.toString().replace(".", ""));
    return n1 * n2 / Math.pow(10, r1 + r2);
  }
  componentDidMount() {
    this.chartRef = React.createRef();
  }
  render() {
    // const logic = new WarranttermsLogic();
    // const chartdata = logic.parseChartData(
    //   this.props.data,
    //   this.props.ric,
    //   this.props.period
    // );
    var chartdata = this.getData(this.props.data);
    // var dataOne = [];
    // var dataTwo = [];
    // if (chartdata !== null) {
    //   for (var i = 0; i < chartdata.dataProvider.length; i++) {
    //     dataOne.push({
    //       x: chartdata.dataProvider[i]["date"] + 28800000,
    //       y: Number(chartdata.dataProvider[i]["column-1"]),
    //     });
    //     dataTwo.push({
    //       x: chartdata.dataProvider[i]["date"] + 28800000,
    //       y: Number(chartdata.dataProvider[i]["column-2"]),
    //     });
    //   }
    // }
    var that = this;
    const chartOption = {
      lang: {
        noData: "no-data-to-display",
      },
      chart: {
        type: "area",
        // zoomType: "x",
        // events: {
        //   selection: function (event) {
        //     // var std=event.xAxis[0].min+','+event.xAxis[0].max
        //     // var pgpd3=event.yAxis[0].min+','+event.yAxis[0].max
        //     // this.$emit("sendData", std,pgpd3);
        //     if (event.xAxis == undefined) {
        //       this.title.update({ text: '' });
        //       return;
        //     }
        //     var min = null;
        //     var max = null;
        //     var min1 = null;
        //     var max2 = null;
        //     var mum1 = event.xAxis[0].min + "";
        //     var mum2 = event.xAxis[0].max + "";
        //     if (mum1.indexOf('.') != -1) {
        //       mum1 = mum1.slice(0, mum1.indexOf('.'))
        //     }
        //     if (mum2.indexOf('.') != -1) {
        //       mum2 = mum2.slice(0, mum2.indexOf('.'))
        //     }
        //     mum1 = Number(mum1)
        //     mum2 = Number(mum2)
        //     // for (var i = 0; i < chartdata.data1.length; i++) {
        //     //   if (new Date(chartdata.data1[i][0])
        //     //   ) {
        //     //     min = chartdata.data1[i][1]
        //     //   }
        //     if (that.props.period === "D") {
        //       for (var i = 0; i < chartdata.data1.length; i++) {
        //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum1).getFullYear() &&
        //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 &&
        //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum1).getDate() &&
        //           new Date(chartdata.data1[i][0]).getHours() == new Date(mum1).getHours() &&
        //           new Date(chartdata.data1[i][0]).getMinutes() == new Date(mum1).getMinutes()
        //         ) {
        //           min = chartdata.data1[i][1]
        //         }
        //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum2).getFullYear() &&
        //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 &&
        //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum2).getDate() &&
        //           new Date(chartdata.data1[i][0]).getHours() == new Date(mum2).getHours() &&
        //           new Date(chartdata.data1[i][0]).getMinutes() == new Date(mum2).getMinutes()

        //         ) {
        //           max = chartdata.data1[i][1]
        //         }
        //       }
        //       for (var i = 0; i < chartdata.data2.length; i++) {
        //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum1).getFullYear() &&
        //           new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 &&
        //           new Date(chartdata.data2[i][0]).getDate() == new Date(mum1).getDate() &&
        //           new Date(chartdata.data2[i][0]).getHours() == new Date(mum1).getHours() &&
        //           new Date(chartdata.data2[i][0]).getMinutes() == new Date(mum1).getMinutes()

        //         ) {
        //           min1 = chartdata.data2[i][1]
        //         }
        //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum2).getFullYear() &&
        //           new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 &&
        //           new Date(chartdata.data2[i][0]).getDate() == new Date(mum2).getDate() &&
        //           new Date(chartdata.data2[i][0]).getHours() == new Date(mum2).getHours() &&
        //           new Date(chartdata.data2[i][0]).getMinutes() == new Date(mum2).getMinutes()

        //         ) {
        //           max2 = chartdata.data2[i][1]
        //         }
        //       }
        //     } else {
        //       for (var i = 0; i < chartdata.data1.length; i++) {
        //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum1).getFullYear() &&
        //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 &&
        //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum1).getDate()
        //         ) {
        //           min = chartdata.data1[i][1]
        //         }
        //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum2).getFullYear() &&
        //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 &&
        //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum2).getDate()

        //         ) {
        //           max = chartdata.data1[i][1]
        //         }
        //       }
        //       for (var i = 0; i < chartdata.data2.length; i++) {
        //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum1).getFullYear() && new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 && new Date(chartdata.data2[i][0]).getDate() == new Date(mum1).getDate()) {
        //           min1 = chartdata.data2[i][1]
        //         }
        //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum2).getFullYear() && new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 && new Date(chartdata.data2[i][0]).getDate() == new Date(mum2).getDate()) {
        //           max2 = chartdata.data2[i][1]
        //         }
        //       }
        //     }
        //     var str = '';
        //     var str1 = '';
        //     if (((max - min) / min).toFixed(2) * 100 == Infinity || isNaN(((max - min) / min).toFixed(2) * 100)) {
        //       str = '';
        //     } else {
        //       str = 'Underlying Percentage Change: ' + that.floatMultiply(((max - min) / min).toFixed(3), 100) + '%';
        //     }
        //     if (((max2 - min1) / min1).toFixed(2) * 100 == Infinity || isNaN(((max2 - min1) / min1).toFixed(2) * 100)) {
        //       str1 = '';
        //     } else {
        //       str1 = 'Warrant bid percentage change: ' + that.floatMultiply(((max2 - min1) / min1).toFixed(3), 100) + '%';
        //     }
        //     this.title.update({ text: str + '<br/>' + str1 });
        //   },
        // }
      },
      title: {
        text: "",
        useHTML: true,
        style: {
          fontSize: '14px'
        }
      },
      subtitle: {
        text: "",
      },
      credits: {
        text: "",
      },
      xAxis: {
        // allowDecimals: false,
        gridLineColor: "#FAFAFA",
        crosshair: true,
        crosshair: {
          width: 1,
          color: 'red'
        },
        gridLineWidth: 1,
        type: "datetime",
        title: {
          text: "",
        },
        labels: {
          // formatter: function () {
          //   return Highcharts.dateFormat('%y/%m', this.value)
          // }
          formatter: function () {
            var date = '';
            if (that.props.period == 'D') {
              date = Highcharts.dateFormat('%H:%M', this.value)
            } else {
              date = Highcharts.dateFormat('%d/%m', this.value)
            }
            return date
          }
        },
        // tickInterval: 7 * 24 * 3600 * 1000,// one day,
        dateTimeLabelFormats: {
          // day: '%d/%m/%y',
          day: '%y/%m',
        }
      },
      yAxis: [
        {
          // crosshair: true,
          opposite: true,
          labels: {
            formatter: function () {
              return this.value;
            },
          },
          title: {
            text: "",
          },
          lineWidth: 1,
          tickWidth: 1,
          gridLineColor: "#FAFAFA",
          min: chartdata.data2Axis.min,
          max: chartdata.data2Axis.max,
        },
        {
          // crosshair: true,
          labels: {
            formatter: function () {
              return this.value;
            },
          },
          title: {
            text: "",
          },
          lineWidth: 1,
          tickWidth: 1,
          gridLineColor: "#FAFAFA",
          min: chartdata.data1Axis.min,
          max: chartdata.data1Axis.max,
        },
      ],
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        split: true,
      },
      plotOptions: {
        area: {
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
        // series: {
        //   pointInterval: 24 * 3600 * 1000 * 60// one day
        // }
      },
      series: [
        {
          name: "Underlying price",
          yAxis: 1,
          data: chartdata.data1,
          tooltip: {
            pointFormat: "Underlying price : <b>{point.y}</b>",
            split: true,
          },
        },
        {
          name: "Warrant bid price (SGD)",
          data: chartdata.data2,
          color: "#DFC49F",
          tooltip: {
            pointFormat: "Warrant price (SGD) : <b>{point.y}</b>",
            split: true,
          },
        },
      ],
    };
    const style = {
      width: "100%",
      height: "370px",
    };
    return (
      <HighchartsReact
        highcharts={highcharts}
        options={chartOption}
        style={style}
        ref={this.chartRef}
      />
    );
    // return <div style={style} ref="charts"></div>;
  }
}

class IndtableShow extends Component {
  getData(fieldName, dp = null) {
    if (
      this.props.warrantdata !== null &&
      fieldName in this.props.warrantdata
    ) {
      const value = this.props.warrantdata[fieldName];
      if (dp !== null) {
        return Number(value).toFixed(dp);
      }
      return value;
    }
    return "";
  }

  getTheta() {
    // "last_trading_date": "23 Feb 21"
    const lasttradingdate = this.getData("last_trading_date");
    const currDate = new Date();
    const currDateMS = currDate.getTime();
    if (lasttradingdate.length > 0) {
      const lasttradingdateMS = Date.parse(lasttradingdate);
      const diff = currDateMS - lasttradingdateMS;
      // check if expired
      if (diff <= 24 * 60 * 60 * 1000) {
        return this.getData("theta", 4);
      }
    }
    return "-";
  }

  getMoneynessClass(nc) {
    let cnm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        cnm = "val mny up";
      } else if (value === 2) {
        cnm = "val mny down";
      }
    }
    return cnm;
  }

  getMoneynessName(nc) {
    let nm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        return "ITM";
      } else if (value === 2) {
        return "OTM";
      } else {
        return "ATM";
      }
    }
    return nm;
  }

  render() {
    const hstclose = this.getData("HST_CLOSE");
    const trdprc1 = this.getData("TRDPRC_1");
    const openprc = this.getData("OPEN_PRC");
    const acvol1scaled = this.getData("ACVOL_1_SCALED");
    const high1 = this.getData("HIGH_1");
    const turnoverscaled = this.getData("TURNOVER_SCALED");
    const low1 = this.getData("LOW_1");
    const underlyingprice = this.getData("underlying_price");
    const ticker = this.getData("ticker");
    const effectivegearing = this.getData("effective_gearing");
    // const underlyingticker = this.getData("underlying_ticker");  
    const underlyingticker =
      MQSGUtil.getUnderlyingTickerDisplayName(this.getData("underlying_ticker"));
    const delta = this.getData("delta");
    const type = this.getData("type");
    const deltaperwrnt = this.getData("delta_per_wrnt");
    const issuername = this.getData("issuer_name");
    const sensitivity = this.getData("sensitivity");
    const breakevenprice = this.getData("breakeven_price");
    const tradedate = this.getData("TRADE_DATE");
    const premium = this.getData("premium");
    const maturity = this.getData("maturity");
    const impliedvolatility = this.getData("implied_volatility");
    const lasttradingdate = this.getData("last_trading_date");
    const theta = this.getTheta();
    const daystomaturity = this.getData("days_to_maturity") + " days";
    const intrinsicvalue = this.getData("intrinsic_value");
    var convratio = this.getData("conv_ratio");
    var exerciseprice = this.getData("exercise_price");
    if (this.props.warrantdata && this.props.warrantdata.dsply_name.indexOf('Tencent') != -1) {
      convratio = this.getData("conv_ratio_cal").toFixed(3);
      exerciseprice = this.getData("exercise_price_cal").toFixed(3);
    } else if (
      this.props.warrantdata &&
      (this.props.warrantdata.dsply_name.indexOf('SembInd') != -1 ||
        this.props.warrantdata.dsply_name.indexOf('CityDev') != -1 ||
        this.props.warrantdata.dsply_name.indexOf('KepCorp') != -1 ||
        this.props.warrantdata.dsply_name.indexOf('Singtel') != -1
      )
    ) {
      // convratio = this.getData("conv_ratio_cal").toFixed(5);
      convratio = Math.floor(this.getData("conv_ratio_cal") * 100) / 100;
    }
    // const convratio = this.getData("conv_ratio");
    const moneyness = this.getData("moneyness");
    const moneynessClass = this.getMoneynessClass(moneyness);
    const moneynessdisplaystring =
      this.getData("percent_moneyness") +
      "% " +
      this.getMoneynessName(moneyness);

    return (
      <div>
        <div id="srtable" style={{ position: "relative" }}>
          <table className="table priorTable">
            <tbody>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Prior
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HST_CLOSE"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {hstclose}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Last trade
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TRDPRC_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {trdprc1}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ border: 0, textAlign: 'left' }}>
                  Open
                </th>
                <td
                  className="srtable-leftval val OPEN_PRC"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {openprc}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Traded volume ('000)
                </th>
                <td className="srtable-rightval val ACVOL_1_SCALED" style={{ textAlign: 'left' }}>
                  {acvol1scaled}
                </td>
              </tr>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left', textAlign: 'left' }}
                >
                  High
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HIGH_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {high1}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Traded value ('000 SGD)
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TURNOVER_SCALED"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {turnoverscaled}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ textAlign: 'left' }}>Low</th>
                <td className="srtable-leftval val LOW_1 " style={{ border: 0, textAlign: 'left' }}>
                  {low1}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Underlying price
                </th>
                <td
                  className="srtable-rightval val underlying_price"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {underlyingprice}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>Terms</h3>
        <div id="indtable" style={{ position: "relative", overflowX: 'auto' }}>
          <table className="table visible-md visible-lg table-closed table-striped indicator_table TermsTable">
            <tbody>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="dwsymbol"
                    data-hasqtip="0"
                    data-tip
                    data-for="Warrant-tooltip"
                  >
                    Warrant code
                  </span>
                  <ReactTooltip
                    id="Warrant-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Warrant code: </b>
                    Each warrant has an unique 4-digit identification code,
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/howarewarrantsnamed"
                    >
                      &nbsp;&nbsp;Click here&nbsp;&nbsp;
                    </a>
                    for a more detailed explanation.
                  </ReactTooltip>
                </th>
                <td className="val ticker">{ticker}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="effectivegearing"
                    data-hasqtip="1"
                    data-tip
                    data-for="Effective-tooltip"
                  >
                    Effective gearing (x)
                  </span>
                  <ReactTooltip
                    id="Effective-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Effective gearing: </b>
                    Indicates your leveraged exposure. An effective gearing of
                    5X tells you a 1% move in the underlying stock/index results
                    in an approximate 5% move in your Warrant price.
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/effectivegearing"
                    >
                      &nbsp;&nbsp;Click here&nbsp;&nbsp;
                    </a>
                    to learn more about effective gearing.
                  </ReactTooltip>
                </th>
                <td className="val effective_gearing">{effectivegearing}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="underlying"
                    data-hasqtip="2"
                    data-tip
                    data-for="Underlying-tooltip"
                  >
                    Underlying
                  </span>
                  <ReactTooltip
                    id="Underlying-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Underlying: </b>
                    The stock or index over which the Warrant is listed.
                  </ReactTooltip>
                </th>
                <td className="val underlying_ticker">
                  <a href={"/tools/underlying/" + underlyingticker}>{underlyingticker}</a>
                </td>
                <th>
                  <span
                    className="ttip"
                    data-target="delta"
                    data-hasqtip="3"
                    aria-describedby="qtip-3"
                    data-tip
                    data-for="Delta-tooltip"
                  >
                    Delta (%)
                  </span>
                  <ReactTooltip
                    id="Delta-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Delta: </b>
                    Estimates how a warrant moves in relation to the underlying
                    stock/index. Puts have negative deltas because their prices
                    increase as the underlying stock/index falls. Note, if your
                    warrant has a WPS that this will need to be taken into
                    account.
                  </ReactTooltip>
                </th>
                <td className="val delta">{delta}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="type"
                    data-hasqtip="4"
                    data-tip
                    data-for="Type-tooltip"
                  >
                    Type
                  </span>
                  <ReactTooltip
                    id="Type-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Type: </b>
                    Calls increase in value as share price rises, Puts increase
                    in value as share price falls.
                  </ReactTooltip>
                </th>
                <td className="val type">{type}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="deltaperdw"
                    data-hasqtip="5"
                    data-tip
                    data-for="Deltaper-tooltip"
                  >
                    Delta per warrant (%)
                  </span>
                  <ReactTooltip
                    id="Deltaper-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Delta per warrant: </b>
                    Delta/Warrant Per Share.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/delta"
                      target="_blank"
                    >
                      &nbsp;Click here&nbsp;
                    </a>
                    to learn more about Delta per warrant.
                  </ReactTooltip>
                </th>
                <td className="val delta_per_wrnt">{deltaperwrnt}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="issuer"
                    data-hasqtip="6"
                    data-tip
                    data-for="Issuer-tooltip"
                  >
                    Issuer
                  </span>
                  <ReactTooltip
                    id="Issuer-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Issuer: </b>
                    The Bank or Securities company that issues the Warrant.
                  </ReactTooltip>
                </th>
                <td className="val issuer_name">{issuername}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="sensitivity"
                    data-hasqtip="7"
                    data-tip
                    data-for="Sensitivity-tooltip"
                  >
                    Sensitivity
                  </span>
                  <ReactTooltip
                    id="Sensitivity-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Sensitivity: </b>
                    Shows you how much the underlying stock or index must move
                    for your warrant to move one "tick" or "Spread". For
                    warrants that cost less than $0.200, one tick is $0.001.
                  </ReactTooltip>
                </th>
                <td className="val sensitivity">{sensitivity}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="exerciseprice"
                    data-hasqtip="8"
                    data-tip
                    data-for="Exercise-tooltip"
                  >
                    Exercise price
                  </span>
                  <ReactTooltip
                    id="Exercise-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Exercise price: </b>
                    The price at which you can buy (calls) or sell (puts) the
                    underlying
                    <br />
                    share or index at expiry.
                  </ReactTooltip>
                </th>
                <td className="val exercise_price">{exerciseprice}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="breakevenpriceatexpiry"
                    data-hasqtip="9"
                    aria-describedby="qtip-9"
                    data-tip
                    data-for="Break-tooltip"
                  >
                    Break even price at expiry
                  </span>
                  <ReactTooltip
                    id="Break-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Break even price at expiry: </b>
                    If you are still holding the warrant at expiry, this is the
                    price that the shares/index must exceed (fall below) for
                    call (puts) for you to make a profit on your purchase price.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/breakevenprice"
                      target="_blank"
                    >
                      &nbsp;Click here&nbsp;
                    </a>
                    here for a more detailed description.
                  </ReactTooltip>
                </th>
                <td className="val breakeven_price">{breakevenprice}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="mostrecenttradedate"
                    data-hasqtip="10"
                    data-tip
                    data-for="Most-tooltip"
                  >
                    Most recent trade date
                  </span>
                  <ReactTooltip
                    id="Most-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Most recent trade date: </b>
                    The last date that the warrant recorded a trade. Note:
                    Warrant do not trade every day, this has implications when
                    calculating price changes.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/howarewarrantspriced"
                      target="_blank"
                    >
                      &nbsp; Click here &nbsp;
                    </a>
                    for more info.
                  </ReactTooltip>
                </th>
                <td className="val TRADE_DATE">{tradedate}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="premium"
                    data-hasqtip="11"
                    data-tip
                    data-for="Premium-tooltip"
                  >
                    Premium (%)
                  </span>
                  <ReactTooltip
                    id="Premium-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Premium: </b>
                    Also refered to as the "Time Value of the Warrant. Note a
                    higher premium could be due to the warrant being longer
                    dated or due to the higher gearing.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/premium"
                      target="_blank"
                    >
                      &nbsp;Click here &nbsp;
                    </a>
                    to learn more about premium.
                  </ReactTooltip>
                </th>
                <td className="val premium">{premium}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="expiry"
                    data-hasqtip="12"
                    data-tip
                    data-for="Expiry-tooltip"
                  >
                    Expiry
                  </span>
                  <ReactTooltip
                    id="Expiry-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Expiry: </b>
                    The date at which the warrant will expire.
                  </ReactTooltip>
                </th>
                <td className="val maturity">{maturity}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="impliedvolatility"
                    data-hasqtip="13"
                    data-tip
                    data-for="Volatility-tooltip"
                  >
                    Volatility (%)
                  </span>
                  <ReactTooltip
                    id="Volatility-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Volatility: </b>
                    The higher the implied volatility, the higher the price of
                    the Warrant. Relates to the volatility and risk of the
                    underlying share/Index.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/impliedvolatility"
                      target="_blank"
                    >
                      &nbsp;Click here&nbsp;
                    </a>
                    to learn more about implied volatility.
                  </ReactTooltip>
                </th>
                <td className="val implied_volatility">{impliedvolatility}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="lasttradingdate"
                    data-hasqtip="14"
                    data-tip
                    data-for="Last-tooltip"
                  >
                    Last trade date
                  </span>
                  <ReactTooltip
                    id="Last-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Last trading date: </b>
                    The last day to buy or sell the warrant.  This date is always the 5th trading day prior to the expiry date.  For Hong Kong underlyings, the last trading date will be brought forward if the 5th trading day prior to expiry falls on a Hong Kong holiday.
                  </ReactTooltip>
                </th>
                <td className="val last_trading_date">{lasttradingdate}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="theta"
                    data-hasqtip="15"
                    data-tip
                    data-for="Theta-tooltip"
                  >
                    Theta (%)
                  </span>
                  <ReactTooltip
                    id="Theta-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Theta: </b>
                    The daily time decay of the warrant expressed in percentage
                    terms.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/timedecay"
                      target="_blank"
                    >
                      &nbsp;Click here&nbsp;
                    </a>
                    to learn more about Theta.
                  </ReactTooltip>
                </th>
                <td className="val theta">{theta}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="timetomaturity"
                    data-hasqtip="16"
                    data-tip
                    data-for="Daysto-tooltip"
                  >
                    Days to maturity
                  </span>
                  <ReactTooltip
                    id="Daysto-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Days to maturity: </b>
                    The number of days left until the expiry date of the
                    warrant.
                  </ReactTooltip>
                </th>
                <td className="val days_to_maturity">{daystomaturity}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="intrinsicvalueperdw"
                    data-hasqtip="17"
                    data-tip
                    data-for="Intrinsic-tooltip"
                  >
                    Intrinsic value per warrant
                  </span>
                  <ReactTooltip
                    id="Intrinsic-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Intrinsic value per warrant: </b>
                    This is the difference between the exercise price and the
                    current share price. For call warrant, if the exercise price
                    is below the current share price it has intrinsic value,
                    vice versa for puts. Please
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/intrinsicvalueandtimevalue"
                      target="_blank"
                    >
                      &nbsp;Click here
                    </a>
                  </ReactTooltip>
                </th>
                <td className="val intrinsic_value">{intrinsicvalue}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="warrantpershare"
                    data-hasqtip="18"
                    aria-describedby="qtip-18"
                    data-tip
                    data-for="Warrantpershare-tooltip"
                  >
                    Warrant per share
                  </span>
                  <ReactTooltip
                    id="Warrantpershare-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Warrant per share: </b>
                    The number of warrants that entitles you to one unit of the
                    underlying share or index at expiry.
                  </ReactTooltip>
                </th>
                <td className="val conv_ratio">{convratio}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="moneyness"
                    data-hasqtip="19"
                    data-tip
                    data-for="Moneyness-tooltip"
                  >
                    Moneyness
                  </span>
                  <ReactTooltip
                    id="Moneyness-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Moneyness: </b>
                    ITM (in-the-money), OTM (out-of-the-money), ATM
                    (at-the-money).
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/moneyness"
                      target="_blank"
                    >
                      &nbsp; Click here&nbsp;
                    </a>
                    to learn more about moneyness.
                  </ReactTooltip>
                </th>
                <td className={moneynessClass}>{moneynessdisplaystring}</td>
              </tr>
            </tbody>
          </table>
          <table className="table hidden-md hidden-lg table-closed table-striped">
            <tbody>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="dwsymbol"
                    data-hasqtip="20"
                  >
                    Warrant code
                  </span>
                </th>
                <td className="val ticker">{ticker}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="underlying"
                    data-hasqtip="21"
                  >
                    Underlying
                  </span>
                </th>
                <td className="val underlying_ticker">
                  <a href="/tools/underlying/HSI">{underlyingticker}</a>
                </td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="type" data-hasqtip="22">
                    Type
                  </span>
                </th>
                <td className="val type">{type}</td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="issuer" data-hasqtip="23">
                    Issuer
                  </span>
                </th>
                <td className="val issuer_name">{issuername}</td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="" data-hasqtip="24">
                    Exercise price
                  </span>
                </th>
                <td className="val exercise_price">{exerciseprice}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="mostrecenttradedate"
                    data-hasqtip="25"
                  >
                    Most recent trade date
                  </span>
                </th>
                <td className="val TRADE_DATE">{tradedate}</td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="expiry" data-hasqtip="26">
                    Expiry
                  </span>
                </th>
                <td className="val maturity">{maturity}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="lasttradingdate"
                    data-hasqtip="27"
                  >
                    Last trading date
                  </span>
                </th>
                <td className="val last_trading_date">{lasttradingdate}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="timetomaturity"
                    data-hasqtip="28"
                  >
                    Days to maturity
                  </span>
                </th>
                <td className="val days_to_maturity">{daystomaturity}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="warrantpershare"
                    data-hasqtip="29"
                  >
                    Warrant per share
                  </span>
                </th>
                <td className="val conv_ratio">{convratio}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="effectivegearing"
                    data-hasqtip="30"
                  >
                    Effective gearing (x)
                  </span>
                </th>
                <td className="val effective_gearing">{effectivegearing}</td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="delta" data-hasqtip="31">
                    Delta
                  </span>
                </th>
                <td className="val delta">{delta}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="deltaperdw"
                    data-hasqtip="32"
                  >
                    Delta per warrant
                  </span>
                </th>
                <td className="val delta_per_wrnt">{deltaperwrnt}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="sensitivity"
                    data-hasqtip="33"
                  >
                    Sensitivity
                  </span>
                </th>
                <td className="val sensitivity">{sensitivity}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="breakevenpriceatexpiry"
                    data-hasqtip="34"
                  >
                    Break even price at expiry
                  </span>
                </th>
                <td className="val breakeven_price">{breakevenprice}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="premium"
                    data-hasqtip="35"
                  >
                    Premium(%)
                  </span>
                </th>
                <td className="val premium">{premium}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="impliedvolatility"
                    data-hasqtip="36"
                  >
                    Volatility (%)
                  </span>
                </th>
                <td className="val implied_volatility">{impliedvolatility}</td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="theta" data-hasqtip="37">
                    Theta (%)
                  </span>
                </th>
                <td className="val theta">{theta}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="intrinsicvalueperdw"
                    data-hasqtip="38"
                  >
                    Intrinsic value per warrant
                  </span>
                </th>
                <td className="val intrinsic_value">{intrinsicvalue}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="moneyness"
                    data-hasqtip="39"
                  >
                    Moneyness
                  </span>
                </th>
                <td className={moneynessClass}>{moneynessdisplaystring}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

class PanelGroup extends Component {
  render() {
    return (
      <div
        className="panel-group visible-xs-block visible-sm-block"
        id="defination"
      >
        <div className="panel panel-default">
          <div className="panel-heading">
            <a
              data-toggle="collapse"
              data-parent="#defination"
              href="#collapse_dwcompare3"
              className="collapsed"
              aria-expanded="false"
            >
              <span className="glyphicon glyphicon glyphicon-question-sign pull-right"></span>
              <div
                id="collapse_title3"
                className="panel-title text-center color-primary"
              >
                Definition &amp; disclaimer
              </div>
            </a>
          </div>
          <div
            id="collapse_dwcompare3"
            className="panel-collapse collapse"
            aria-expanded="false"
            style={{ height: "20px" }}
          >
            <table className="table table-striped indicator_table">
              <tbody>
                <tr>
                  <td>Warrant code</td>
                  <td>
                    Each warrant has an unique 4-digit identification code,
                    click{" "}
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/howarewarrantsnamed"
                    >
                      click here
                    </a>{" "}
                    for a more detailed explanation.
                  </td>
                </tr>
                <tr>
                  <td>Underlying</td>
                  <td>The stock or index over which the Warrant is listed.</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>
                    Calls increase in value as share price rises, Puts increase
                    in value as share price falls.
                  </td>
                </tr>
                <tr>
                  <td>Issuer</td>
                  <td>
                    The Bank or Securities company that issues the Warrant.
                  </td>
                </tr>
                <tr>
                  <td>Exercise price</td>
                  <td>
                    The price at which you can buy (calls) or sell (puts) the
                    underlying
                    <br /> share or index at expiry.
                  </td>
                </tr>
                <tr>
                  <td>Most recent trade date</td>
                  <td>
                    The last date that the warrant recorded a trade. Note:
                    Warrant do not trade every day, this has implications when
                    calculating price changes.{" "}
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/howarewarrantspriced"
                    >
                      click here
                    </a>{" "}
                    for more info.
                  </td>
                </tr>
                <tr>
                  <td>Expiry</td>
                  <td>The date at which the warrant will expire.</td>
                </tr>
                <tr>
                  <td>Last trading date</td>
                  <td>
                    The last day to buy or sell the warrant.  This date is always the 5th trading day prior to the expiry date.  For Hong Kong underlyings, the last trading date will be brought forward if the 5th trading day prior to expiry falls on a Hong Kong holiday.
                  </td>
                </tr>
                <tr>
                  <td>Days to maturity</td>
                  <td>
                    The number of days left until the expiry date of the
                    warrant.
                  </td>
                </tr>
                <tr>
                  <td>Warrant per share</td>
                  <td>
                    The number of warrants that entitles you to one unit of the
                    underlying share or index at expiry.
                  </td>
                </tr>
                <tr>
                  <td>Effective gearing (x)</td>
                  <td>
                    Indicates your leveraged exposure. An effective gearing of
                    5X tells you a 1% move in the underlying stock/index results
                    in an approximate 5% move in your Warrant price.{" "}
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/effectivegearing"
                    >
                      click here
                    </a>{" "}
                    to learn more about effective gearing.
                  </td>
                </tr>
                <tr>
                  <td>Delta</td>
                  <td>
                    Estimates how a warrant moves in relation to the underlying
                    stock/index. Puts have negative deltas because their prices
                    increase as the underlying stock/index falls. Note, if your
                    warrant has a WPS that this will need to be taken into
                    account.
                  </td>
                </tr>
                <tr>
                  <td>Delta per warrant</td>
                  <td>
                    Delta/Warrant Per Share.{" "}
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/delta"
                    >
                      click here
                    </a>{" "}
                    to learn more about Delta per warrant.
                  </td>
                </tr>
                <tr>
                  <td>Sensitivity</td>
                  <td>
                    Shows you how much the underlying stock or index must move
                    for your warrant to move one "tick" or "Spread". For
                    warrants that cost less than $0.200, one tick is $0.001.
                  </td>
                </tr>
                <tr>
                  <td>Break even price at expiry</td>
                  <td>
                    If you are still holding the warrant at expiry, this is the
                    price that the shares/index must exceed (fall below) for
                    call (puts) for you to make a profit on your purchase price.{" "}
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/breakevenprice"
                    >
                      click here
                    </a>{" "}
                    here for a more detailed description.
                  </td>
                </tr>
                <tr>
                  <td>Premium (%)</td>
                  <td>
                    Also refered to as the "Time Value of the Warrant. Note a
                    higher premium could be due to the warrant being longer
                    dated or due to the higher gearing.{" "}
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/premium"
                    >
                      click here
                    </a>{" "}
                    to learn more about premium.
                  </td>
                </tr>
                <tr>
                  <td>Volatility (%)</td>
                  <td>
                    The higher the implied volatility, the higher the price of
                    the Warrant. Relates to the volatility and risk of the
                    underlying share/Index.{" "}
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/impliedvolatility"
                    >
                      &nbsp;Click here&nbsp;
                    </a>{" "}
                    to learn more about implied volatility.
                  </td>
                </tr>
                <tr>
                  <td>Theta (%)</td>
                  <td>
                    The daily time decay of the warrant expressed in percentage
                    terms.{" "}
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/timedecay"
                    >
                      click here
                    </a>{" "}
                    to learn more about Theta.
                  </td>
                </tr>
                <tr>
                  <td>Intrinsic value per warrant</td>
                  <td>
                    This is the difference between the exercise price and the
                    current share price. For call warrant, if the exercise price
                    is below the current share price it has intrinsic value,
                    vice versa for puts. Please{" "}
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/intrinsicvalueandtimevalue"
                    >
                      &nbsp;Click here
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Moneyness</td>
                  <td>
                    ITM (in-the-money), OTM (out-of-the-money), ATM
                    (at-the-money).{" "}
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/moneyness"
                    >
                      click here
                    </a>{" "}
                    to learn more about moneyness.
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="small">
              All market data is delayed 15 mins unless otherwise indicated by
              timestamp.
            </p>
            <p className="small">
              The warrant price displayed in prior, Open, High and low is based
              on the "bid price for the warrant rather than the "traded price".
              the intraday graph also uses the warrant bid price to generate the
              warrant price history. We do this to provide a more accurate
              representation of the warrant value at each particular point in
              time and a more accurate reflection of warrant price changes.
              Warrants do not trade as frequently as shares and sometimes may
              not record a trade for a long period of time though the bid/offer
              prices may continue to move. Therefore using the bid price is
              usually the best indicator of warrant value. For a more detailed
              description&nbsp;
              <Link
                className="text-lowercase"
                style={{ color: "#0675C2", fontFamily: "HelveticaNeueMedium" }}
                target="_blank"
                to="/InvestorAcademy"
              >
                Click here
              </Link>
              .
            </p>
            <hr className="hr-hmgbm" />
          </div>
        </div>
      </div>
    );
  }
}

class LiveMatrixTodayTable extends Component {
  getTableContent(page) {
    if (this.props.logic !== null) {
      return this.props.logic.getTableContent(this.props.page);
    }
    return null;
  }

  getPrintRow() {
    if (this.isHome()) {
      return null;
    }
    var name = document.querySelector(".ricbar-lbl");
    name = name ? name.innerHTML : "";
    // var title = `Live matrix | Singapore Warrants | Macquarie | ${name}`;
    var title = `Live matrix | Singapore Warrants | Macquarie`;
    return (
      <tr>
        <th
          colSpan="4"
          className="th-left"
          style={{
            borderBottom: "0px",
            padding: "0px 0px",
            textAlign: "left",
          }}
        >
          {/* <ReactToPrint
            content={() => this.tableRef}
            documentTitle={title}
            trigger={() => (
              <img
                className="printimg"
                src="/img/bullet/print.png"
                alt="print live matrix"
                // onClick={this.print}
              />
            )}
          /> */}
          <img
            className="printimg"
            src="/img/bullet/print.png"
            alt="print live matrix"
            onClick={this.print}
            style={{
              width: '20px',
              marginTop: '-8px',
              cursor: 'pointer'
            }}
          />
        </th>
      </tr>
    );
  }
  print() {
    // var oldHtml = document.body.innerHTML;
    // var newHtml = document.getElementById("lmtablecontainer").innerHTML;
    // document.body.innerHTML = newHtml;
    // window.print();
    // document.body.innerHTML = oldHtml;
    // window.location.reload();
    $("#lmtablecontainer").printThis({
      pageTitle: $(document).find("title").text() + " | " + $("#ticker").html(),
      loadCSS: ["/src/pages/tools/livematrix/livematrix.css", "/css/global.css"],
      // importStyle: ["<style>html{background:'#fff'}}</style>"]
    });


    // //获取当前页的html代码
    // var bodyhtml = document.getElementById("lmtablecontainer").innerHTML;
    // //设置打印开始区域、结束区域
    // var startFlag = "/* startprint */";
    // var endFlag = "/* endprint */";
    // // 要打印的部分
    // var printhtml = bodyhtml.substring(
    //   bodyhtml.indexOf(startFlag),
    //   bodyhtml.indexOf(endFlag)
    // );
    // // 生成并打印ifrme
    // var f = document.getElementById("printf");
    // f.contentDocument.write(bodyhtml);
    // f.contentDocument.write(
    //   '<link rel="stylesheet" type="text/css" href="/css/global.css">'
    // );
    // f.contentDocument.write(
    //   '<link rel="stylesheet" type="text/css" href="/src/tools/livenatrix/livenatrix.css">'
    // );
    // f.contentDocument.close();
    // f.contentWindow.print();
  }
  isHome() {
    if (this.props.page !== undefined && this.props.page !== null) {
      if (this.props.page === "home") {
        return true;
      }
    }
    return false;
  }

  render() {
    const isLoading = this.props.isLoading;
    var className = '';
    if (isLoading) {
      className += " selector-loadingindicator";
    }
    const printRow = this.getPrintRow();
    var showAll = true;
    var show = false;
    if (
      this.getTableContent(this.props.page) != null
    ) {
      if (this.getTableContent(this.props.page).length == 0) {
        if (this.isHome() === true) {
          showAll = false;
        } else {
          showAll = true;
          if (isLoading) {
            show = false;
          } else {
            show = true;
          }

        }
      }

    } else {
      if (this.isHome() === true) {
        showAll = false;
      } else {
        showAll = true;
        if (isLoading) {
          show = false;
        } else {
          show = true;
        }

      }
    }
    var isSti = false;
    if (this.props.logic != null) {
      if (this.props.logic.isSTI) {
        isSti = true;
      } else {
        isSti = false;
      }
    }
    let isWarrantsShow = false;
    let isUnderyingShow = false;
    let future_dsply_name = '';
    if (
      typeof this.props.selected !== "undefined" &&
      this.props.selected !== null &&
      this.props.selected.symbol !== null
    ) {
      if (
        this.props.selected.symbol.label.indexOf('S&P') != -1 ||
        this.props.selected.symbol.label.indexOf('DJI') != -1 ||
        this.props.selected.symbol.label.indexOf('HSI') != -1 ||
        this.props.selected.symbol.label.indexOf('HSTECH') != -1 ||
        this.props.selected.symbol.label.indexOf('NKY') != -1 ||
        this.props.selected.symbol.label.indexOf('SIMSCI') != -1 ||
        this.props.selected.symbol.label.indexOf('Sea') != -1 ||
        this.props.selected.symbol.label.indexOf('NASDAQ') != -1
      ) {
        isWarrantsShow = true;
      } else {
        if (this.props.selected.symbol.future_dsply_name && this.props.selected.symbol.future_dsply_name != '' && this.props.selected.symbol.future_dsply_name != '-') {
          isUnderyingShow = true;
          future_dsply_name = this.props.selected.symbol.future_dsply_name;
        }
      }
    }
    return (
      <div
        id="lmtablecontainer"
        ref={(el) => (this.tableRef = el)}
        className={className}
        style={{ paddingTop: "10px" }}
      >
        <div style={{ display: this.props.tabNum == 3 ? 'block' : 'none' }}>
          <p style={{ marginTop: '10px', marginBottom: '0px', display: isWarrantsShow ? 'block' : 'none' }}>
            Underlying price below refers to the relevant Index Futures contract. Refer to the "Live index futures prices" on the home page to verify which Index Futures it is tracking.<br /> For US indices, it is typically tracking the quarterly month futures - reach us at +65 6601 0189 to confirm the month should you be unsure.
          </p>
          <p id="p-contract" style={{ marginTop: '10px', marginBottom: '0px', display: isUnderyingShow ? 'block' : 'none' }}>Underlying price below refers to the relevant Index Futures contract

            <span> over</span>
            <span className="text-bold"> {future_dsply_name}</span>
          </p>
        </div>
        {/* startprint */}
        <table
          id="today_table"
          className="table lbtable"
          style={{
            position: "relative",
            width: '100%',
          }}
        >
          <thead
            style={{
              position: "relative",
              display: showAll ? "revert" : "none",
            }}
          >
            {printRow}
            <tr>
              <th width="30%">
                <span className="th-left th-callbid">{this.props.logic && this.props.logic.ricdata.type == 'PUT' ? 'Underlying offer' : 'Underlying bid'}</span>
              </th>
              <th width="20%">
                <span className="th-left">Warrant bid</span>
              </th>
              <th width="20%">
                <span className="th-right">Warrant offer</span>
              </th>
              <th width="20%">
                <span className="th-right th-calloffer">{this.props.logic && this.props.logic.ricdata.type != 'PUT' ? 'Underlying offer' : 'Underlying bid'}</span>
              </th>
            </tr>
          </thead>
          {/* <tbody style={{ display: isSti ? 'none' : 'revert' }}>{this.getTableContent(this.props.page)}</tbody> */}
          <tbody>{this.getTableContent(this.props.page)}</tbody>
          {/* {
            !isSti ? (
              <tbody
                style={{
                  display: show ? "revert" : "none",
                }}
              >
                <tr>
                  <td
                    colSpan="6"
                    className="text-content"
                    style={{ fontSize: "16px" }}
                  >
                    There is no matrix available for this warrant, as the warrant
                    has no value at current levels in the underlying
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody
                style={{ border: '0px', display: this.isHome() ? 'none' : 'revert' }}
              >
                <tr >
                  <td
                    colSpan="6"
                    className="text-content"
                    style={{ fontSize: "16px" }}
                  >
                    There is no live matrix for STI warrants as there are no actively traded STI futures in Singapore.
                  </td>
                </tr>
              </tbody>
            )
          } */}
          <tbody
            style={{
              display: show ? "revert" : "none",
            }}
          >
            <tr>
              <td
                colSpan="6"
                className="text-content"
                style={{ fontSize: "16px" }}
              >
                There is no matrix available for this warrant, as the warrant
                has no value at current levels in the underlying
              </td>
            </tr>
          </tbody>
        </table>
        {/* endprint */}
        {/* <iframe
          id="printf"
          src=""
          width="0"
          height="0"
          frameBorder="0"
        ></iframe> */}
      </div>
    );
  }
}
class LivematrixLogic {
  constructor(livematrix, ricdata) {
    this.livematrix = livematrix;
    this.ricdata = ricdata;

    this.isMacq = false;
    if ("issuer_name" in ricdata) {
      if (ricdata.issuer_name.indexOf("Macquarie") > -1) {
        this.isMacq = true;
      }
    }
    this.isSTI = false;
    this.isXIAOMI = false;
    this.isSP = false;
    this.isHKStock = false;
    if ("underlying_ric" in ricdata) {
      if (ricdata.underlying_ric === ".STI") {
        this.isSTI = true;
      } else if (ricdata.underlying_ric.indexOf("ESc") > -1) {
        this.isSP = true;
      } else if (ricdata.underlying_ric.indexOf(".HK") > -1) {
        this.isHKStock = true;
      }
      if (ricdata.underlying_ric === "1810.HK") {
        this.isXIAOMI = true;
      }
    }
    this.isPut = false;
    if ("type" in ricdata) {
      if (ricdata.type === "PUT") {
        this.isPut = true;
      }
    }
    this.isCompressed = false;
    if (this.livematrix !== null) {
      if (this.livematrix.length > 0) {
        if ("is_compressed" in this.livematrix[0]) {
          if (this.livematrix[0].is_compressed) {
            this.isCompressed = true;
          }
        }
      }
    }
  }

  findMidPoint(iscompress, isfiveday) {
    const lmdata = this.livematrix;
    const type = this.ricdata.type;
    const lmprice = this.ricdata.underlying_bid_lm; // Number from source
    const mp = {
      price: -1,
      diff: -1,
      index: -1,
      price2: -1,
      diff2: -1,
      index2: -1,
    };
    if (lmdata !== null) {
      for (let i = 0; i < lmdata.length; i++) {
        if (iscompress && !(i % 2 === 0)) {
          //for compressed data, process only even data
          continue;
        }
        let ubid;
        if (isfiveday && !iscompress) {
          ubid = Object.keys(lmdata[i])[0]; // ?
        } else {
          ubid =
            lmdata[i][type === "PUT" ? "underlying_ask" : "underlying_bid"];
        }
        let diff = Math.abs(lmprice - ubid);
        if (mp.index === -1 || mp.diff > diff) {
          mp.diff2 = mp.diff;
          mp.price2 = mp.price;
          mp.index2 = mp.index;
          mp.diff = diff;
          mp.price = ubid;
          mp.index = i;
        }
        if (lmprice < ubid && mp.index === i - 1) {
          mp.diff2 = diff;
          mp.price2 = ubid;
          mp.index2 = i;
        }
      }
    }
    return mp;
  }

  findBIDMidPoint(iscompress, isfiveday) {
    const lmdata = this.livematrix;
    const lmprice = this.ricdata.BID;
    const type = this.ricdata.type;
    const mp = {
      price: -1,
      diff: -1,
      index: -1,
      price2: -1,
      diff2: -1,
      index2: -1,
    };
    for (let i = 0; i < lmdata.length; i++) {
      if (iscompress && !(i % 2 === 0)) {
        //for compressed data, process only even data
        continue;
      }
      let ubid;
      if (isfiveday && !iscompress) {
        ubid = Object.keys(lmdata[i])[0];
      } else {
        ubid = lmdata[i][type === "PUT" ? "bid" : "bid"];
      }
      const diff = Math.abs(lmprice - ubid);
      if (mp.index === -1 || mp.diff > diff) {
        mp.diff2 = mp.diff;
        mp.price2 = mp.price;
        mp.index2 = mp.index;
        mp.diff = diff;
        mp.price = ubid;
        mp.index = i;
      }
      if (lmprice < ubid && mp.index === i - 1) {
        mp.diff2 = diff;
        mp.price2 = ubid;
        mp.index2 = i;
      }
    }
    return mp;
  }

  getTableContent(page) {
    if (page) {
      return this.getHomeData();
    } else {
      if (this.isHKStock === false && this.isSP === false) {
        return this.case1();
      }
      return this.case2();
    }
  }
  getHomeData() {
    const ubidask = this.isPut ? "underlying_ask" : "underlying_bid";
    //update quote data and description
    var tablecontent = [];
    if (this.livematrix && this.livematrix.length > 4 && !this.isSTI) {
      var midpoint;
      if (!this.isXIAOMI) {
        if (this.isCompressed) {
          midpoint = this.findMidPoint(
            this.livematrix,
            this.ricdata.underlying_bid_lm,
            true,
            false,
            this.ricdata.type
          );
          const rowShow = this.case1_1_show(ubidask, midpoint);
          var hrow, trow;
          var scount = 1;
          var lcount = 1;
          var showcount = -1;
          var startIndex, endIndex;
          if (midpoint.index > this.livematrix.length - 3) {
            startIndex = midpoint.index - 4;
            endIndex = midpoint.index + 1;
          } else if (midpoint.index < 2) {
            startIndex = 0;
            endIndex = 5;
          } else {
            startIndex = midpoint.index - 2;
            endIndex = midpoint.index + 3;
          }
          for (var i = startIndex; i < endIndex + 1; i++) {
            hrow = this.livematrix[i];
            trow = this.livematrix[i + 1];

            if (!isNaN(hrow[ubidask])) {
              hrow[ubidask] = Number(hrow[ubidask]);
            }

            if (hrow.head_tail_id === trow.head_tail_id) {
              var classrow = "";
              let isShow = false;
              if (hrow[ubidask] === midpoint.price) {
                classrow += "midpoint";
                isShow = true;
              } else if (hrow[ubidask] < midpoint.price) {
                classrow += "srow srow" + scount;
                if (rowShow.sShow.length > scount) {
                  isShow = rowShow.sShow[scount];
                }
                scount++;
              } else if (hrow[ubidask] > midpoint.price) {
                classrow += "lrow lrow" + lcount;
                if (rowShow.lShow.length > lcount) {
                  isShow = rowShow.lShow[lcount];
                }
                lcount++;
              }
              // let showStyle = { display: "none" }; 有修改
              let showStyle = {};
              if (isShow) {
                showcount += 1;
                showStyle = {};
                if (showcount % 2 === 0) {
                  classrow += " bgcolor-08";
                }
              }
              const hRow = (
                <tr key={i} className={classrow} style={showStyle}>
                  <td>
                    {Number(
                      this.isPut ? hrow.underlying_ask : hrow.underlying_bid
                    ).toFixed(3)}
                  </td>
                  <td>{Number(hrow.bid).toFixed(3)}</td>
                  <td>{Number(hrow.ask).toFixed(3)}</td>
                  <td>
                    {Number(
                      this.isPut ? hrow.underlying_bid : hrow.underlying_ask
                    ).toFixed(3)}
                  </td>
                </tr>
              );
              tablecontent.push(hRow);
              const tRow = (
                <tr key={i + 1} className={classrow} style={showStyle}>
                  <td>
                    {Number(
                      this.isPut ? trow.underlying_ask : trow.underlying_bid
                    ).toFixed(3)}
                  </td>
                  <td>{Number(trow.bid).toFixed(3)}</td>
                  <td>{Number(trow.ask).toFixed(3)}</td>
                  <td>
                    {Number(
                      this.isPut ? trow.underlying_bid : trow.underlying_ask
                    ).toFixed(3)}
                  </td>
                </tr>
              );
              tablecontent.push(tRow);
              i++;
            }
          }
        } else {
          midpoint = this.findMidPoint(
            this.livematrix,
            this.ricdata.underlying_bid_lm,
            false,
            false,
            this.ricdata.type
          );
          var livematrixindex = 0;
          var livematrixlimit = 9;
          livematrixindex = midpoint.index;
          if (livematrixindex === 0) {
            livematrixindex = Math.ceil(this.livematrix.length / 2);
          }
          let startIndex, endIndex;
          if (midpoint.index === this.livematrix.length - 1) {
            startIndex = midpoint.index - 4;
            endIndex = midpoint.index;
          } else if (midpoint.index < 2) {
            startIndex = 0;
            endIndex = 4;
          } else {
            startIndex = midpoint.index - 2;
            endIndex = midpoint.index + 2;
          }
          for (let i = startIndex; i < endIndex + 1; i++) {
            var livematrixdata = this.livematrix[i];
            var isMidPoint =
              livematrixdata.underlying_bid.toFixed(3) ===
                midpoint.price.toFixed(3)
                ? true
                : false;
            let rowClassName = isMidPoint ? "midpoint" : "";
            rowClassName += i % 2 === 0 ? " bgcolor-08" : "";
            const row = (
              <tr key={i} className={rowClassName}>
                <td>
                  {(this.isPut
                    ? livematrixdata.underlying_ask
                    : livematrixdata.underlying_bid
                  ).toFixed(3)}
                </td>
                <td>{livematrixdata.bid.toFixed(3)}</td>
                <td>{livematrixdata.ask.toFixed(3)}</td>
                <td>
                  {(this.isPut
                    ? livematrixdata.underlying_bid
                    : livematrixdata.underlying_ask
                  ).toFixed(3)}
                </td>
              </tr>
            );
            tablecontent.push(row);
          }
        }
      } else {
        for (let i = 0; i < this.livematrix.length; i++) {
          let livematrixdata = this.livematrix[i];
          const rowClassName = i % 2 === 0 ? " bgcolor-08" : "";
          const row = (
            <tr key={i} className={rowClassName}>
              <td>
                {(this.isPut
                  ? livematrixdata.underlying_ask
                  : livematrixdata.underlying_bid
                ).toFixed(3)}
              </td>
              <td>{livematrixdata.bid.toFixed(3)}</td>
              <td>{livematrixdata.ask.toFixed(3)}</td>
              <td>
                {(this.isPut
                  ? livematrixdata.underlying_bid
                  : livematrixdata.underlying_ask
                ).toFixed(3)}
              </td>
            </tr>
          );
          tablecontent.push(row);
        }
      }
    }
    return tablecontent;
  }
  getPageData() { }
  //Case 1: isHKStock === false && isSP === false
  case1() {
    if (this.isCompressed) {
      return this.case1_1();
    }
    return this.case1_2();
  }

  //Case 1.1: isHKStock === false && isSP === false && is_compressed === true
  case1_1_show(ubidask, midpoint) {
    let hrow, trow;
    let scount = 1,
      lcount = 1;
    for (let i = 0; i < this.livematrix.length; i++) {
      hrow = this.livematrix[i];
      trow = this.livematrix[i + 1];
      let hrowUbidask = null;
      if (ubidask in hrow) {
        hrowUbidask = Number(hrow[ubidask]);
      }
      let midpointPrice = Number(midpoint.price);
      if (
        hrowUbidask !== null &&
        !isNaN(hrowUbidask) &&
        !isNaN(midpointPrice)
      ) {
        if (hrowUbidask < midpointPrice) {
          scount++;
        } else if (hrowUbidask > midpointPrice) {
          lcount++;
        }
      }
      i++;
    }
    const sShow = new Array(scount).fill(false);
    const lShow = new Array(lcount).fill(false);

    // if ("BID" in this.ricdata && Number(this.ricdata.BID) === 0.04) {
    //   for (let i = lcount; i >= lcount - 8; i--) {
    //     lShow[i] = true;
    //   }
    // } else {
    for (let i = lcount; i >= lcount - 8; i--) {
      lShow[i] = true;
    }
    for (let i = 1; i <= 8; i++) {
      sShow[i] = true;
    }
    // }
    return { sShow, lShow };
  }

  case1_1() {
    const ubidask = this.isPut ? "underlying_ask" : "underlying_bid";
    const midpoint = this.findMidPoint(true, false);
    let hrow, trow;
    let scount = 1,
      lcount = 1,
      showcount = -1;
    const rowShow = this.case1_1_show(ubidask, midpoint);
    const tablecontent = [];
    for (let i = 0; i < this.livematrix.length - 1; i++) {
      hrow = this.livematrix[i];
      trow = this.livematrix[i + 1];
      let hrowUbidask = null;
      if (ubidask in hrow) {
        hrowUbidask = Number(hrow[ubidask]);
      }
      let midpointPrice = Number(midpoint.price);

      if (
        hrowUbidask !== null &&
        !isNaN(hrowUbidask) &&
        !isNaN(midpointPrice)
      ) {
        if (hrow.head_tail_id === trow.head_tail_id) {
          let rowClassName = "";
          let isShow = false;
          if (hrowUbidask === midpointPrice) {
            rowClassName += "midpoint";
            isShow = true;
          } else if (hrowUbidask < midpointPrice) {
            rowClassName += "srow srow" + scount;
            if (rowShow.sShow.length > scount) {
              isShow = rowShow.sShow[scount];
            }
            scount++;
          } else if (hrowUbidask > midpointPrice) {
            rowClassName += "lrow lrow" + lcount;
            if (rowShow.lShow.length > lcount) {
              isShow = rowShow.lShow[lcount];
            }
            lcount++;
          }
          let showStyle = { display: "none" };
          if (isShow) {
            showcount += 1;
            showStyle = {};
            if (showcount % 2 === 0) {
              rowClassName += " bgcolor-08";
            }
          }
          const hRow = (
            <tr key={i} className={rowClassName} style={showStyle}>
              <td>
                {Number(
                  this.isPut ? hrow.underlying_ask : hrow.underlying_bid
                ).toFixed(3)}
              </td>
              <td>{Number(hrow.bid).toFixed(3)}</td>
              <td>{Number(hrow.ask).toFixed(3)}</td>
              <td>
                {Number(
                  this.isPut ? hrow.underlying_bid : hrow.underlying_ask
                ).toFixed(3)}
              </td>
            </tr>
          );
          tablecontent.push(hRow);
          const tRow = (
            <tr key={i + 1} className={rowClassName} style={showStyle}>
              <td>
                {Number(
                  this.isPut ? trow.underlying_ask : trow.underlying_bid
                ).toFixed(3)}
              </td>
              <td>{Number(trow.bid).toFixed(3)}</td>
              <td>{Number(trow.ask).toFixed(3)}</td>
              <td>
                {Number(
                  this.isPut ? trow.underlying_bid : trow.underlying_ask
                ).toFixed(3)}
              </td>
            </tr>
          );
          tablecontent.push(tRow);
          i++;
        }
      }
    }
    // tableElement.html(tablecontent);
    // if (ric_data.BID == 0.04) {
    //     tableElement.find(".srow").hide();
    //     tableElement.find(".lrow").hide();
    //     for (var i = lcount; i >= lcount - 8; i--) {
    //         tableElement.find(".lrow" + i).show();
    //     }
    // } else {
    //     tableElement.find(".srow").hide();
    //     tableElement.find(".lrow").hide();
    //     for (var i = lcount; i >= lcount - 8; i--) {
    //         tableElement.find(".lrow" + i).show();
    //     }
    //     for (var i = 1; i <= 8; i++) {
    //         tableElement.find(".srow" + i).show();
    //     }
    // }
    // var isOdd = true;
    // var cnt = 1;
    // tableElement.find('tr:visible').filter(function () {
    //     if (cnt > 2) {
    //         cnt = 1;
    //         isOdd = !isOdd;
    //     }
    //     cnt++;
    //     return isOdd;
    // }).addClass('bgcolor-08');
    return tablecontent;
  }

  //Case 1.2: isHKStock === false && isSP === false && is_compressed === false
  case1_2() {
    const midpoint = this.findMidPoint(false, false);
    let livematrixindex = 0;
    let livematrixlimit = 9;
    if (this.isSP) {
      livematrixlimit = 14;
    }
    livematrixindex = midpoint.index;
    if (livematrixindex === 0) {
      livematrixindex = Math.ceil(this.livematrix.length / 2);
    }
    const tablecontent = [];
    for (let i = 0; i < this.livematrix.length; i++) {
      if (
        i > livematrixindex - livematrixlimit &&
        i < livematrixindex + livematrixlimit
      ) {
        let livematrixdata = this.livematrix[i];
        let isMidPoint =
          livematrixdata.underlying_bid.toFixed(3) === midpoint.price.toFixed(3)
            ? true
            : false;
        if (!isMidPoint && midpoint.diff !== 0) {
          isMidPoint =
            livematrixdata.underlying_bid.toFixed(3) ===
              midpoint.price2.toFixed(3)
              ? true
              : false;
        }
        // tablecontent += isMidPoint ? "<tr class='midpoint'><td>" : "<tr><td>";
        // tablecontent += (isPut? livematrixdata.underlying_ask : livematrixdata.underlying_bid ).toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += livematrixdata.bid.toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += livematrixdata.ask.toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += (isPut? livematrixdata.underlying_bid : livematrixdata.underlying_ask).toFixed(3);
        // tablecontent += "</td></tr>";
        let rowClassName = isMidPoint ? "midpoint" : "";
        rowClassName += i % 2 === 0 ? " bgcolor-08" : "";
        const row = (
          <tr key={i} className={rowClassName}>
            <td>
              {(this.isPut
                ? livematrixdata.underlying_ask
                : livematrixdata.underlying_bid
              ).toFixed(3)}
            </td>
            <td>{livematrixdata.bid.toFixed(3)}</td>
            <td>{livematrixdata.ask.toFixed(3)}</td>
            <td>
              {(this.isPut
                ? livematrixdata.underlying_bid
                : livematrixdata.underlying_ask
              ).toFixed(3)}
            </td>
          </tr>
        );
        tablecontent.push(row);
      }
    }
    // tableElement.html(tablecontent);
    // tableElement.find('tr:visible').filter(':even').addClass('bgcolor-08');
    return tablecontent;
  }

  //Case 2: !(isHKStock === false && isSP === false)
  case2() {
    if (this.isCompressed) {
      return this.case2_1();
    }
    return this.case2_2();
  }

  // Case 2.1: !(isHKStock === false && isSP === false) && is_compressed === true
  case2_1_show(ubidask, midpoint) {
    let hrow, trow;
    let scount = 1,
      lcount = 1;
    for (let i = 0; i < this.livematrix.length; i++) {
      hrow = this.livematrix[i];
      trow = this.livematrix[i + 1];
      let hrowUbidask = null;
      if (ubidask in hrow) {
        hrowUbidask = Number(hrow[ubidask]);
      }
      let midpointPrice = Number(midpoint.price);
      if (
        hrowUbidask !== null &&
        !isNaN(hrowUbidask) &&
        !isNaN(midpointPrice)
      ) {
        if (hrowUbidask < midpointPrice) {
          scount++;
        } else if (hrowUbidask > midpointPrice) {
          lcount++;
        }
      }
      i++;
    }
    const sShow = new Array(scount).fill(false);
    const lShow = new Array(lcount).fill(false);

    if ("BID" in this.ricdata && Number(this.ricdata.BID) === 0.04) {
      for (let i = lcount; i >= lcount - 8; i--) {
        lShow[i] = true;
      }
    } else {
      // for (let i = lcount; i >= lcount - 8; i--) {
      //   lShow[i] = true;
      // }
      // for (let i = 1; i <= 8; i++) {
      //   sShow[i] = true;
      // }
      if (this.livematrix[0].bid < this.livematrix[this.livematrix.length - 1].bid) {
        for (let i = 0; i <= 8; i++) {
          lShow[i] = true;
        }
        // i=0
        for (let i = scount; i >= scount - 8; i--) {
          sShow[i] = true;
        }
      } else {
        for (let i = lcount; i >= lcount - 8; i--) {
          lShow[i] = true;
        }
        for (let i = 1; i <= 8; i++) {
          sShow[i] = true;
        }
      }
    }
    return { sShow, lShow };
  }

  case2_1() {
    const ubidask = "bid";
    const midpoint = this.findBIDMidPoint(true, false);
    let hrow = null;
    let trow = null;
    let scount = 1,
      lcount = 1,
      showcount = -1;
    const rowShow = this.case2_1_show(ubidask, midpoint);
    const tablecontent = [];
    for (let i = 0; i < this.livematrix.length - 1; i++) {
      hrow = this.livematrix[i];
      trow = this.livematrix[i + 1];
      let hrowUbidask = null;
      if (ubidask in hrow) {
        hrowUbidask = Number(hrow[ubidask]);
      }
      const midpointPrice = Number(midpoint.price);
      if (
        hrowUbidask !== null &&
        !isNaN(hrowUbidask) &&
        !isNaN(midpointPrice)
      ) {
        if (hrow.head_tail_id === trow.head_tail_id) {
          let rowClassName = "";
          let isShow = false;
          if (hrowUbidask === midpointPrice) {
            rowClassName += "midpoint";
            isShow = true;
          } else if (hrowUbidask < midpointPrice) {
            rowClassName += "srow srow" + scount;
            if (rowShow.sShow.length > scount) {
              isShow = rowShow.sShow[scount];
            }
            scount++;
          } else if (hrowUbidask > midpointPrice) {
            rowClassName += "lrow lrow" + lcount;
            if (rowShow.lShow.length > lcount) {
              isShow = rowShow.lShow[lcount];
            }
            lcount++;
          }

          // tablecontent += "<tr class='" + rowClassName + "'><td>";
          // tablecontent += Number(isPut? hrow.underlying_ask : hrow.underlying_bid).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(hrow.bid).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(hrow.ask).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(isPut? hrow.underlying_bid : hrow.underlying_ask).toFixed(3);
          // tablecontent += "</td></tr>";
          let showStyle = { display: "none" };
          if (isShow) {
            showcount += 1;
            showStyle = {};
            if (showcount % 2 === 0) {
              rowClassName += " bgcolor-08";
            }
          }
          const hRow = (
            <tr key={i} className={rowClassName} style={showStyle}>
              <td>
                {Number(
                  this.isPut ? hrow.underlying_ask : hrow.underlying_bid
                ).toFixed(3)}
              </td>
              <td>{Number(hrow.bid).toFixed(3)}</td>
              <td>{Number(hrow.ask).toFixed(3)}</td>
              <td>
                {Number(
                  this.isPut ? hrow.underlying_bid : hrow.underlying_ask
                ).toFixed(3)}
              </td>
            </tr>
          );
          tablecontent.push(hRow);

          // tablecontent += "<tr class='" + rowClassName + "'><td>";
          // tablecontent += Number(isPut? trow.underlying_ask : trow.underlying_bid).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(trow.bid).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(trow.ask).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(isPut? trow.underlying_bid : trow.underlying_ask).toFixed(3);
          // tablecontent += "</td></tr>";
          const tRow = (
            <tr key={i + 1} className={rowClassName} style={showStyle}>
              <td>
                {Number(
                  this.isPut ? trow.underlying_ask : trow.underlying_bid
                ).toFixed(3)}
              </td>
              <td>{Number(trow.bid).toFixed(3)}</td>
              <td>{Number(trow.ask).toFixed(3)}</td>
              <td>
                {Number(
                  this.isPut ? trow.underlying_bid : trow.underlying_ask
                ).toFixed(3)}
              </td>
            </tr>
          );
          tablecontent.push(tRow);
          i++;
        }
      }
    }
    // tableElement.html(tablecontent);
    // if (ric_data.BID == 0.04) {
    //     tableElement.find(".srow").hide();
    //     tableElement.find(".lrow").hide();
    //     for (var i = lcount; i >= lcount - 8; i--) {
    //         tableElement.find(".lrow" + i).show();
    //     }
    // } else {
    //     tableElement.find(".srow").hide();
    //     tableElement.find(".lrow").hide();
    //     for (var i = lcount; i >= lcount - 8; i--) {
    //         tableElement.find(".lrow" + i).show();
    //     }
    //     for (var i = 1; i <= 8; i++) {
    //         tableElement.find(".srow" + i).show();
    //     }
    // }
    // var isOdd = true;
    // var cnt = 1;
    // tableElement.find('tr:visible').filter(function () {
    //     if (cnt > 2) {
    //         cnt = 1;
    //         isOdd = !isOdd;
    //     }
    //     cnt++;
    //     return isOdd;
    // }).addClass('bgcolor-08');
    return tablecontent;
  }

  // Case 2.2: !(isHKStock === false && isSP === false) && is_compressed === false
  case2_2() {
    const midpoint = this.findBIDMidPoint(false, false);
    let tick = 0.001;
    const midprice = Number(midpoint.price);
    if (midprice >= 0.2) tick = 0.005;
    var minvalue = Number((midprice - 5 * tick).toFixed(4));
    var macvalue = Number((midprice + 5 * tick).toFixed(4));
    // var ticklimit = 10;
    // var livematrixindex = 0;
    // var livematrixlimit = 14;
    // livematrixindex = midpoint.index;
    // if (livematrixindex === 0) {
    //     livematrixindex = Math.ceil(this.livematrix.length / 2);
    // }
    const tablecontent = [];
    for (var i = 0; i < this.livematrix.length; i++) {
      const livematrixdata = this.livematrix[i];
      const lmdatabid = Number(livematrixdata.bid);
      if (lmdatabid >= minvalue && lmdatabid <= macvalue) {
        let isMidPoint =
          livematrixdata.bid.toFixed(3) === midpoint.price.toFixed(3)
            ? true
            : false;
        if (!isMidPoint && midpoint.diff !== 0) {
          isMidPoint =
            livematrixdata.bid.toFixed(3) === midpoint.price2.toFixed(3)
              ? true
              : false;
        }
        // tablecontent += "<tr><td>";
        // tablecontent += (isPut? livematrixdata.underlying_ask : livematrixdata.underlying_bid ).toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += livematrixdata.bid.toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += livematrixdata.ask.toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += (isPut? livematrixdata.underlying_bid : livematrixdata.underlying_ask).toFixed(3);
        // tablecontent += "</td></tr>";
        const rowClassName = i % 2 === 0 ? " bgcolor-08" : "";
        const row = (
          <tr key={i} className={rowClassName}>
            <td>
              {(this.isPut
                ? livematrixdata.underlying_ask
                : livematrixdata.underlying_bid
              ).toFixed(3)}
            </td>
            <td>{livematrixdata.bid.toFixed(3)}</td>
            <td>{livematrixdata.ask.toFixed(3)}</td>
            <td>
              {(this.isPut
                ? livematrixdata.underlying_bid
                : livematrixdata.underlying_ask
              ).toFixed(3)}
            </td>
          </tr>
        );
        tablecontent.push(row);
      }
    }
    // tableElement.html(tablecontent);
    // tableElement.find('tr:visible').filter(':even').addClass('bgcolor-08');
    return tablecontent;
  }
}
class TableGeneral extends Component {
  getData(fieldName, dp = null) {
    if (
      this.props.warrantdata !== null &&
      fieldName in this.props.warrantdata
    ) {
      const value = this.props.warrantdata[fieldName];
      if (dp !== null) {
        return Number(value).toFixed(dp);
      }
      return value;
    }
    return "";
  }

  getTheta() {
    // "last_trading_date": "23 Feb 21"
    const lasttradingdate = this.getData("last_trading_date");
    const currDate = new Date();
    const currDateMS = currDate.getTime();
    if (lasttradingdate.length > 0) {
      const lasttradingdateMS = Date.parse(lasttradingdate);
      const diff = currDateMS - lasttradingdateMS;
      // check if expired
      if (diff <= 24 * 60 * 60 * 1000) {
        return this.getData("theta", 4);
      }
    }
    return "-";
  }

  getMoneynessClass(nc) {
    let cnm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        cnm = "val mny up";
      } else if (value === 2) {
        cnm = "val mny down";
      }
    }
    return cnm;
  }

  getMoneynessName(nc) {
    let nm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        return "ITM";
      } else if (value === 2) {
        return "OTM";
      } else {
        return "ATM";
      }
    }
    return nm;
  }

  render() {
    const hstclose = this.getData("HST_CLOSE");
    const trdprc1 = this.getData("TRDPRC_1");
    const openprc = this.getData("OPEN_PRC");
    const acvol1scaled = this.getData("ACVOL_1_SCALED");
    const high1 = this.getData("HIGH_1");
    const turnoverscaled = this.getData("TURNOVER_SCALED");
    const low1 = this.getData("LOW_1");
    const underlyingprice = this.getData("underlying_price");
    const ticker = this.getData("ticker");
    const effectivegearing = this.getData("effective_gearing");
    const underlyingticker = this.getData("underlying_ticker");
    const delta = this.getData("delta");
    const type = this.getData("type");
    const deltaperwrnt = this.getData("delta_per_wrnt");
    const issuername = this.getData("issuer_name");
    const sensitivity = this.getData("sensitivity");
    const exerciseprice = this.getData("exercise_price");
    const breakevenprice = this.getData("breakeven_price");
    const tradedate = this.getData("TRADE_DATE");
    const premium = this.getData("premium");
    const maturity = this.getData("maturity");
    const impliedvolatility = this.getData("implied_volatility");
    const lasttradingdate = this.getData("last_trading_date");
    const theta = this.getTheta();
    const daystomaturity = this.getData("days_to_maturity") + " days";
    const intrinsicvalue = this.getData("intrinsic_value");
    const convratio = this.getData("conv_ratio");
    const moneyness = this.getData("moneyness");
    const moneynessClass = this.getMoneynessClass(moneyness);
    const moneynessdisplaystring =
      this.getData("percent_moneyness") +
      "% " +
      this.getMoneynessName(moneyness);

    return (
      <div>
        <div id="srtable" style={{ position: "relative" }}>
          <table className="table priorTable">
            <tbody>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Prior
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HST_CLOSE"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {hstclose}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Last trade
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TRDPRC_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {trdprc1}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ border: 0, textAlign: 'left' }}>
                  Open
                </th>
                <td
                  className="srtable-leftval val OPEN_PRC"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {openprc}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Traded volume ('000)
                </th>
                <td className="srtable-rightval val ACVOL_1_SCALED" style={{ textAlign: 'left' }}>
                  {acvol1scaled}
                </td>
              </tr>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left', textAlign: 'left' }}
                >
                  High
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HIGH_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {high1}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Traded value ('000 SGD)
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TURNOVER_SCALED"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {turnoverscaled}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ textAlign: 'left' }}>Low</th>
                <td className="srtable-leftval val LOW_1" style={{ border: 0, textAlign: 'left' }}>
                  {low1}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Underlying price
                </th>
                <td
                  className="srtable-rightval val underlying_price"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {underlyingprice}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import Select from "react-select";
import classNames from "classnames";
import PageHeader from "../../../components/pageHeader/pageHeader";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import WarrantCalculator from "../warrantcalculator/calculator";
import AdvertBox from "../../../components/advert/advert";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { components } from "react-select";
import ReactToPrint from "react-to-print";
import "./livematrix.css";
import LiveIndexFuture from "../../../components/liveindexfuture/liveindexfuture";
import highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import nodata from "highcharts/modules/no-data-to-display";
import "../warrantterm/term.css";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
export default class LiveMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      symbols: null,
      livematrixdata: null,
      isLoading: true,
      tabNum: 3,
      period: 'Y',
      chartData: [],
      isLoadingChart: false
    };
    this.page = "livematrix";
    this.logic = null;
    this.ticker = null;
    this.initRedirect(props);
  }
  onPeriodChange(period) {
    if (this.state.livematrixdata !== null) {
      const ric = this.state.selected.value;
      const underlyingric = this.state.livematrixdata.ric_data.underlying_ric;
      if (underlyingric !== null) {
        // const selectedNew = { ...this.state.selected };
        this.setState({ period: period, chartdata: null });
        this.onFetchChartData(underlyingric, ric, period);
      }
    }
  }
  onFetchChartData(underlyingRic, ric, period = "Y") {
    if (MQSGUtil.isUsingStaticData("Warrantterms")) {
      setTimeout(() => {
        this.onFetchStaticChartData(underlyingRic, ric, period);
      }, 100); // pretend fetch
      return;
    }
    const isLoadingChart = false;
    const ricString = underlyingRic + ";" + ric;
    let chartdata = null;
    const url =
      MQSGUtil.getAPIBasePath() +
      "/alert?TYPE=JSON&RIC=" +
      ricString +
      "&PERIODICITY=" +
      period +
      "&d=" +
      Date.now();
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          chartdata = result;
          this.setState({ chartdata, isLoadingChart });
        },
        (error) => {
          this.setState({ chartdata, isLoadingChart });
        }
      );
  }
  componentDidMount() {
    if (!this.isHome()) {
      document.title = "Live matrix | Singapore Warrants | Macquarie"
      if (this.props.match.params.ticker) {
        let symbols = null;
        const url =
          MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=AEML_tm.SI&mode=0";
        fetch(url)
          .then((res) => res.json())
          .then(
            (result) => {
              if ("symbols" in result) {
                symbols = result.symbols;
                var tabNum = sessionStorage.getItem('tab1');
                if (!tabNum) {
                  tabNum = 3
                }
                this.setState({ symbols, tabNum });
                let check = symbols.filter((item) => {
                  if (item['security_code'] == this.props.match.params.ticker) {
                    item.label = item.dsply_nmll + " (" + item.security_code + ")";
                    item.value = item.ric;
                    return item
                  }
                })
                if (check.length > 0) {
                  var initselected = { label: check[0].label, value: check[0].value, future_dsply_name: check[0].future_dsply_name }
                  this.onWarrantChange(initselected);
                } else {
                  var initselected = this.initSelection(symbols);
                  this.onWarrantChange(initselected);
                }
                // security_code
                // const initselected = this.initSelection(symbols);
                // this.onWarrantChange(initselected);
              }
            },
            (error) => {
              this.setState({ symbols, isLoading: false });
            }
          );
      } else {
        this.onFetchSymbol();
      }
    } else {
      this.onFetchSymbol();
    }
  }
  setTab(i) {
    if (i != this.state.tabNum) {
      this.setState({
        tabNum: i
      }, () => {
        sessionStorage.setItem('tab1', i)
      })
    }
  }
  initRedirect(props) {
    const match = MQSGUtil.getData(props, "match", null);
    if (match !== null) {
      const params = MQSGUtil.getData(match, "params", null);
      if (params !== null) {
        const ticker = MQSGUtil.getData(params, "ticker", null);
        if (ticker !== null) {
          this.ticker = ticker.toUpperCase();
        }
      }
    }
  }

  initSelection(symbols) {
    let lastselection = MQSGUtil.getLastSelection(this.page);
    if (this.ticker !== null) {
      lastselection = null;
    }
    let selectedsymbol = null;
    let initsymbol = null;
    if (symbols !== null && Array.isArray(symbols)) {
      symbols.forEach((symbol) => {
        if ("ric" in symbol) {
          if (initsymbol === null) {
            initsymbol = symbol;
          }
          if (lastselection === symbol.ric) {
            selectedsymbol = symbol;
          }
        }
        if ("security_code" in symbol) {
          if (this.ticker === symbol["security_code"]) {
            selectedsymbol = symbol;
          }
        }
      });
    }
    if (selectedsymbol !== null) {
      initsymbol = selectedsymbol;
    }
    if (initsymbol !== null) {
      MQSGUtil.updateLastSelection(this.page, initsymbol.ric);
      if ("dsply_nmll" in initsymbol && "security_code" in initsymbol) {
        return {
          label: initsymbol.dsply_nmll + " (" + initsymbol.security_code + ")",
          value: initsymbol.ric,
          future_dsply_name: initsymbol.future_dsply_name
        };
      }
    }
    this.ticker = null;
    return null;
  }

  onFetchStaticSymbol() {
    let symbols = null;
    const staticData = MQSGUtil.getStaticData("LiveMatrix");
    if ("symbols" in staticData) {
      symbols = staticData.symbols;
      this.setState({ symbols });
      const initselected = this.initSelection(symbols);
      this.onWarrantChange(initselected);
    } else {
      this.setState({ symbols, isLoading: false });
    }
  }

  onFetchSymbol() {
    if (MQSGUtil.isUsingStaticData("LiveMatrix")) {
      this.onFetchStaticSymbol();
      return;
    }

    let symbols = null;
    const url =
      MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=AEML_tm.SI&mode=0";
    // MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=SXML_t34.SI&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          if ("symbols" in result) {
            symbols = result.symbols;
            this.setState({ symbols });
            const initselected = this.initSelection(symbols);
            this.onWarrantChange(initselected);
          }
        },
        (error) => {
          this.setState({ symbols, isLoading: false });
        }
      );
  }

  onFetchStaticData(ric) {
    const isLoading = false;
    const staticData = MQSGUtil.getStaticData("LiveMatrix");
    if (ric in staticData) {
      let livematrixdata = staticData[ric];
      this.logic = new LivematrixLogic(
        livematrixdata.livematrix,
        livematrixdata.ric_data
      );
      this.setState({ livematrixdata, isLoading });
    } else {
      this.setState({ livematrixdata: null, isLoading });
    }
  }

  onFetchData(ric) {
    if (MQSGUtil.isUsingStaticData("LiveMatrix")) {
      setTimeout(() => {
        this.onFetchStaticData(ric);
      }, 100); // pretend fetch
      return;
    }
    const isLoading = false;
    const url =
      MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=" + ric + "&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const livematrixdata = result;
          this.logic = new LivematrixLogic(
            livematrixdata.livematrix,
            livematrixdata.ric_data
          );
          this.setState({ livematrixdata, isLoading }, () => {
            const underlyingric = this.state.livematrixdata.ric_data.underlying_ric;
            this.onFetchChartData(
              underlyingric,
              ric,
              this.state.period
            );
          });
        },
        (error) => {
          this.setState({ livematrixdata: null, isLoading });
        }
      );
  }

  onWarrantChange(selected) {
    if (selected !== undefined && selected !== null) {
      this.logic = null; // hide previous data
      this.setState({ selected, livematrixdata: null, isLoading: true });
      this.onFetchData(selected.value);

      MQSGUtil.updateLastSelection(this.page, selected.value);
    }
  }

  onRefresh() {
    this.onWarrantChange(this.state.selected);
  }

  getRicData() {
    if (this.state.livematrixdata !== null) {
      if ("ric_data" in this.state.livematrixdata) {
        return this.state.livematrixdata.ric_data;
      }
    }
    return null;
  }

  getLastUpdate() {
    if (this.state.livematrixdata !== null) {
      if ("last_update" in this.state.livematrixdata) {
        return this.state.livematrixdata["last_update"];
      }
    }
    return "-";
  }

  getSymbols() {
    if (this.state.symbols !== null) {
      if (Array.isArray(this.state.symbols)) {
        return this.state.symbols;
      }
    }
    return null;
  }

  getSelectedLabel() {
    if (this.state.selected === null) {
      return "";
    }
    return this.state.selected.label;
  }

  isLoading() {
    return this.state.isLoading;
  }

  isHome() {
    if (this.props.page !== undefined && this.props.page !== null) {
      if (this.props.page === "home") {
        return true;
      }
    }
    return false;
  }

  renderHome() {
    let isShow = true;
    var isSti = false;
    if (this.logic != null) {
      if (this.logic.isSTI) {
        isSti = true;
      } else {
        isSti = false;
      }
    }
    if (
      this.state.livematrixdata == null ||
      this.state.livematrixdata.livematrix.length === 0 ||
      isSti
    ) {
      isShow = false;
    }
    var code = '';
    if (this.state.selected != null) {
      code = this.state.selected.label.slice(this.state.selected.label.indexOf('(') + 1, this.state.selected.label.indexOf(')'))
    }
    return (
      <div className="holder-lmatrix" >
        <div id="lmatrix">
          <div className="page-header">
            <h2 className="small">Live matrix</h2>
          </div>
          <p className="section-p">
            Shows investors where our bid and offer prices are for a range of
            given underlying prices.
          </p>
        </div>
        <div>
          <Warrant
            symbols={this.getSymbols()}
            disabled={this.isLoading()}
            isHome={this.isHome()}
            onWarrantChange={(selected) => this.onWarrantChange(selected)}
          />
        </div>
        <div
          className="ricbar-home bgcolor-03"
          style={{
            // display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            marginTop: "10px",
            display: !isShow ? "none" : "flex",
            overflowX: 'auto'
          }}
        >
          <div
            className="ticker-home"
            id="ticker"
            style={{ paddingLeft: "10px", lineHeight: '35px' }}
          >
            {this.getSelectedLabel()}
          </div>
          <a href={"/tools/livematrix/" + code} style={{ display: "flex", alignItems: 'center' }} target="_blank">
            <p style={{ margin: "4px", lineHeight: "25px" }}>View more</p>
            <img src="/img/bullet/qlink-achor.png" alt="" style={{ height: 36, width: 36 }} />
          </a>
        </div>
        <div
          style={{
            display: isShow || !isSti ? "none" : "block",
            marginTop: "10px",
            fontStyle: "italic",
          }}
        >
          Please select a warrant to see its live matrix.
        </div>
        <LiveMatrixTodayTable logic={this.logic} page={this.props.page} isLoading={this.state.isLoading} />
      </div>
    );
  }
  childMethod() {
    if (this.refs.child) {
      this.refs.child.onClickTradeWarrantsHere()
    }
  }
  getTermHtml() {
    if (this.state.livematrixdata != null) {
      return (
        <div id="dwterms">
          <TermsSummary
            warrantdata={this.state.livematrixdata.ric_data}
            tabNum={this.state.tabNum}
          />
          {/* <TableGeneral
            warrantdata={this.state.livematrixdata.ric_data}
          /> */}

        </div>
      )
    }
  }
  renderPage() {
    let ric = null;
    let name = null;
    let isSpShow = false;
    let isWarrantsShow = false;
    let isUnderyingShow = false;
    let future_dsply_name = '';
    let spanShow = false;
    if (
      typeof this.state.selected !== "undefined" &&
      this.state.selected !== null
    ) {
      ric = this.state.selected.value;
      name = this.state.selected.label;
      // if (this.state.selected.label.indexOf('S&P') != -1 && this.state.selected.future_dsply_name != '' && this.state.selected.future_dsply_name != '-') {
      //   isSpShow = true
      // }
      // if (this.state.selected.label.indexOf('S&P') == -1 && this.state.selected.future_dsply_name != '' && this.state.selected.future_dsply_name != '-') {
      //   isUnderyingShow = true;
      //   future_dsply_name = this.state.selected.future_dsply_name;
      //   if (this.state.selected.label.indexOf('NDX') != -1) {
      //     spanShow = true;
      //     future_dsply_name = ' Nasdaq-100';
      //   }
      //   if (this.state.selected.label.indexOf('DJI') != -1) {
      //     spanShow = true;
      //     future_dsply_name = ' Dow Jones Industrial Average';
      //   }
      // }
      if (
        this.state.selected.label.indexOf('S&P') != -1 ||
        this.state.selected.label.indexOf('DJI') != -1 ||
        this.state.selected.label.indexOf('HSI') != -1 ||
        this.state.selected.label.indexOf('HSTECH') != -1 ||
        this.state.selected.label.indexOf('NKY') != -1 ||
        this.state.selected.label.indexOf('SIMSCI') != -1 ||
        this.state.selected.label.indexOf('Sea') != -1 ||
        this.state.selected.label.indexOf('NASDAQ') != -1
      ) {
        isWarrantsShow = true;
      } else {
        if (this.state.selected.future_dsply_name && this.state.selected.future_dsply_name != '' && this.state.selected.future_dsply_name != '-') {
          isUnderyingShow = true;
          future_dsply_name = this.state.selected.future_dsply_name;
        }
      }
    }
    var isShow = false;
    if (this.state.tabNum == 2) {
      isShow = true
    } else {
      isShow = false
    }
    return (
      <div id="livematrix" className="pageobj" onClick={() => this.childMethod()}>
        {/* <Helmet>
          <title>Live matrix | Singapore Warrants | Macquarie</title>
          <meta name="keywords" content="live matrix, live pricing, bid price" />
          <meta name="description"
            content="Use our live matrix to make trading warrants easy. See where Macquarie's bid prices for index and single stock structured warrants will be throughout the day." />
        </Helmet> */}
        <BreadcrumbBar
          link="/home"
          group="Warrant tools"
          title="Live matrix"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <PageHeader title="Live matrix" ref="child" />
                <div>
                  Shows investors where our bid and offer prices are for a range
                  of given underlying prices.{" "}
                  <span
                    className="ttip"
                    data-target="livematrixdescription"
                    data-hasqtip="0"
                    data-tip
                    data-for="indicator-hotdw-tooltip"
                    data-tooltip-id="indicator-hotdw-tooltip"
                  >
                    <mark>
                      Read on to find out how the matrix for put warrants differ
                      from calls
                    </mark>
                    <ReactTooltip
                      id="indicator-hotdw-tooltip"
                      delayHide={100}
                      place="right"
                      type="warning"
                      effect="solid"
                      backgroundColor="#FECC0A"
                      textColor="#000"
                      className="ttip_con"
                    >
                      <div
                        style={{
                          color: "#333333",
                        }}
                      >
                        Put prices move in the opposite direction to the share
                        price, for this reason the price matrices
                        <br />
                        are the opposite to what you would find for a call
                        warrant, where..
                        <br />
                        The{" "}
                        <span style={{ textDecoration: "underline" }}>
                          offer
                        </span>{" "}
                        price for the warrant is based on the bid price of the
                        underlying
                        <br />
                        The{" "}
                        <span style={{ textDecoration: "underline" }}>
                          bid{" "}
                        </span>
                        price for the warrant is based on the offer price of the
                        underlying
                      </div>
                    </ReactTooltip>
                  </span>
                </div>
                {/* <p style={{ marginTop: "10px" }}>
                  <span style={{ fontWeight: 700 }}>Important notice: </span>
                  The live matrix may be updated during trading hours, and if so
                  they will be reflected when you refresh the live matrix. The
                  actual prices of warrants may differ from the prices displayed
                  below. Information contained in the live matrix may not be
                  accurate outside of our market making hours* and may
                  occasionally be delayed. Please call us at +65 6601 0289 if
                  you have any questions or require immediate assistance.
                </p> */}
                <p style={{ marginTop: "10px" }}>
                  The live matrix consists of a table showing a range of prices for the underlying’s bid/offer and warrant’s bid/offer.
                </p>
                <p style={{ marginTop: "10px" }}>
                  Call prices move in the same direction as the share price. The call warrant offer price is based on the underlying offer price while the bid price of the warrant is based on the underlying bid price.
                </p>
                <p style={{ marginTop: "10px" }}>
                  On the other hand, the offer price for a put warrant is based on the bid price of the underlying while the bid price for the warrant is based on the offer price of the underlying"
                </p>
                <div id="top_area">
                  <Warrant
                    symbols={this.getSymbols()}
                    disabled={this.isLoading()}
                    onWarrantChange={(selected) =>
                      this.onWarrantChange(selected)
                    }
                  />

                  <div className="tablist">
                    <div id="topTabs" className="tablist-left" style={{ overflowX: 'auto' }}>
                      <table>
                        <tbody>
                          <tr>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 1,
                              })}
                              onClick={() => this.setTab(1)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Historical Performance
                                </div>
                              </div>
                            </td>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 4,
                              })}
                              onClick={() => this.setTab(4)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Warrant terms
                                </div>
                              </div>
                            </td>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 2,
                              })}
                              onClick={() => this.setTab(2)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Warrant Calculator
                                </div>
                              </div>
                            </td>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 3,
                              })}
                              onClick={() => this.setTab(3)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Live matrix
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* <div className="ricbar  hidden-xs" style={{ display: "flex", padding: '0px', justifyContent: 'space-between' }}>
                    <div>
                      <button
                        id="resetbtn"
                        type="button"
                        onClick={() => this.setTab(1)}
                        className=" btn btn-primary resetbtn hidden-xs"
                        style={{ minWidth: "auto" }}
                      >
                        Historical Performance
                      </button>
                    </div>

                    <div>
                      <button
                        id="resetbtn"
                        type="button"
                        onClick={() => this.setTab(4)}
                        className=" btn btn-primary resetbtn hidden-xs"
                        style={{ minWidth: "auto" }}
                      >
                        Warrant terms
                      </button>
                    </div>

                    <div>
                      <button
                        id="resetbtn"
                        type="button"
                        onClick={() => this.setTab(2)}
                        className=" btn btn-primary resetbtn hidden-xs"
                        style={{ minWidth: "auto" }}
                      >
                        Warrant Calculator
                      </button>
                    </div>
                    <div>
                      <button
                        id="resetbtn"
                        type="button"
                        onClick={() => this.setTab(3)}
                        className=" btn btn-primary resetbtn hidden-xs"
                        style={{ minWidth: "auto" }}
                      >
                        Live matrix
                      </button>
                    </div>
                  </div> */}
                  {this.getTermHtml()}
                  {/* <div className="ricbar"> */}
                  {/* <div id="ticker" className="ricbar-lbl" style={{ width: '100%', display: this.state.tabNum == 4 ? 'none' : 'block' }}>
                      {this.getSelectedLabel()}
                    </div> */}

                  {/* <div>
                      <Link
                        href="/tools/warrantterms"
                        to={{
                          pathname: "/tools/warrantterms",
                          state: {
                            ric: ric,
                            name: name,
                          },
                        }}
                        id="viewwarrantterms"
                        type="button"
                        className="ricbar-btn btn btn-primary hidden-xs"
                        style={{ minWidth: "auto" }}
                      >
                        View warrant terms
                      </Link>
                    </div> */}
                  {/* <div>
                      <button
                        id="resetbtn"
                        type="button"
                        onClick={this.onRefresh.bind(this)}
                        className="ricbar-btn btn btn-primary resetbtn hidden-xs"
                        style={{ minWidth: "auto" }}
                      >
                        Refresh
                      </button>
                    </div> */}
                  {/* </div> */}


                  {/* <p style={{ marginTop: '10px', marginBottom: '0px', display: isSpShow ? 'block' : 'none' }}>Underlying price below refers to the relevant Index Futures contract&nbsp;
                    <span style={{ fontFamily: 'HelveticaNeueMedium' }}>S&P 500
                    </span>
                    <span style={{
                      fontSize: '50%',
                      verticalAlign: 'super'
                    }}>&reg;</span>
                  </p> */}
                  <div style={{ display: this.state.tabNum == 3 ? 'block' : 'none' }}>
                    <p style={{ marginTop: '10px', marginBottom: '0px', display: isWarrantsShow ? 'block' : 'none' }}>
                      Underlying price below refers to the relevant Index Futures contract. Refer to the "Live index futures prices" on the home page to verify which Index Futures it is tracking.<br /> For US indices, it is typically tracking the quarterly month futures - reach us at +65 6601 0189 to confirm the month should you be unsure.
                    </p>
                    <p id="p-contract" style={{ marginTop: '10px', marginBottom: '0px', display: isUnderyingShow ? 'block' : 'none' }}>Underlying price below refers to the relevant Index Futures contract

                      <span> over</span>
                      <span className="text-bold"> {future_dsply_name}</span>
                    </p>
                  </div>

                  {/* <div style={{ display: this.state.tabNum == 4 && this.state.livematrixdata != null ? 'block' : 'none' }}>
                    <TermsSummary
                      warrantdata={this.state.livematrixdata.ric_data}
                    />
                  </div> */}

                  {
                    // this.state.tabNum == 1
                    //   ? <TermsChart
                    //     selected={this.state.selected}
                    //     period={this.state.period}
                    //     chartdata={this.state.chartdata}
                    //     onPeriodChange={this.onPeriodChange.bind(this)}
                    //   />
                    //   : 
                    this.state.tabNum == 3 ? <LiveMatrixTodayTable logic={this.logic} isLoading={this.state.isLoading} /> : this.state.tabNum == 4 ? <IndtableShow
                      warrantdata={this.state.livematrixdata && this.state.livematrixdata.ric_data}
                    /> : null
                  }
                  {/* <LiveMatrixTodayTable logic={this.logic} isLoading={this.state.isLoading} /> */}
                  <div style={{ display: this.state.tabNum == 1 ? 'block' : 'none' }}>
                    <TermsChart
                      selected={this.state.selected}
                      period={this.state.period}
                      chartdata={this.state.chartdata}
                      onPeriodChange={this.onPeriodChange.bind(this)}

                    />
                  </div>

                  {/*<LiveMatrixTodayTable logic={this.logic} isLoading={this.state.isLoading} style={{ display: this.state.tabNum == 3 ? 'block' : 'none' }} /> */}
                  <div style={{ display: isShow ? 'block' : 'none' }} >
                    <WarrantCalculator isRightPanel={true} ric={ric} />
                  </div>

                </div>
                <div style={{ clear: "both" }}></div>
                <div id="bottom_area" className="livematrix_valid">
                  <div id="srtable" style={{ overflowX: 'unset' }}>
                    <div style={{ display: this.state.tabNum == 4 || this.state.tabNum == 3 ? 'none' : 'block', overflowX: 'auto' }}>
                      <LiveMatrixPriceTable ricdata={this.getRicData()} />
                    </div>
                    <div id="last_update">
                      Last update: <span>{this.getLastUpdate()}</span>
                    </div>
                    <p>
                      The above price matrix is a direct live feed from
                      Macquarie’s market making system. It indicates where the
                      market maker’s bids and offers may be based on various
                      price points in the stock or index, and as at the last
                      updated time stamp. Please manually refresh the live
                      matrix for the latest prices. Note that the live matrix
                      may not be accurate outside of market making hours. These
                      are:
                    </p>
                    <div style={{ overflowX: 'auto' }}>
                      <LiveMatrixMarketHourTable />
                    </div>
                  </div>
                  {/* 文字说明 */}
                  <TextDescrtion />
                </div>
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4">
              <LiveIndexFuture liveMatrix={true} isSti={this.state.selected} />
              <AdvertBox />
              <div id="qlink-dwvideo" className="holder-qlink-dwvideo ">
                <div className="videoplayer">
                  <div className="videoplayer-container">
                    <iframe
                      title="education video"
                      frameBorder="0"
                      allowFullScreen=""
                      className="dwvideo-iframe"
                      src="https://www.youtube.com/embed/_EqFjoCgE08?autoplay=0&index=0&rel=0&wmode=opaque"
                    ></iframe>
                  </div>
                </div>
              </div>
              {/* <WarrantCalculator isRightPanel={true} ric={ric} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.isHome()) {
      return this.renderHome();
    } else {
      return this.renderPage();
    }
  }
}

function LiveMatrixMarketHourTable() {
  return (
    <table
      className="markethourtbl table table-striped table-left"
      style={{ tableLayout: "auto", backgroundColor: "unset" }}
    >
      <tbody style={{ textAlign: 'left' }}>
        <tr>
          <th>Hong Kong stock warrants: </th>
          <td>
            9.30am to 11.58am,
            <br className="visible-xs" /><br className="visible-minpc" /> 1.00pm to 3.58pm{" "}
          </td>
        </tr>
        <tr>
          <th>HSI and HSTECH warrants:</th>
          <td>
            9.15am to 11.58am,
            <br className="visible-xs" /><br className="visible-minpc" /> 1.00pm to 4.28pm
          </td>
        </tr>
        <tr>
          <th>NKY warrants:</th>
          <td>
            9.00am to 11.58am,
            <br className="visible-xs" /><br className="visible-minpc" /> 1.00pm to 2.23pm,
            <br className="visible-xs" /><br className="visible-pc" /> 2.55pm to 4.58pm
          </td>
        </tr>
        <tr>
          <th>US index warrants:</th>
          <td>
            9.00am to 11:58am,
            <br className="visible-xs" /><br className="visible-minpc" /> 1:00pm to 4:58pm
          </td>
        </tr>
        <tr>
          <th>SIMSCI & Singapore stocks:</th>
          <td>
            9.00am to 11:58am,
            <br className="visible-xs" /><br className="visible-minpc" /> 1:00pm to 4:58pm
          </td>
        </tr>
      </tbody>
    </table>
  );
}

class LiveMatrixTodayTable extends Component {
  getTableContent(page) {
    if (this.props.logic !== null) {
      return this.props.logic.getTableContent(this.props.page);
    }
    return null;
  }

  getPrintRow() {
    if (this.isHome()) {
      return null;
    }
    var name = document.querySelector(".ricbar-lbl");
    name = name ? name.innerHTML : "";
    // var title = `Live matrix | Singapore Warrants | Macquarie | ${name}`;
    var title = `Live matrix | Singapore Warrants | Macquarie`;
    return (
      <tr>
        <th
          colSpan="4"
          className="th-left"
          style={{
            borderBottom: "0px",
            padding: "0px 0px",
            textAlign: "left",
          }}
        >
          {/* <ReactToPrint
            content={() => this.tableRef}
            documentTitle={title}
            trigger={() => (
              <img
                className="printimg"
                src="/img/bullet/print.png"
                alt="print live matrix"
                // onClick={this.print}
              />
            )}
          /> */}
          <img
            className="printimg"
            src="/img/bullet/print.png"
            alt="print live matrix"
            onClick={this.print}
            style={{
              width: '20px',
              marginTop: '-8px',
              cursor: 'pointer'
            }}
          />
        </th>
      </tr>
    );
  }
  print() {
    // var oldHtml = document.body.innerHTML;
    // var newHtml = document.getElementById("lmtablecontainer").innerHTML;
    // document.body.innerHTML = newHtml;
    // window.print();
    // document.body.innerHTML = oldHtml;
    // window.location.reload();
    $("#lmtablecontainer").printThis({
      pageTitle: $(document).find("title").text() + " | " + $("#ticker").html(),
      loadCSS: ["/src/pages/tools/livematrix/livematrix.css", "/css/global.css"],
      // importStyle: ["<style>html{background:'#fff'}}</style>"]
    });


    // //获取当前页的html代码
    // var bodyhtml = document.getElementById("lmtablecontainer").innerHTML;
    // //设置打印开始区域、结束区域
    // var startFlag = "/* startprint */";
    // var endFlag = "/* endprint */";
    // // 要打印的部分
    // var printhtml = bodyhtml.substring(
    //   bodyhtml.indexOf(startFlag),
    //   bodyhtml.indexOf(endFlag)
    // );
    // // 生成并打印ifrme
    // var f = document.getElementById("printf");
    // f.contentDocument.write(bodyhtml);
    // f.contentDocument.write(
    //   '<link rel="stylesheet" type="text/css" href="/css/global.css">'
    // );
    // f.contentDocument.write(
    //   '<link rel="stylesheet" type="text/css" href="/src/tools/livenatrix/livenatrix.css">'
    // );
    // f.contentDocument.close();
    // f.contentWindow.print();
  }
  isHome() {
    if (this.props.page !== undefined && this.props.page !== null) {
      if (this.props.page === "home") {
        return true;
      }
    }
    return false;
  }

  render() {
    const isLoading = this.props.isLoading;
    var className = '';
    if (isLoading) {
      className += " selector-loadingindicator";
    }
    const printRow = this.getPrintRow();
    var showAll = true;
    var show = false;
    if (
      this.getTableContent(this.props.page) != null
    ) {
      if (this.getTableContent(this.props.page).length == 0) {
        if (this.isHome() === true) {
          showAll = false;
        } else {
          showAll = true;
          if (isLoading) {
            show = false;
          } else {
            show = true;
          }

        }
      }

    } else {
      if (this.isHome() === true) {
        showAll = false;
      } else {
        showAll = true;
        if (isLoading) {
          show = false;
        } else {
          show = true;
        }

      }
    }
    var isSti = false;
    if (this.props.logic != null) {
      if (this.props.logic.isSTI) {
        isSti = true;
      } else {
        isSti = false;
      }
    }
    return (
      <div
        id="lmtablecontainer"
        ref={(el) => (this.tableRef = el)}
        className={className}
        style={{ paddingTop: "10px", overflowX: 'auto' }}
      >
        {/* startprint */}
        <table
          id="today_table"
          className="table lbtable"
          style={{
            position: "relative",
            width: '100%',
          }}
        >
          <thead
            style={{
              position: "relative",
              display: showAll ? "revert" : "none",
            }}
          >
            {printRow}
            <tr>
              <th width="30%">
                <span className="th-left th-callbid">{this.props.logic && this.props.logic.ricdata.type == 'PUT' ? 'Underlying offer' : 'Underlying bid'}</span>
              </th>
              <th width="20%">
                <span className="th-left">Warrant bid</span>
              </th>
              <th width="20%">
                <span className="th-right">Warrant offer</span>
              </th>
              <th width="20%">
                <span className="th-right th-calloffer">{this.props.logic && this.props.logic.ricdata.type != 'PUT' ? 'Underlying offer' : 'Underlying bid'}</span>
              </th>
            </tr>
          </thead>
          {/* <tbody style={{ display: isSti ? 'none' : 'revert' }}>{this.getTableContent(this.props.page)}</tbody> */}
          <tbody>{this.getTableContent(this.props.page)}</tbody>
          {/* {
            !isSti ? (
              <tbody
                style={{
                  display: show ? "revert" : "none",
                }}
              >
                <tr>
                  <td
                    colSpan="6"
                    className="text-content"
                    style={{ fontSize: "16px" }}
                  >
                    There is no matrix available for this warrant, as the warrant
                    has no value at current levels in the underlying
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody
                style={{ border: '0px', display: this.isHome() ? 'none' : 'revert' }}
              >
                <tr >
                  <td
                    colSpan="6"
                    className="text-content"
                    style={{ fontSize: "16px" }}
                  >
                    There is no live matrix for STI warrants as there are no actively traded STI futures in Singapore.
                  </td>
                </tr>
              </tbody>
            )
          } */}
          <tbody
            style={{
              display: show ? "revert" : "none",
            }}
          >
            <tr>
              <td
                colSpan="6"
                className="text-content"
                style={{ fontSize: "16px" }}
              >
                There is no matrix available for this warrant, as the warrant
                has no value at current levels in the underlying
              </td>
            </tr>
          </tbody>
        </table>
        {/* endprint */}
        {/* <iframe
          id="printf"
          src=""
          width="0"
          height="0"
          frameBorder="0"
        ></iframe> */}
      </div>
    );
  }
}

class LiveMatrixPriceTable extends Component {
  render() {
    const ricdata = this.props.ricdata;
    // const underlyingticker = MQSGUtil.getData(ricdata, "underlying_ticker");
    const underlyingticker =
      MQSGUtil.getUnderlyingTickerDisplayName(MQSGUtil.getData(ricdata, "underlying_ticker"));
    const effectivegearing = MQSGUtil.getData(ricdata, "effective_gearing");
    const type = MQSGUtil.getData(ricdata, "type");
    const delta = MQSGUtil.getData(ricdata, "delta");
    const exerciseprice = MQSGUtil.getData(ricdata, "exercise_price");
    const impliedvolatility = MQSGUtil.getData(ricdata, "implied_volatility");
    const wrntpershare = MQSGUtil.getData(ricdata, "wrnt_per_share");
    const lasttradingdate = MQSGUtil.getData(ricdata, "last_trading_date");

    return (
      <table className="pricetbl table tableSize">
        <tbody>
          <tr>
            <th className="srtable-leftlbl bgcolor-01">Underlying</th>
            <td
              id="underlying_ticker"
              className="srtable-leftval bgcolor-01"
              style={{ border: 0 }}
            >
              {underlyingticker}
            </td>
            <th className="srtable-rightlbl bgcolor-02" style={{ border: 0 }}>
              Effective gearing(X)
            </th>
            <td id="effective_gearing" className="srtable-rightval bgcolor-02">
              {effectivegearing}
            </td>
          </tr>
          <tr>
            <th>Type</th>
            <td id="type">{type}</td>
            <th>Delta(%)</th>
            <td id="delta">{delta}</td>
          </tr>
          <tr>
            <th className="bgcolor-01">Strike</th>
            <td
              id="exercise_price"
              className="bgcolor-01"
              style={{ border: 0 }}
            >
              {exerciseprice}
            </td>
            <th className="bgcolor-02">Volatility(%)</th>
            <td id="implied_volatility" className="bgcolor-02">
              {impliedvolatility}
            </td>
          </tr>
          <tr>
            <th>Exercise ratio</th>
            <td id="wrnt_per_share">{wrntpershare}</td>
            <th>Last trading date</th>
            <td id="last_trading_date">{lasttradingdate}</td>
          </tr>
        </tbody>
      </table>
    );
  }
}

class LivematrixLogic {
  constructor(livematrix, ricdata) {
    this.livematrix = livematrix;
    this.ricdata = ricdata;

    this.isMacq = false;
    if ("issuer_name" in ricdata) {
      if (ricdata.issuer_name.indexOf("Macquarie") > -1) {
        this.isMacq = true;
      }
    }
    this.isSTI = false;
    this.isXIAOMI = false;
    this.isSP = false;
    this.isHKStock = false;
    if ("underlying_ric" in ricdata) {
      if (ricdata.underlying_ric === ".STI") {
        this.isSTI = true;
      } else if (ricdata.underlying_ric.indexOf("ESc") > -1) {
        this.isSP = true;
      } else if (ricdata.underlying_ric.indexOf(".HK") > -1) {
        this.isHKStock = true;
      }
      if (ricdata.underlying_ric === "1810.HK") {
        this.isXIAOMI = true;
      }
    }
    this.isPut = false;
    if ("type" in ricdata) {
      if (ricdata.type === "PUT") {
        this.isPut = true;
      }
    }
    this.isCompressed = false;
    if (this.livematrix !== null) {
      if (this.livematrix.length > 0) {
        if ("is_compressed" in this.livematrix[0]) {
          if (this.livematrix[0].is_compressed) {
            this.isCompressed = true;
          }
        }
      }
    }
  }

  findMidPoint(iscompress, isfiveday) {
    const lmdata = this.livematrix;
    const type = this.ricdata.type;
    const lmprice = this.ricdata.underlying_bid_lm; // Number from source
    const mp = {
      price: -1,
      diff: -1,
      index: -1,
      price2: -1,
      diff2: -1,
      index2: -1,
    };
    if (lmdata !== null) {
      for (let i = 0; i < lmdata.length; i++) {
        if (iscompress && !(i % 2 === 0)) {
          //for compressed data, process only even data
          continue;
        }
        let ubid;
        if (isfiveday && !iscompress) {
          ubid = Object.keys(lmdata[i])[0]; // ?
        } else {
          ubid =
            lmdata[i][type === "PUT" ? "underlying_ask" : "underlying_bid"];
        }
        let diff = Math.abs(lmprice - ubid);
        if (mp.index === -1 || mp.diff > diff) {
          mp.diff2 = mp.diff;
          mp.price2 = mp.price;
          mp.index2 = mp.index;
          mp.diff = diff;
          mp.price = ubid;
          mp.index = i;
        }
        if (lmprice < ubid && mp.index === i - 1) {
          mp.diff2 = diff;
          mp.price2 = ubid;
          mp.index2 = i;
        }
      }
    }
    return mp;
  }

  findBIDMidPoint(iscompress, isfiveday) {
    const lmdata = this.livematrix;
    const lmprice = this.ricdata.BID;
    const type = this.ricdata.type;
    const mp = {
      price: -1,
      diff: -1,
      index: -1,
      price2: -1,
      diff2: -1,
      index2: -1,
    };
    for (let i = 0; i < lmdata.length; i++) {
      if (iscompress && !(i % 2 === 0)) {
        //for compressed data, process only even data
        continue;
      }
      let ubid;
      if (isfiveday && !iscompress) {
        ubid = Object.keys(lmdata[i])[0];
      } else {
        ubid = lmdata[i][type === "PUT" ? "bid" : "bid"];
      }
      const diff = Math.abs(lmprice - ubid);
      if (mp.index === -1 || mp.diff > diff) {
        mp.diff2 = mp.diff;
        mp.price2 = mp.price;
        mp.index2 = mp.index;
        mp.diff = diff;
        mp.price = ubid;
        mp.index = i;
      }
      if (lmprice < ubid && mp.index === i - 1) {
        mp.diff2 = diff;
        mp.price2 = ubid;
        mp.index2 = i;
      }
    }
    return mp;
  }

  getTableContent(page) {
    if (page) {
      return this.getHomeData();
    } else {
      if (this.livematrix.length == 0) {
        return;
      }
      if (this.isHKStock === false && this.isSP === false) {
        return this.case1();
      }
      return this.case2();
    }
  }
  getHomeData() {
    const ubidask = this.isPut ? "underlying_ask" : "underlying_bid";
    //update quote data and description
    var tablecontent = [];
    if (this.livematrix && this.livematrix.length > 4 && !this.isSTI) {
      var midpoint;
      if (!this.isXIAOMI) {
        if (this.isCompressed) {
          midpoint = this.findMidPoint(
            this.livematrix,
            this.ricdata.underlying_bid_lm,
            true,
            false,
            this.ricdata.type
          );
          const rowShow = this.case1_1_show(ubidask, midpoint);
          var hrow, trow;
          var scount = 1;
          var lcount = 1;
          var showcount = -1;
          var startIndex, endIndex;
          if (midpoint.index > this.livematrix.length - 3) {
            startIndex = midpoint.index - 4;
            endIndex = midpoint.index + 1;
          } else if (midpoint.index < 2) {
            startIndex = 0;
            endIndex = 5;
          } else {
            startIndex = midpoint.index - 2;
            endIndex = midpoint.index + 3;
          }
          for (var i = startIndex; i < endIndex + 1; i++) {
            hrow = this.livematrix[i];
            trow = this.livematrix[i + 1];

            if (!isNaN(hrow[ubidask])) {
              hrow[ubidask] = Number(hrow[ubidask]);
            }
            if (hrow.head_tail_id === trow.head_tail_id) {
              var classrow = "";
              let isShow = false;
              if (hrow[ubidask] === midpoint.price) {
                classrow += "midpoint";
                isShow = true;
              } else if (hrow[ubidask] < midpoint.price) {
                classrow += "srow srow" + scount;
                if (rowShow.sShow.length > scount) {
                  isShow = rowShow.sShow[scount];
                }
                scount++;
              } else if (hrow[ubidask] > midpoint.price) {
                classrow += "lrow lrow" + lcount;
                if (rowShow.lShow.length > lcount) {
                  isShow = rowShow.lShow[lcount];
                }
                lcount++;
              }
              // let showStyle = { display: "none" }; 有修改
              let showStyle = {};
              if (isShow) {
                showcount += 1;
                showStyle = {};
                if (showcount % 2 === 0) {
                  classrow += " bgcolor-08";
                }
              }
              const hRow = (
                <tr key={i} className={classrow} style={showStyle}>
                  <td>
                    {Number(
                      this.isPut ? hrow.underlying_ask : hrow.underlying_bid
                    ).toFixed(3)}
                  </td>
                  <td>{Number(hrow.bid).toFixed(3)}</td>
                  <td>{Number(hrow.ask).toFixed(3)}</td>
                  <td>
                    {Number(
                      this.isPut ? hrow.underlying_bid : hrow.underlying_ask
                    ).toFixed(3)}
                  </td>
                </tr>
              );
              tablecontent.push(hRow);
              const tRow = (
                <tr key={i + 1} className={classrow} style={showStyle}>
                  <td>
                    {Number(
                      this.isPut ? trow.underlying_ask : trow.underlying_bid
                    ).toFixed(3)}
                  </td>
                  <td>{Number(trow.bid).toFixed(3)}</td>
                  <td>{Number(trow.ask).toFixed(3)}</td>
                  <td>
                    {Number(
                      this.isPut ? trow.underlying_bid : trow.underlying_ask
                    ).toFixed(3)}
                  </td>
                </tr>
              );
              tablecontent.push(tRow);
              i++;
            }
          }
        } else {
          midpoint = this.findMidPoint(
            this.livematrix,
            this.ricdata.underlying_bid_lm,
            false,
            false,
            this.ricdata.type
          );
          var livematrixindex = 0;
          var livematrixlimit = 9;
          livematrixindex = midpoint.index;
          if (livematrixindex === 0) {
            livematrixindex = Math.ceil(this.livematrix.length / 2);
          }
          let startIndex, endIndex;
          if (midpoint.index === this.livematrix.length - 1) {
            startIndex = midpoint.index - 4;
            endIndex = midpoint.index;
          } else if (midpoint.index < 2) {
            startIndex = 0;
            endIndex = 4;
          } else {
            startIndex = midpoint.index - 2;
            endIndex = midpoint.index + 2;
          }
          for (let i = startIndex; i < endIndex + 1; i++) {
            var livematrixdata = this.livematrix[i];
            var isMidPoint =
              livematrixdata.underlying_bid.toFixed(3) ===
                midpoint.price.toFixed(3)
                ? true
                : false;
            let rowClassName = isMidPoint ? "midpoint" : "";
            rowClassName += i % 2 === 0 ? " bgcolor-08" : "";
            const row = (
              <tr key={i} className={rowClassName}>
                <td>
                  {(this.isPut
                    ? livematrixdata.underlying_ask
                    : livematrixdata.underlying_bid
                  ).toFixed(3)}
                </td>
                <td>{livematrixdata.bid.toFixed(3)}</td>
                <td>{livematrixdata.ask.toFixed(3)}</td>
                <td>
                  {(this.isPut
                    ? livematrixdata.underlying_bid
                    : livematrixdata.underlying_ask
                  ).toFixed(3)}
                </td>
              </tr>
            );
            tablecontent.push(row);
          }
        }
      } else {
        for (let i = 0; i < this.livematrix.length; i++) {
          let livematrixdata = this.livematrix[i];
          const rowClassName = i % 2 === 0 ? " bgcolor-08" : "";
          const row = (
            <tr key={i} className={rowClassName}>
              <td>
                {(this.isPut
                  ? livematrixdata.underlying_ask
                  : livematrixdata.underlying_bid
                ).toFixed(3)}
              </td>
              <td>{livematrixdata.bid.toFixed(3)}</td>
              <td>{livematrixdata.ask.toFixed(3)}</td>
              <td>
                {(this.isPut
                  ? livematrixdata.underlying_bid
                  : livematrixdata.underlying_ask
                ).toFixed(3)}
              </td>
            </tr>
          );
          tablecontent.push(row);
        }
      }
    }
    return tablecontent;
  }
  getPageData() { }
  //Case 1: isHKStock === false && isSP === false
  case1() {
    if (this.isCompressed) {
      return this.case1_1();
    }
    return this.case1_2();
  }

  //Case 1.1: isHKStock === false && isSP === false && is_compressed === true
  case1_1_show(ubidask, midpoint) {
    let hrow, trow;
    let scount = 1,
      lcount = 1;
    for (let i = 0; i < this.livematrix.length; i++) {
      hrow = this.livematrix[i];
      trow = this.livematrix[i + 1];
      let hrowUbidask = null;
      if (ubidask in hrow) {
        hrowUbidask = Number(hrow[ubidask]);
      }
      let midpointPrice = Number(midpoint.price);
      if (
        hrowUbidask !== null &&
        !isNaN(hrowUbidask) &&
        !isNaN(midpointPrice)
      ) {
        if (hrowUbidask < midpointPrice) {
          scount++;
        } else if (hrowUbidask > midpointPrice) {
          lcount++;
        }
      }
      i++;
    }
    const sShow = new Array(scount).fill(false);
    const lShow = new Array(lcount).fill(false);

    // if ("BID" in this.ricdata && Number(this.ricdata.BID) === 0.04) {
    //   for (let i = lcount; i >= lcount - 8; i--) {
    //     lShow[i] = true;
    //   }
    // } else {
    for (let i = lcount; i >= lcount - 8; i--) {
      lShow[i] = true;
    }
    for (let i = 1; i <= 8; i++) {
      sShow[i] = true;
    }
    // }
    return { sShow, lShow };
  }

  case1_1() {
    const ubidask = this.isPut ? "underlying_ask" : "underlying_bid";
    const midpoint = this.findMidPoint(true, false);
    const lm = this.ricdata.underlying_bid_lm;
    let hrow, trow;
    let scount = 1,
      lcount = 1,
      showcount = -1;
    const rowShow = this.case1_1_show(ubidask, midpoint);
    const tablecontent = [];
    var midpointPrice = Number(midpoint.price2);
    for (let i = 0; i < this.livematrix.length - 1; i++) {
      hrow = this.livematrix[i];
      trow = this.livematrix[i + 1];
      let hrowUbidask = null;
      let trowUbidask = null;
      if (ubidask in hrow) {
        hrowUbidask = Number(hrow[ubidask]);
      }
      if (ubidask in trow) {
        trowUbidask = Number(trow[ubidask]);
      }
      if (hrowUbidask === midpointPrice) {
        if (hrowUbidask > lm) {
          if (trowUbidask > lm) {
            midpointPrice = Number(midpoint.price)
          }
        } else {
          if (trowUbidask < lm) {
            midpointPrice = Number(midpoint.price)
          }
        }
      }
      if (
        hrowUbidask !== null &&
        !isNaN(hrowUbidask) &&
        !isNaN(midpointPrice)
      ) {
        if (hrow.head_tail_id === trow.head_tail_id) {
          let rowClassName = "";
          let isShow = false;
          if (hrowUbidask === midpointPrice) {
            rowClassName += "midpoint";
            isShow = true;
          } else if (hrowUbidask < midpointPrice) {
            rowClassName += "srow srow" + scount;
            if (rowShow.sShow.length > scount) {
              isShow = rowShow.sShow[scount];
            }
            scount++;
          } else if (hrowUbidask > midpointPrice) {
            rowClassName += "lrow lrow" + lcount;
            if (rowShow.lShow.length > lcount) {
              isShow = rowShow.lShow[lcount];
            }
            lcount++;
          }
          let showStyle = { display: "none" };
          if (isShow) {
            showcount += 1;
            showStyle = {};
            if (showcount % 2 === 0) {
              rowClassName += " bgcolor-08";
            }
          }
          const hRow = (
            <tr key={i} className={rowClassName} style={showStyle}>
              <td>
                {Number(
                  this.isPut ? hrow.underlying_ask : hrow.underlying_bid
                ).toFixed(3)}
              </td>
              <td>{Number(hrow.bid).toFixed(3)}</td>
              <td>{Number(hrow.ask).toFixed(3)}</td>
              <td>
                {Number(
                  this.isPut ? hrow.underlying_bid : hrow.underlying_ask
                ).toFixed(3)}
              </td>
            </tr>
          );
          tablecontent.push(hRow);
          const tRow = (
            <tr key={i + 1} className={rowClassName} style={showStyle}>
              <td>
                {Number(
                  this.isPut ? trow.underlying_ask : trow.underlying_bid
                ).toFixed(3)}
              </td>
              <td>{Number(trow.bid).toFixed(3)}</td>
              <td>{Number(trow.ask).toFixed(3)}</td>
              <td>
                {Number(
                  this.isPut ? trow.underlying_bid : trow.underlying_ask
                ).toFixed(3)}
              </td>
            </tr>
          );
          tablecontent.push(tRow);
          i++;
        }
      }
    }
    // tableElement.html(tablecontent);
    // if (ric_data.BID == 0.04) {
    //     tableElement.find(".srow").hide();
    //     tableElement.find(".lrow").hide();
    //     for (var i = lcount; i >= lcount - 8; i--) {
    //         tableElement.find(".lrow" + i).show();
    //     }
    // } else {
    //     tableElement.find(".srow").hide();
    //     tableElement.find(".lrow").hide();
    //     for (var i = lcount; i >= lcount - 8; i--) {
    //         tableElement.find(".lrow" + i).show();
    //     }
    //     for (var i = 1; i <= 8; i++) {
    //         tableElement.find(".srow" + i).show();
    //     }
    // }
    // var isOdd = true;
    // var cnt = 1;
    // tableElement.find('tr:visible').filter(function () {
    //     if (cnt > 2) {
    //         cnt = 1;
    //         isOdd = !isOdd;
    //     }
    //     cnt++;
    //     return isOdd;
    // }).addClass('bgcolor-08');
    return tablecontent;
  }

  //Case 1.2: isHKStock === false && isSP === false && is_compressed === false
  case1_2() {
    const midpoint = this.findMidPoint(false, false);
    let livematrixindex = 0;
    let livematrixlimit = 9;
    if (this.isSP) {
      livematrixlimit = 14;
    }
    livematrixindex = midpoint.index;
    if (livematrixindex === 0) {
      livematrixindex = Math.ceil(this.livematrix.length / 2);
    }
    const tablecontent = [];
    for (let i = 0; i < this.livematrix.length; i++) {
      if (
        i > livematrixindex - livematrixlimit &&
        i < livematrixindex + livematrixlimit
      ) {
        let livematrixdata = this.livematrix[i];
        let isMidPoint =
          livematrixdata.underlying_bid.toFixed(3) === midpoint.price.toFixed(3)
            ? true
            : false;
        if (!isMidPoint && midpoint.diff !== 0) {
          isMidPoint =
            livematrixdata.underlying_bid.toFixed(3) ===
              midpoint.price2.toFixed(3)
              ? true
              : false;
        }
        // tablecontent += isMidPoint ? "<tr class='midpoint'><td>" : "<tr><td>";
        // tablecontent += (isPut? livematrixdata.underlying_ask : livematrixdata.underlying_bid ).toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += livematrixdata.bid.toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += livematrixdata.ask.toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += (isPut? livematrixdata.underlying_bid : livematrixdata.underlying_ask).toFixed(3);
        // tablecontent += "</td></tr>";
        let rowClassName = isMidPoint ? "midpoint" : "";
        rowClassName += i % 2 === 0 ? " bgcolor-08" : "";
        const row = (
          <tr key={i} className={rowClassName}>
            <td>
              {(this.isPut
                ? livematrixdata.underlying_ask
                : livematrixdata.underlying_bid
              ).toFixed(3)}
            </td>
            <td>{livematrixdata.bid.toFixed(3)}</td>
            <td>{livematrixdata.ask.toFixed(3)}</td>
            <td>
              {(this.isPut
                ? livematrixdata.underlying_bid
                : livematrixdata.underlying_ask
              ).toFixed(3)}
            </td>
          </tr>
        );
        tablecontent.push(row);
      }
    }
    // tableElement.html(tablecontent);
    // tableElement.find('tr:visible').filter(':even').addClass('bgcolor-08');
    return tablecontent;
  }

  //Case 2: !(isHKStock === false && isSP === false)
  case2() {
    if (this.isCompressed) {
      return this.case2_1();
    }
    return this.case2_2();
  }

  // Case 2.1: !(isHKStock === false && isSP === false) && is_compressed === true
  case2_1_show(ubidask, midpoint) {
    let hrow, trow;
    let scount = 1,
      lcount = 1;
    for (let i = 0; i < this.livematrix.length; i++) {
      hrow = this.livematrix[i];
      trow = this.livematrix[i + 1];
      let hrowUbidask = null;
      if (ubidask in hrow) {
        hrowUbidask = Number(hrow[ubidask]);
      }
      let midpointPrice = Number(midpoint.price);
      if (
        hrowUbidask !== null &&
        !isNaN(hrowUbidask) &&
        !isNaN(midpointPrice)
      ) {
        if (hrowUbidask < midpointPrice) {
          scount++;
        } else if (hrowUbidask > midpointPrice) {
          lcount++;
        }
      }
      i++;
    }
    const sShow = new Array(scount).fill(false);
    const lShow = new Array(lcount).fill(false);
    // if ("BID" in this.ricdata && Number(this.ricdata.BID) === 0.04) {
    //   for (let i = lcount; i >= lcount - 8; i--) {
    //     lShow[i] = true;
    //   }
    // } else {
    if (this.livematrix[0].bid < this.livematrix[this.livematrix.length - 1].bid) {
      for (let i = 0; i <= 8; i++) {
        lShow[i] = true;
      }
      // i=0
      for (let i = scount; i >= scount - 8; i--) {
        sShow[i] = true;
      }
    } else {
      for (let i = lcount; i >= lcount - 8; i--) {
        lShow[i] = true;
      }
      for (let i = 1; i <= 8; i++) {
        sShow[i] = true;
      }
    }
    // }
    return { sShow, lShow };
  }

  case2_1() {
    const ubidask = "bid";
    const midpoint = this.findBIDMidPoint(true, false);
    let hrow = null;
    let trow = null;
    let scount = 1,
      lcount = 1,
      showcount = -1;
    const rowShow = this.case2_1_show(ubidask, midpoint);
    const tablecontent = [];
    for (let i = 0; i < this.livematrix.length - 1; i++) {
      hrow = this.livematrix[i];
      trow = this.livematrix[i + 1];
      let hrowUbidask = null;
      if (ubidask in hrow) {
        hrowUbidask = Number(hrow[ubidask]);
      }
      const midpointPrice = Number(midpoint.price);
      if (
        hrowUbidask !== null &&
        !isNaN(hrowUbidask) &&
        !isNaN(midpointPrice)
      ) {
        if (hrow.head_tail_id === trow.head_tail_id) {
          let rowClassName = "";
          let isShow = false;
          // if (hrow.head_tail_id == 24) {
          //   console.log(hrowUbidask, midpointPrice)
          // }
          if (hrowUbidask === midpointPrice) {
            rowClassName += "midpoint";
            isShow = true;
          } else if (hrowUbidask < midpointPrice) {
            rowClassName += "srow srow" + scount;
            if (rowShow.sShow.length > scount) {
              isShow = rowShow.sShow[scount];
            }
            scount++;
          } else if (hrowUbidask > midpointPrice) {
            rowClassName += "lrow lrow" + lcount;
            if (rowShow.lShow.length > lcount) {
              isShow = rowShow.lShow[lcount];
            }
            lcount++;
          }

          // tablecontent += "<tr class='" + rowClassName + "'><td>";
          // tablecontent += Number(isPut? hrow.underlying_ask : hrow.underlying_bid).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(hrow.bid).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(hrow.ask).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(isPut? hrow.underlying_bid : hrow.underlying_ask).toFixed(3);
          // tablecontent += "</td></tr>";
          let showStyle = { display: "none" };
          if (isShow) {
            showcount += 1;
            showStyle = {};
            if (showcount % 2 === 0) {
              rowClassName += " bgcolor-08";
            }
          }
          const hRow = (
            <tr key={i} className={rowClassName} style={showStyle}>
              <td>
                {Number(
                  this.isPut ? hrow.underlying_ask : hrow.underlying_bid
                ).toFixed(3)}
              </td>
              <td>{Number(hrow.bid).toFixed(3)}</td>
              <td>{Number(hrow.ask).toFixed(3)}</td>
              <td>
                {Number(
                  this.isPut ? hrow.underlying_bid : hrow.underlying_ask
                ).toFixed(3)}
              </td>
            </tr>
          );
          tablecontent.push(hRow);

          // tablecontent += "<tr class='" + rowClassName + "'><td>";
          // tablecontent += Number(isPut? trow.underlying_ask : trow.underlying_bid).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(trow.bid).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(trow.ask).toFixed(3);
          // tablecontent += "</td><td>";
          // tablecontent += Number(isPut? trow.underlying_bid : trow.underlying_ask).toFixed(3);
          // tablecontent += "</td></tr>";
          const tRow = (
            <tr key={i + 1} className={rowClassName} style={showStyle}>
              <td>
                {Number(
                  this.isPut ? trow.underlying_ask : trow.underlying_bid
                ).toFixed(3)}
              </td>
              <td>{Number(trow.bid).toFixed(3)}</td>
              <td>{Number(trow.ask).toFixed(3)}</td>
              <td>
                {Number(
                  this.isPut ? trow.underlying_bid : trow.underlying_ask
                ).toFixed(3)}
              </td>
            </tr>
          );
          tablecontent.push(tRow);
          i++;
        }
      }
    }
    // tableElement.html(tablecontent);
    // if (ric_data.BID == 0.04) {
    //     tableElement.find(".srow").hide();
    //     tableElement.find(".lrow").hide();
    //     for (var i = lcount; i >= lcount - 8; i--) {
    //         tableElement.find(".lrow" + i).show();
    //     }
    // } else {
    //     tableElement.find(".srow").hide();
    //     tableElement.find(".lrow").hide();
    //     for (var i = lcount; i >= lcount - 8; i--) {
    //         tableElement.find(".lrow" + i).show();
    //     }
    //     for (var i = 1; i <= 8; i++) {
    //         tableElement.find(".srow" + i).show();
    //     }
    // }
    // var isOdd = true;
    // var cnt = 1;
    // tableElement.find('tr:visible').filter(function () {
    //     if (cnt > 2) {
    //         cnt = 1;
    //         isOdd = !isOdd;
    //     }
    //     cnt++;
    //     return isOdd;
    // }).addClass('bgcolor-08');
    return tablecontent;
  }

  // Case 2.2: !(isHKStock === false && isSP === false) && is_compressed === false
  case2_2() {
    const midpoint = this.findBIDMidPoint(false, false);
    let tick = 0.001;
    const midprice = Number(midpoint.price);
    if (midprice >= 0.2) tick = 0.005;
    var minvalue = Number((midprice - 5 * tick).toFixed(4));
    var macvalue = Number((midprice + 5 * tick).toFixed(4));
    // var ticklimit = 10;
    // var livematrixindex = 0;
    // var livematrixlimit = 14;
    // livematrixindex = midpoint.index;
    // if (livematrixindex === 0) {
    //     livematrixindex = Math.ceil(this.livematrix.length / 2);
    // }
    const tablecontent = [];
    for (var i = 0; i < this.livematrix.length; i++) {
      const livematrixdata = this.livematrix[i];
      const lmdatabid = Number(livematrixdata.bid);
      if (lmdatabid >= minvalue && lmdatabid <= macvalue) {
        let isMidPoint =
          livematrixdata.bid.toFixed(3) === midpoint.price.toFixed(3)
            ? true
            : false;
        if (!isMidPoint && midpoint.diff !== 0) {
          isMidPoint =
            livematrixdata.bid.toFixed(3) === midpoint.price2.toFixed(3)
              ? true
              : false;
        }
        // tablecontent += "<tr><td>";
        // tablecontent += (isPut? livematrixdata.underlying_ask : livematrixdata.underlying_bid ).toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += livematrixdata.bid.toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += livematrixdata.ask.toFixed(3);
        // tablecontent += "</td><td>"
        // tablecontent += (isPut? livematrixdata.underlying_bid : livematrixdata.underlying_ask).toFixed(3);
        // tablecontent += "</td></tr>";
        const rowClassName = i % 2 === 0 ? " bgcolor-08" : "";
        const row = (
          <tr key={i} className={rowClassName}>
            <td>
              {(this.isPut
                ? livematrixdata.underlying_ask
                : livematrixdata.underlying_bid
              ).toFixed(3)}
            </td>
            <td>{livematrixdata.bid.toFixed(3)}</td>
            <td>{livematrixdata.ask.toFixed(3)}</td>
            <td>
              {(this.isPut
                ? livematrixdata.underlying_bid
                : livematrixdata.underlying_ask
              ).toFixed(3)}
            </td>
          </tr>
        );
        tablecontent.push(row);
      }
    }
    // tableElement.html(tablecontent);
    // tableElement.find('tr:visible').filter(':even').addClass('bgcolor-08');
    return tablecontent;
  }
}

class Warrant extends Component {
  onChange(selected) {
    if (this.props.isHome) {
      this.props.onWarrantChange(selected);
    } else {
      window.location.href = '/tools/livematrix/' + selected.label.slice(selected.label.indexOf('(') + 1, selected.label.indexOf(')'))
    }
  }
  getDisplayName() {
    const symbols = [];
    if (this.props.symbols !== null && this.props.symbols.length > 0) {
      this.props.symbols.forEach((element) => {
        if (
          "ric" in element &&
          "dsply_nmll" in element &&
          "security_code" in element
        ) {
          const item = {
            label: element.dsply_nmll + " (" + element.security_code + ")",
            value: element.ric,
            future_dsply_name: element.future_dsply_name
          };
          symbols.push(item);
        }
      });
    }
    return symbols;
  }

  render() {
    return (
      <div id="dropdown-warrant">
        <div>
          <Select
            className="center"
            placeholder="Select warrant"
            options={this.getDisplayName()}
            onChange={this.onChange.bind(this)}
            isDisabled={this.props.disabled}
            styles={{
              option: (provided, state) => ({
                ...provided,
                textAlign: "center",
                ':hover': {
                  backgroundColor: '#1A73E8',
                }
              }),
              valueContainer: (provided) => ({
                ...provided,
                justifyContent: 'center'
              })
            }}
          />
        </div>
      </div>
    );
  }
}

class TextDescrtion extends Component {
  render() {
    return (
      <div>
        <div id="lmatrix_tbl_f" className="hidden-sm hidden-xs">
          <ol>
            <li>
              The warrant prices in the live matrix are indicative. The actual
              price of the warrants in the market may be different from the
              prices displayed on the website due to factors such as 1) the
              liquidity and the volatility of the warrant and the underlying as
              well as the relevant market conditions 2) the buying and the
              selling that is driven by the supply and the demand in the market
              3) the delay in displaying the price 4) the various rights of the
              underlying and other factors that are beyond the control of
              derivative warrant’s issuer, the market maker, the relevant
              parties or the unexpected situations. Macquarie reserves the
              rights to consider the terms and the conditions on buying back or
              selling warrants on case-by-case basis, at its sole discretion.
            </li>
            <li>
              There may be occasional delays in the data feed and any
              information in the live matrix. Only continuous trading session
              prices are shown.
            </li>
            <li>
              The warrant price are provided for your personal use and is
              intended for information purpose only and does not constitute an
              offer, a solicitation, an advice, a recommendation or an
              indication to buy or sell the underlying securities or warrant.
              Macquarie shall not be liable for the accuracy or completeness of
              any information or for any loss or damage however caused arising
              in connection with any information, including without limitation
              any direct, indirect, special or consequential loss and loss of
              profits whether in part or in full.
            </li>
          </ol>
          <hr />
          <p className="small">
            All market data is delayed 15 mins unless otherwise indicated by
            timestamp.
          </p>
        </div>

        <div
          className="panel-group visible-xs-block visible-sm-block"
          id="defination"
        >
          <div className="panel panel-default">
            <div className="panel-heading">
              <a
                data-toggle="collapse"
                data-parent="#defination"
                href="#collapse_dwcompare3"
              >
                <span className="glyphicon glyphicon glyphicon-question-sign pull-right"></span>
                <div
                  id="collapse_title3"
                  className="panel-title text-center color-primary"
                >
                  Definition &amp; disclaimer
                </div>
              </a>
            </div>
            <div id="collapse_dwcompare3" className="panel-collapse collapse">
              <div id="lmatrix_tbl_f">
                <ol>
                  <li>
                    The warrant prices in the live matrix are indicative. The
                    actual price of the warrants in the market may be different
                    from the prices displayed on the website due to factors such
                    as 1) the liquidity and the volatility of the warrant and
                    the underlying as well as the relevant market conditions 2)
                    the buying and the selling that is driven by the supply and
                    the demand in the market 3) the delay in displaying the
                    price 4) the various rights of the underlying and other
                    factors that are beyond the control of derivative warrant’s
                    issuer, the market maker, the relevant parties or the
                    unexpected situations. Macquarie reserves the rights to
                    consider the terms and the conditions on buying back or
                    selling warrants on case-by-case basis, at its sole
                    discretion.
                  </li>
                  <li>
                    There may be occasional delays in the data feed and any
                    information in the live matrix. Only continuous trading
                    session prices are shown.
                  </li>
                  <li>
                    The warrant price are provided for your personal use and is
                    intended for information purpose only and does not
                    constitute an offer, a solicitation, an advice, a
                    recommendation or an indication to buy or sell the
                    underlying securities or warrant. Macquarie shall not be
                    liable for the accuracy or completeness of any information
                    or for any loss or damage however caused arising in
                    connection with any information, including without
                    limitation any direct, indirect, special or consequential
                    loss and loss of profits whether in part or in full.
                  </li>
                </ol>
                <hr />
                <p className="small">
                  All market data is delayed 15 mins unless otherwise indicated
                  by timestamp.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


class TermsChart extends Component {
  constructor(props) {
    super(props);
    // this.chart = React.createRef();
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      minDate: new Date(),
      MaxDate: new Date(),
      chartData: null,
      warrantPrice: '-',
      Underlying: '-',
      day: '-',
      last: '-',
      last1: '-',
      from: '-',
      from1: '-',
      fixedStartDate: '-',
      fixedEndDate: '-',
      switch: false,
      lastRic: true
    };
  }
  onPeriodChange(period) {
    this.props.onPeriodChange(period);
  }
  componentWillReceiveProps(props) {
    if (props.chartdata) {
      if (props.chartdata == this.props.chartdata) {
        return false;
      }
      if (props.chartdata.data.length == 0 && props.chartdata.data1.length == 0) {
        return false;
      }
      this.chart.current.chartRef.current.chart.xAxis[0].update({ min: null, max: null })

      var arrMin = props.chartdata.data[0].time.replace(' 00:00', '');
      var arrMax = props.chartdata.data[props.chartdata.data.length - 1].time.replace(' 00:00', '');
      var arrMin2 = props.chartdata.data1.length > 0 ? props.chartdata.data1[0].time.replace(' 00:00', '') : '';
      var arrMax2 = props.chartdata.data1.length > 0 ? props.chartdata.data1[props.chartdata.data1.length - 1].time.replace(' 00:00', '') : '';
      var min = '';
      var max = '';
      // if (props.period == 'Y') {
      //   min = arrMin2
      //   max = arrMax2
      // } else {
      // if (new Date(arrMin) < new Date(arrMin2)) {
      //   min = arrMin
      // } else {
      //   min = arrMin2
      // }
      if (props.chartdata.data1.length < 1) {
        min = arrMin
      } else {
        min = arrMin2
      }

      if (new Date(arrMax) < new Date(arrMax2)) {
        max = arrMax2
      } else {
        max = arrMax
      }
      // }
      var date = null;
      var currentDate = new Date(max);
      // if (props.period == 'Y') {
      //   date = new Date(currentDate.setDate(currentDate.getDate() - 7));
      // } else {
      //   date = new Date(min)
      // }
      var lastRic = JSON.parse(sessionStorage.getItem('lastRic'))
      if (this.state.switch) {
        var parameterSet = {
          chartData: props.chartdata,
          startDate: this.state.startDate,
          // endDate: new Date(max),
          endDate: this.state.endDate,
          switch: false
        }
      } else {
        if (lastRic && this.state.lastRic && lastRic.ric == this.props.selected.value) {
          var parameterSet = {
            chartData: props.chartdata,
            startDate: new Date(lastRic.startDate),
            endDate: new Date(lastRic.endDate),
            lastRic: false
          }
        } else {
          var parameterSet = {
            chartData: props.chartdata,
            startDate: new Date(min),
            endDate: new Date(max),
            lastRic: false
          }
        }

      }
      if (this.state.fixedStartDate == '-' && this.state.fixedEndDate == '-') {
        date = {
          minDate: new Date(min),
          MaxDate: new Date(max),
          fixedStartDate: new Date(min),
          fixedEndDate: new Date(max),
        }
        if (lastRic && lastRic.ric != this.props.selected.value || lastRic == null) {
          window.setTimeout(() => {
            this.onPeriodChange('W')
          }, 300)
        }
      } else {
        date = {}
      }
      this.setState(Object.assign(parameterSet, date), () => {
        this.changeDate(props.chartdata)
      })
      // this.setState({
      //   chartData: props.chartdata,
      //   minDate: new Date(min),
      //   MaxDate: new Date(max),
      //   startDate: new Date(min),
      //   // startDate: date,
      //   endDate: new Date(max),
      // }, () => {
      //   this.changeDate(props.chartdata)
      // })
    }
  }
  changeDate(chartdata) {
    if (this.props.period != "D") {
      var dataArr = this.chart.current.chartRef.current.chart.series[0].xData;
      var dataArr2 = this.chart.current.chartRef.current.chart.series[1].xData;
      var underlyingMax = undefined;
      var underlyingMin = undefined;
      var warrantMax = undefined;
      var warrantMin = undefined;
      if (dataArr.indexOf(this.state.endDate.getTime()) != -1) {
        underlyingMax = this.chart.current.chartRef.current.chart.series[0].yData[dataArr.indexOf(this.state.endDate.getTime())]
      }
      if (dataArr.indexOf(this.state.startDate.getTime()) != -1) {
        underlyingMin = this.chart.current.chartRef.current.chart.series[0].yData[dataArr.indexOf(this.state.startDate.getTime())]
      }
      if (dataArr2.indexOf(this.state.endDate.getTime()) != -1) {
        warrantMax = this.chart.current.chartRef.current.chart.series[1].yData[dataArr2.indexOf(this.state.endDate.getTime())]
      }
      if (dataArr2.indexOf(this.state.startDate.getTime()) != -1) {
        warrantMin = this.chart.current.chartRef.current.chart.series[1].yData[dataArr2.indexOf(this.state.startDate.getTime())]
      }
      var str = ''
      var str1 = ''
      if (((underlyingMax - underlyingMin) / underlyingMin).toFixed(2) * 100 == Infinity || isNaN(((underlyingMax - underlyingMin) / underlyingMin).toFixed(2) * 100)) {
        str = '-';
      } else {
        str = this.floatMultiply(((underlyingMax - underlyingMin) / underlyingMin).toFixed(3), 100) + '%';
      }
      if (((warrantMax - warrantMin) / warrantMin).toFixed(2) * 100 == Infinity || isNaN(((warrantMax - warrantMin) / warrantMin).toFixed(2) * 100)) {
        str1 = '-';
      } else {
        str1 = this.floatMultiply(((warrantMax - warrantMin) / warrantMin).toFixed(3), 100) + '%';
      }
      var day = (this.state.endDate.getTime() - this.state.startDate.getTime()) / (1000 * 60 * 60 * 24)
      // if (day == 0 || day > 0 && day < 1) {
      //   day = 1
      // }
      if (day < 1) {
        day = 1
      }
      this.setState({
        warrantPrice: str1,
        Underlying: str,
        last: warrantMin ? warrantMin : '-',
        last1: underlyingMin ? underlyingMin : '-',
        from: warrantMax ? warrantMax : '-',
        from1: underlyingMax ? underlyingMax : '-',
        day: Math.ceil(day)
      })
      this.chart.current.chartRef.current.chart.xAxis[0].update({ max: this.state.endDate.getTime(), min: this.state.startDate.getTime() })
    } else {
      var underlyingMax = undefined;
      var underlyingMin = undefined;
      var warrantMax = undefined;
      var warrantMin = undefined;
      underlyingMax = chartdata.data[0].close;
      underlyingMin = chartdata.data[chartdata.data.length - 1].close;
      warrantMax = chartdata.data1[0].close;
      warrantMin = chartdata.data1[chartdata.data1.length - 1].close;
      var str = ''
      var str1 = ''
      if (((underlyingMin - underlyingMax) / underlyingMax).toFixed(2) * 100 == Infinity || isNaN(((underlyingMin - underlyingMax) / underlyingMax).toFixed(2) * 100)) {
        str = '-';
      } else {
        str = this.floatMultiply(((underlyingMin - underlyingMax) / underlyingMax).toFixed(3), 100) + '%';
      }
      if (((warrantMin - warrantMax) / warrantMax).toFixed(2) * 100 == Infinity || isNaN(((warrantMin - warrantMax) / warrantMax).toFixed(2) * 100)) {
        str1 = '-';
      } else {
        str1 = this.floatMultiply(((warrantMin - warrantMax) / warrantMax).toFixed(3), 100) + '%';
      }
      var day = (this.state.endDate.getTime() - this.state.startDate.getTime()) / (1000 * 60 * 60 * 24)
      this.setState({
        warrantPrice: str1,
        Underlying: str,
        // last: warrantMin ? warrantMin : '-',
        // last1: underlyingMin ? underlyingMin : '-',
        // from: warrantMax ? warrantMax : '-',
        // from1: underlyingMax ? underlyingMax : '-',
        from: warrantMin ? warrantMin : '-',
        from1: underlyingMin ? underlyingMin : '-',
        last: warrantMax ? warrantMax : '-',
        last1: underlyingMax ? underlyingMax : '-',
        day: 1
      })
      // this.chart.current.chartRef.current.chart.xAxis[0].update({ max: this.state.endDate.getTime(), min: this.state.startDate.getTime() })
    }
  }
  componentDidMount() {
    this.chart = React.createRef();
    // this.changeDate(this.props.chartdata)
  }
  setStartDate(date) {
    if (date != null) {
      if (this.state.startDate.getTime() == date.getTime()) {
        return false;
      }
      var endDate = '';
      var boolean = false;
      if (this.props.period == "D") {
        endDate = this.state.MaxDate
        var time = new Date(date + '')
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var startDate = new Date(y + '-' + m + '-' + d + ' ' + '08:00:00')
      } else {
        endDate = this.state.endDate
        var startDate = date
      }
      if (this.props.period != "Y") {
        this.onPeriodChange('Y')
        boolean = true
      }
      this.setState({
        startDate: startDate,
        switch: boolean,
        endDate: endDate
      }, () => {
        sessionStorage.setItem('lastRic', JSON.stringify({ ric: this.props.selected.value, startDate: this.state.startDate, endDate: this.state.endDate }))
        this.changeDate(this.props.chartdata)

      })
    }
  }
  floatMultiply(arg1, arg2) {
    if (arg1 == null || arg2 == null) {
      return null;
    }
    var n1, n2;
    var r1, r2; // 小数位数
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    n1 = Number(arg1.toString().replace(".", ""));
    n2 = Number(arg2.toString().replace(".", ""));
    return n1 * n2 / Math.pow(10, r1 + r2);
  }
  setEndDate(date) {
    if (date != null) {
      var boolean = false;
      if (this.props.period != "Y") {
        this.onPeriodChange('Y')
        boolean = true
      }
      this.setState({
        endDate: date,
        switch: boolean
      }, () => {
        sessionStorage.setItem('lastRic', JSON.stringify({ ric: this.props.selected.value, startDate: this.state.startDate, endDate: this.state.endDate }))
        this.changeDate(this.props.chartdata)
      })
    }
  }
  render() {
    return (
      <div className="termschart" style={{ marginTop: '30px' }}>
        <table id="priceTable" style={{ fontSize: '14px' }}>
          <tbody>
            <tr>
              <td colSpan="4" style={{ backgroundColor: '#DFDBCF', lineHeight: '35px' }}>Warrant vs. Underlying Performance</td>
            </tr>
            <tr style={{ background: '#F5F6F7' }}>
              <td rowSpan="2" className="isShowPC">

                From
                &nbsp;
                <DatePicker
                  // selected={this.props.startDate}
                  selected={this.state.startDate}
                  onChange={(date) => this.setStartDate(date)}
                  minDate={this.state.minDate}
                  maxDate={this.state.endDate}
                  selectsStart
                // value=""
                // dayClassName={(date) =>
                //   this.getbgDate(date)
                //   // getDate(date) < Math.random() * 31 ? "random" : undefined
                // }
                />
              </td>
              <td className="isShowMobile">From</td>
              <td>Warrant Price</td>
              <td>Underlying</td>
              <td>Number of days</td>
            </tr>
            <tr style={{ background: '#F5F6F7' }}>
              <td className="isShowMobile">
                <DatePicker
                  // selected={this.props.startDate}
                  selected={this.state.startDate}
                  onChange={(date) => this.setStartDate(date)}
                  minDate={this.state.minDate}
                  maxDate={this.state.endDate}
                  selectsStart
                // value=""
                // dayClassName={(date) =>
                //   this.getbgDate(date)
                //   // getDate(date) < Math.random() * 31 ? "random" : undefined
                // }
                />
              </td>
              <td>{this.state.last}</td>
              <td>{this.state.last1}</td>
              <td></td>
            </tr>
            <tr>
              <td>
                To
                &nbsp;
                &nbsp;
                &nbsp;
                <DatePicker
                  // selected={this.props.startDate}
                  // selected={new Date()}
                  selectsEnd
                  selected={this.state.endDate}
                  onChange={(date) => this.setEndDate(date)}
                  minDate={this.state.startDate}
                  maxDate={this.state.MaxDate}
                // maxDate={new Date(this.props.chartdata.data[0].time)}
                // value=""
                // dayClassName={(date) =>
                //   this.getbgDate(date)
                //   // getDate(date) < Math.random() * 31 ? "random" : undefined
                // }
                />
              </td>
              <td>{this.state.from}</td>
              <td>{this.state.from1}</td>
              <td></td>
            </tr>
            <tr style={{ background: '#F5F6F7' }}>
              <td style={{ width: '36%' }}>
                {/* <DatePicker
                  // selected={this.props.startDate}
                  selected={this.state.startDate}
                  onChange={(date) => this.setStartDate(date)}
                  minDate={this.state.minDate}
                  maxDate={this.state.endDate}
                  selectsStart
                // value=""
                // dayClassName={(date) =>
                //   this.getbgDate(date)
                //   // getDate(date) < Math.random() * 31 ? "random" : undefined
                // }
                /> */}
                {/* &nbsp;to&nbsp; */}
                {/* <DatePicker
                  // selected={this.props.startDate}
                  // selected={new Date()}
                  selectsEnd
                  selected={this.state.endDate}
                  onChange={(date) => this.setEndDate(date)}
                  minDate={this.state.startDate}
                  maxDate={this.state.MaxDate}
                // maxDate={new Date(this.props.chartdata.data[0].time)}
                // value=""
                // dayClassName={(date) =>
                //   this.getbgDate(date)
                //   // getDate(date) < Math.random() * 31 ? "random" : undefined
                // }
                /> */}
              </td>
              <td style={{ color: this.state.warrantPrice.indexOf('-') != -1 ? 'red' : '#6CC263' }}>{this.state.warrantPrice}</td>
              <td style={{ color: this.state.Underlying.indexOf('-') != -1 ? 'red' : '#6CC263' }}>{this.state.Underlying}</td>
              <td style={{ color: 'red' }}>{this.state.day}</td>
            </tr>
          </tbody>
        </table>
        <table id="periodtab" className="ctab">
          <tbody>
            <tr>
              <td
                className={classNames(
                  { active: this.props.period === "D" },
                  "ctab-item"
                )}
                onClick={() => this.onPeriodChange("D")}
              >
                1D
              </td>
              <td className="ctab-item-space">&nbsp;</td>
              <td
                className={classNames(
                  { active: this.props.period === "W" },
                  "ctab-item"
                )}
                onClick={() => this.onPeriodChange("W")}
              >
                1W
              </td>
              <td className="ctab-item-space">&nbsp;</td>
              <td
                className={classNames(
                  { active: this.props.period === "M" },
                  "ctab-item"
                )}
                onClick={() => this.onPeriodChange("M")}
              >
                1M
              </td>
              <td className="ctab-item-space">&nbsp;</td>
              <td
                className={classNames(
                  { active: this.props.period === "Y" },
                  "ctab-item"
                )}
                onClick={() => this.onPeriodChange("Y")}
              >
                MAX
              </td>
            </tr>
          </tbody>
        </table>
        <div className="chart-legend">
          <div className="row hidden-xs">
            <div className="chart-legend-left col-sm-6">
              <div className="chart-legend-item">
                Warrant bid price (SGD)
                <span className="chart-legend-bar warrant"></span>
              </div>
            </div>
            <div className="chart-legend-right col-sm-6">
              <div className="chart-legend-item">
                Underlying price
                {/* (<span className="underlying_curr">HKD</span>) */}
                <span className="chart-legend-bar underlying"></span>
              </div>
            </div>
          </div>
          <div className="row visible-xs">
            <table className="chart-legend-tbl">
              <tbody>
                <tr>
                  <td>
                    Warrant bid price (<span className="SGD"></span>)
                  </td>
                  <td>
                    <span className="chart-legend-bar warrant"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Underlying price (
                    <span className="underlying_curr">HKD</span>)
                  </td>
                  <td>
                    <span className="chart-legend-bar underlying"></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="chartbox" className="chartbox">
          <Chart
            // ric={this.props.selected.underlyingric}
            // underlyingcurr={this.props.selected.underlyingcurr}
            period={this.props.period}
            data={this.props.chartdata}
            ref={this.chart}
          />
        </div>
      </div>
    );
  }
}
class Chart extends Component {
  getData(data) {
    var chartData = {
      data1: [],
      data2: [],
      data1Axis: {
        min: null,
        max: null,
      },
      data2Axis: {
        min: null,
        max: null,
      },
    };
    if (data) {
      var arr1 = [];
      var arr2 = [];
      for (var i = 0; i < data.data.length; i++) {
        // chartData.data1.push({
        //   x: new Date(data.data[i]["time"]).getTime(),
        //   y: Number(data.data[i]["close"]),
        // });
        chartData.data1.push([
          this.props.period === "D"
            ? new Date(data.data[i]["time"].replace(' 00:00', '')).getTime() + 28800000
            : new Date(data.data[i]["time"].replace(' 00:00', '')).getTime(),
          Number(data.data[i]["close"]),
        ]);
        arr1.push(Number(data.data[i]["close"]));
      }
      for (var j = 0; j < data.data1.length; j++) {
        // chartData.data2.push({
        //   x: new Date(data.data1[j]["time"]).getTime(),
        //   y: Number(data.data1[j]["close"]),
        // });
        chartData.data2.push([
          this.props.period === "D"
            ? new Date(data.data1[j]["time"].replace(' 00:00', '')).getTime() + 28800000
            : new Date(data.data1[j]["time"].replace(' 00:00', '')).getTime(),
          Number(data.data1[j]["close"]),
        ]);
        arr2.push(Number(data.data1[j]["close"]));
      }
      chartData.data1Axis.min = Math.min.apply(Math, arr1);
      chartData.data1Axis.max = Math.max.apply(Math, arr1);
      chartData.data2Axis.min = Math.min.apply(Math, arr2);
      chartData.data2Axis.max = Math.max.apply(Math, arr2);
      return chartData;
    } else {
      return chartData;
    }
  }
  floatMultiply(arg1, arg2) {
    if (arg1 == null || arg2 == null) {
      return null;
    }
    var n1, n2;
    var r1, r2; // 小数位数
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    n1 = Number(arg1.toString().replace(".", ""));
    n2 = Number(arg2.toString().replace(".", ""));
    return n1 * n2 / Math.pow(10, r1 + r2);
  }
  componentDidMount() {
    this.chartRef = React.createRef();
  }
  render() {
    var chartdata = this.getData(this.props.data);
    var that = this;
    const chartOption = {
      lang: {
        noData: "no-data-to-display",
      },
      chart: {
        type: "area",
        // zoomType: "x",
        // events: {
        //   selection: function (event) {
        //     // var std=event.xAxis[0].min+','+event.xAxis[0].max
        //     // var pgpd3=event.yAxis[0].min+','+event.yAxis[0].max
        //     // this.$emit("sendData", std,pgpd3);
        //     if (event.xAxis == undefined) {
        //       this.title.update({ text: '' });
        //       return;
        //     }
        //     var min = null;
        //     var max = null;
        //     var min1 = null;
        //     var max2 = null;
        //     var mum1 = event.xAxis[0].min + "";
        //     var mum2 = event.xAxis[0].max + "";
        //     if (mum1.indexOf('.') != -1) {
        //       mum1 = mum1.slice(0, mum1.indexOf('.'))
        //     }
        //     if (mum2.indexOf('.') != -1) {
        //       mum2 = mum2.slice(0, mum2.indexOf('.'))
        //     }
        //     mum1 = Number(mum1)
        //     mum2 = Number(mum2)
        //     // for (var i = 0; i < chartdata.data1.length; i++) {
        //     //   if (new Date(chartdata.data1[i][0])
        //     //   ) {
        //     //     min = chartdata.data1[i][1]
        //     //   }
        //     if (that.props.period === "D") {
        //       for (var i = 0; i < chartdata.data1.length; i++) {
        //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum1).getFullYear() &&
        //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 &&
        //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum1).getDate() &&
        //           new Date(chartdata.data1[i][0]).getHours() == new Date(mum1).getHours() &&
        //           new Date(chartdata.data1[i][0]).getMinutes() == new Date(mum1).getMinutes()
        //         ) {
        //           min = chartdata.data1[i][1]
        //         }
        //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum2).getFullYear() &&
        //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 &&
        //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum2).getDate() &&
        //           new Date(chartdata.data1[i][0]).getHours() == new Date(mum2).getHours() &&
        //           new Date(chartdata.data1[i][0]).getMinutes() == new Date(mum2).getMinutes()

        //         ) {
        //           max = chartdata.data1[i][1]
        //         }
        //       }
        //       for (var i = 0; i < chartdata.data2.length; i++) {
        //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum1).getFullYear() &&
        //           new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 &&
        //           new Date(chartdata.data2[i][0]).getDate() == new Date(mum1).getDate() &&
        //           new Date(chartdata.data2[i][0]).getHours() == new Date(mum1).getHours() &&
        //           new Date(chartdata.data2[i][0]).getMinutes() == new Date(mum1).getMinutes()

        //         ) {
        //           min1 = chartdata.data2[i][1]
        //         }
        //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum2).getFullYear() &&
        //           new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 &&
        //           new Date(chartdata.data2[i][0]).getDate() == new Date(mum2).getDate() &&
        //           new Date(chartdata.data2[i][0]).getHours() == new Date(mum2).getHours() &&
        //           new Date(chartdata.data2[i][0]).getMinutes() == new Date(mum2).getMinutes()

        //         ) {
        //           max2 = chartdata.data2[i][1]
        //         }
        //       }
        //     } else {
        //       for (var i = 0; i < chartdata.data1.length; i++) {
        //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum1).getFullYear() &&
        //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 &&
        //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum1).getDate()
        //         ) {
        //           min = chartdata.data1[i][1]
        //         }
        //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum2).getFullYear() &&
        //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 &&
        //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum2).getDate()

        //         ) {
        //           max = chartdata.data1[i][1]
        //         }
        //       }
        //       for (var i = 0; i < chartdata.data2.length; i++) {
        //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum1).getFullYear() && new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 && new Date(chartdata.data2[i][0]).getDate() == new Date(mum1).getDate()) {
        //           min1 = chartdata.data2[i][1]
        //         }
        //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum2).getFullYear() && new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 && new Date(chartdata.data2[i][0]).getDate() == new Date(mum2).getDate()) {
        //           max2 = chartdata.data2[i][1]
        //         }
        //       }
        //     }
        //     var str = '';
        //     var str1 = '';
        //     if (((max - min) / min).toFixed(2) * 100 == Infinity || isNaN(((max - min) / min).toFixed(2) * 100)) {
        //       str = '';
        //     } else {
        //       str = 'Underlying Percentage Change: ' + that.floatMultiply(((max - min) / min).toFixed(2), 100) + '%';
        //     }
        //     if (((max2 - min1) / min1).toFixed(2) * 100 == Infinity || isNaN(((max2 - min1) / min1).toFixed(3) * 100)) {
        //       str1 = '';
        //     } else {
        //       str1 = 'Warrant bid percentage change: ' + that.floatMultiply(((max2 - min1) / min1).toFixed(3), 100) + '%';
        //     }
        //     this.title.update({ text: str + '<br/>' + str1 });
        //   },
        // }
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      credits: {
        text: "",
      },
      xAxis: {
        // allowDecimals: false,
        gridLineColor: "#FAFAFA",
        crosshair: true,
        crosshair: {
          width: 1,
          color: 'red'
        },
        gridLineWidth: 1,
        type: "datetime",
        title: {
          text: "",
        },
        labels: {
          // formatter: function () {
          //   return Highcharts.dateFormat('%y/%m', this.value)
          // }
          formatter: function () {
            var date = '';
            if (that.props.period == 'D') {
              date = Highcharts.dateFormat('%H:%M', this.value)
            } else {
              date = Highcharts.dateFormat('%d/%m', this.value)
            }
            return date
          }
        },
        // tickInterval: 7 * 24 * 3600 * 1000,// one day,
        dateTimeLabelFormats: {
          // day: '%d/%m/%y',
          day: '%y/%m',
        }
      },
      yAxis: [
        {
          // crosshair: true,
          opposite: true,
          labels: {
            formatter: function () {
              return this.value;
            },
          },
          title: {
            text: "",
          },
          lineWidth: 1,
          tickWidth: 1,
          gridLineColor: "#FAFAFA",
          min: chartdata.data2Axis.min,
          max: chartdata.data2Axis.max,
        },
        {
          // crosshair: true,
          labels: {
            formatter: function () {
              return this.value;
            },
          },
          title: {
            text: "",
          },
          lineWidth: 1,
          tickWidth: 1,
          gridLineColor: "#FAFAFA",
          min: chartdata.data1Axis.min,
          max: chartdata.data1Axis.max,
        },
      ],
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        split: true,
      },
      plotOptions: {
        area: {
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
        // series: {
        //   pointInterval: 24 * 3600 * 1000 * 60// one day
        // }
      },
      series: [
        {
          name: "Underlying price",
          yAxis: 1,
          data: chartdata.data1,
          tooltip: {
            pointFormat: "Underlying price : <b>{point.y}</b>",
            split: true,
          },
        },
        {
          name: "Warrant bid price (SGD)",
          data: chartdata.data2,
          color: "#DFC49F",
          tooltip: {
            pointFormat: "Warrant price (SGD) : <b>{point.y}</b>",
            split: true,
          },
        },
      ],
    };
    const style = {
      width: "100%",
      height: "370px",
    };
    return (
      <HighchartsReact
        highcharts={highcharts}
        options={chartOption}
        style={style}
        ref={this.chartRef}
      />
    );
    // return <div style={style} ref="charts"></div>;
  }
}

class IndtableShow extends Component {
  getData(fieldName, dp = null) {
    if (
      this.props.warrantdata !== null &&
      fieldName in this.props.warrantdata
    ) {
      const value = this.props.warrantdata[fieldName];
      if (dp !== null) {
        return Number(value).toFixed(dp);
      }
      return value;
    }
    return "";
  }

  getTheta() {
    // "last_trading_date": "23 Feb 21"
    const lasttradingdate = this.getData("last_trading_date");
    const currDate = new Date();
    const currDateMS = currDate.getTime();
    if (lasttradingdate.length > 0) {
      const lasttradingdateMS = Date.parse(lasttradingdate);
      const diff = currDateMS - lasttradingdateMS;
      // check if expired
      if (diff <= 24 * 60 * 60 * 1000) {
        return this.getData("theta", 4);
      }
    }
    return "-";
  }

  getMoneynessClass(nc) {
    let cnm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        cnm = "val mny up";
      } else if (value === 2) {
        cnm = "val mny down";
      }
    }
    return cnm;
  }

  getMoneynessName(nc) {
    let nm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        return "ITM";
      } else if (value === 2) {
        return "OTM";
      } else {
        return "ATM";
      }
    }
    return nm;
  }

  render() {
    const hstclose = this.getData("HST_CLOSE");
    const trdprc1 = this.getData("TRDPRC_1");
    const openprc = this.getData("OPEN_PRC");
    const acvol1scaled = this.getData("ACVOL_1_SCALED");
    const high1 = this.getData("HIGH_1");
    const turnoverscaled = this.getData("TURNOVER_SCALED");
    const low1 = this.getData("LOW_1");
    const underlyingprice = this.getData("underlying_price");
    const ticker = this.getData("ticker");
    const effectivegearing = this.getData("effective_gearing");
    // const underlyingticker = this.getData("underlying_ticker");
    const underlyingticker =
      MQSGUtil.getUnderlyingTickerDisplayName(this.getData("underlying_ticker"));
    const delta = this.getData("delta");
    const type = this.getData("type");
    const deltaperwrnt = this.getData("delta_per_wrnt");
    const issuername = this.getData("issuer_name");
    const sensitivity = this.getData("sensitivity");
    // const exerciseprice = this.getData("exercise_price");
    const breakevenprice = this.getData("breakeven_price");
    const tradedate = this.getData("TRADE_DATE");
    const premium = this.getData("premium");
    const maturity = this.getData("maturity");
    const impliedvolatility = this.getData("implied_volatility");
    const lasttradingdate = this.getData("last_trading_date");
    const theta = this.getTheta();
    const daystomaturity = this.getData("days_to_maturity") + " days";
    const intrinsicvalue = this.getData("intrinsic_value");
    var convratio = this.getData("conv_ratio");
    var exerciseprice = this.getData("exercise_price");
    if (this.props.warrantdata && this.props.warrantdata.dsply_name.indexOf('Tencent') != -1) {
      convratio = this.getData("conv_ratio_cal").toFixed(3);
      exerciseprice = this.getData("exercise_price_cal").toFixed(3);
    } else if (
      this.props.warrantdata &&
      (this.props.warrantdata.dsply_name.indexOf('SembInd') != -1 ||
        this.props.warrantdata.dsply_name.indexOf('CityDev') != -1 ||
        this.props.warrantdata.dsply_name.indexOf('KepCorp') != -1 ||
        this.props.warrantdata.dsply_name.indexOf('Singtel') != -1
      )
    ) {
      // convratio = this.getData("conv_ratio_cal").toFixed(5);
      convratio = Math.floor(this.getData("conv_ratio_cal") * 100) / 100;
    }
    const moneyness = this.getData("moneyness");
    const moneynessClass = this.getMoneynessClass(moneyness);
    const moneynessdisplaystring =
      this.getData("percent_moneyness") +
      "% " +
      this.getMoneynessName(moneyness);

    return (
      <div>
        <div id="srtable" style={{ position: "relative" }}>
          <table className="table priorTable">
            <tbody>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Prior
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HST_CLOSE"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {hstclose}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Last trade
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TRDPRC_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {trdprc1}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ border: 0, textAlign: 'left' }}>
                  Open
                </th>
                <td
                  className="srtable-leftval val OPEN_PRC"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {openprc}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Traded volume ('000)
                </th>
                <td className="srtable-rightval val ACVOL_1_SCALED" style={{ textAlign: 'left' }}>
                  {acvol1scaled}
                </td>
              </tr>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left', textAlign: 'left' }}
                >
                  High
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HIGH_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {high1}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Traded value ('000 SGD)
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TURNOVER_SCALED"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {turnoverscaled}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ textAlign: 'left' }}>Low</th>
                <td className="srtable-leftval val LOW_1" style={{ border: 0, textAlign: 'left' }}>
                  {low1}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Underlying price
                </th>
                <td
                  className="srtable-rightval val underlying_price"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {underlyingprice}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>Terms</h3>
        <div id="indtable" style={{ position: "relative" }}>
          <table className="table visible-md visible-lg table-closed table-striped indicator_table TermsTable">
            <tbody>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="dwsymbol"
                    data-hasqtip="0"
                    data-tip
                    data-for="Warrant-tooltip"
                  >
                    Warrant code
                  </span>
                  <ReactTooltip
                    id="Warrant-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Warrant code: </b>
                    Each warrant has an unique 4-digit identification code,
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/howarewarrantsnamed"
                    >
                      &nbsp;&nbsp;Click here&nbsp;&nbsp;
                    </a>
                    for a more detailed explanation.
                  </ReactTooltip>
                </th>
                <td className="val ticker">{ticker}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="effectivegearing"
                    data-hasqtip="1"
                    data-tip
                    data-for="Effective-tooltip"
                  >
                    Effective gearing (x)
                  </span>
                  <ReactTooltip
                    id="Effective-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Effective gearing: </b>
                    Indicates your leveraged exposure. An effective gearing of
                    5X tells you a 1% move in the underlying stock/index results
                    in an approximate 5% move in your Warrant price.
                    <a
                      target="_blank"
                      href="https://www.warrants.com.sg/InvestorAcademy/effectivegearing"
                    >
                      &nbsp;&nbsp;Click here&nbsp;&nbsp;
                    </a>
                    to learn more about effective gearing.
                  </ReactTooltip>
                </th>
                <td className="val effective_gearing">{effectivegearing}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="underlying"
                    data-hasqtip="2"
                    data-tip
                    data-for="Underlying-tooltip"
                  >
                    Underlying
                  </span>
                  <ReactTooltip
                    id="Underlying-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Underlying: </b>
                    The stock or index over which the Warrant is listed.
                  </ReactTooltip>
                </th>
                <td className="val underlying_ticker">
                  <a href={"/tools/underlying/" + underlyingticker}>{underlyingticker}</a>
                </td>
                <th>
                  <span
                    className="ttip"
                    data-target="delta"
                    data-hasqtip="3"
                    aria-describedby="qtip-3"
                    data-tip
                    data-for="Delta-tooltip"
                  >
                    Delta (%)
                  </span>
                  <ReactTooltip
                    id="Delta-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Delta: </b>
                    Estimates how a warrant moves in relation to the underlying
                    stock/index. Puts have negative deltas because their prices
                    increase as the underlying stock/index falls. Note, if your
                    warrant has a WPS that this will need to be taken into
                    account.
                  </ReactTooltip>
                </th>
                <td className="val delta">{delta}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="type"
                    data-hasqtip="4"
                    data-tip
                    data-for="Type-tooltip"
                  >
                    Type
                  </span>
                  <ReactTooltip
                    id="Type-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Type: </b>
                    Calls increase in value as share price rises, Puts increase
                    in value as share price falls.
                  </ReactTooltip>
                </th>
                <td className="val type">{type}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="deltaperdw"
                    data-hasqtip="5"
                    data-tip
                    data-for="Deltaper-tooltip"
                  >
                    Delta per warrant (%)
                  </span>
                  <ReactTooltip
                    id="Deltaper-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Delta per warrant: </b>
                    Delta/Warrant Per Share.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/delta"
                      target="_blank"
                    >
                      &nbsp;Click here&nbsp;
                    </a>
                    to learn more about Delta per warrant.
                  </ReactTooltip>
                </th>
                <td className="val delta_per_wrnt">{deltaperwrnt}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="issuer"
                    data-hasqtip="6"
                    data-tip
                    data-for="Issuer-tooltip"
                  >
                    Issuer
                  </span>
                  <ReactTooltip
                    id="Issuer-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Issuer: </b>
                    The Bank or Securities company that issues the Warrant.
                  </ReactTooltip>
                </th>
                <td className="val issuer_name">{issuername}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="sensitivity"
                    data-hasqtip="7"
                    data-tip
                    data-for="Sensitivity-tooltip"
                  >
                    Sensitivity
                  </span>
                  <ReactTooltip
                    id="Sensitivity-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Sensitivity: </b>
                    Shows you how much the underlying stock or index must move
                    for your warrant to move one "tick" or "Spread". For
                    warrants that cost less than $0.200, one tick is $0.001.
                  </ReactTooltip>
                </th>
                <td className="val sensitivity">{sensitivity}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="exerciseprice"
                    data-hasqtip="8"
                    data-tip
                    data-for="Exercise-tooltip"
                  >
                    Exercise price
                  </span>
                  <ReactTooltip
                    id="Exercise-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Exercise price: </b>
                    The price at which you can buy (calls) or sell (puts) the
                    underlying
                    <br />
                    share or index at expiry.
                  </ReactTooltip>
                </th>
                <td className="val exercise_price">{exerciseprice}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="breakevenpriceatexpiry"
                    data-hasqtip="9"
                    aria-describedby="qtip-9"
                    data-tip
                    data-for="Break-tooltip"
                  >
                    Break even price at expiry
                  </span>
                  <ReactTooltip
                    id="Break-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Break even price at expiry: </b>
                    If you are still holding the warrant at expiry, this is the
                    price that the shares/index must exceed (fall below) for
                    call (puts) for you to make a profit on your purchase price.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/breakevenprice"
                      target="_blank"
                    >
                      &nbsp;Click here&nbsp;
                    </a>
                    here for a more detailed description.
                  </ReactTooltip>
                </th>
                <td className="val breakeven_price">{breakevenprice}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="mostrecenttradedate"
                    data-hasqtip="10"
                    data-tip
                    data-for="Most-tooltip"
                  >
                    Most recent trade date
                  </span>
                  <ReactTooltip
                    id="Most-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Most recent trade date: </b>
                    The last date that the warrant recorded a trade. Note:
                    Warrant do not trade every day, this has implications when
                    calculating price changes.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/howarewarrantspriced"
                      target="_blank"
                    >
                      &nbsp; Click here &nbsp;
                    </a>
                    for more info.
                  </ReactTooltip>
                </th>
                <td className="val TRADE_DATE">{tradedate}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="premium"
                    data-hasqtip="11"
                    data-tip
                    data-for="Premium-tooltip"
                  >
                    Premium (%)
                  </span>
                  <ReactTooltip
                    id="Premium-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Premium: </b>
                    Also refered to as the "Time Value of the Warrant. Note a
                    higher premium could be due to the warrant being longer
                    dated or due to the higher gearing.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/premium"
                      target="_blank"
                    >
                      &nbsp;Click here &nbsp;
                    </a>
                    to learn more about premium.
                  </ReactTooltip>
                </th>
                <td className="val premium">{premium}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="expiry"
                    data-hasqtip="12"
                    data-tip
                    data-for="Expiry-tooltip"
                  >
                    Expiry
                  </span>
                  <ReactTooltip
                    id="Expiry-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Expiry: </b>
                    The date at which the warrant will expire.
                  </ReactTooltip>
                </th>
                <td className="val maturity">{maturity}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="impliedvolatility"
                    data-hasqtip="13"
                    data-tip
                    data-for="Volatility-tooltip"
                  >
                    Volatility (%)
                  </span>
                  <ReactTooltip
                    id="Volatility-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Volatility: </b>
                    The higher the implied volatility, the higher the price of
                    the Warrant. Relates to the volatility and risk of the
                    underlying share/Index.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/impliedvolatility"
                      target="_blank"
                    >
                      &nbsp;Click here&nbsp;
                    </a>
                    to learn more about implied volatility.
                  </ReactTooltip>
                </th>
                <td className="val implied_volatility">{impliedvolatility}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="lasttradingdate"
                    data-hasqtip="14"
                    data-tip
                    data-for="Last-tooltip"
                  >
                    Last trade date
                  </span>
                  <ReactTooltip
                    id="Last-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Last trading date: </b>
                    The last day to buy or sell the warrant. This date is always
                    the 5th trading day prior to the expiry date.
                  </ReactTooltip>
                </th>
                <td className="val last_trading_date">{lasttradingdate}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="theta"
                    data-hasqtip="15"
                    data-tip
                    data-for="Theta-tooltip"
                  >
                    Theta (%)
                  </span>
                  <ReactTooltip
                    id="Theta-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Theta: </b>
                    The daily time decay of the warrant expressed in percentage
                    terms.
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/timedecay"
                      target="_blank"
                    >
                      &nbsp;Click here&nbsp;
                    </a>
                    to learn more about Theta.
                  </ReactTooltip>
                </th>
                <td className="val theta">{theta}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="timetomaturity"
                    data-hasqtip="16"
                    data-tip
                    data-for="Daysto-tooltip"
                  >
                    Days to maturity
                  </span>
                  <ReactTooltip
                    id="Daysto-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Days to maturity: </b>
                    The number of days left until the expiry date of the
                    warrant.
                  </ReactTooltip>
                </th>
                <td className="val days_to_maturity">{daystomaturity}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="intrinsicvalueperdw"
                    data-hasqtip="17"
                    data-tip
                    data-for="Intrinsic-tooltip"
                  >
                    Intrinsic value per warrant
                  </span>
                  <ReactTooltip
                    id="Intrinsic-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Intrinsic value per warrant: </b>
                    This is the difference between the exercise price and the
                    current share price. For call warrant, if the exercise price
                    is below the current share price it has intrinsic value,
                    vice versa for puts. Please
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/intrinsicvalueandtimevalue"
                      target="_blank"
                    >
                      &nbsp;Click here
                    </a>
                  </ReactTooltip>
                </th>
                <td className="val intrinsic_value">{intrinsicvalue}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="warrantpershare"
                    data-hasqtip="18"
                    aria-describedby="qtip-18"
                    data-tip
                    data-for="Warrantpershare-tooltip"
                  >
                    Warrant per share
                  </span>
                  <ReactTooltip
                    id="Warrantpershare-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Warrant per share: </b>
                    The number of warrants that entitles you to one unit of the
                    underlying share or index at expiry.
                  </ReactTooltip>
                </th>
                <td className="val conv_ratio">{convratio}</td>
                <th>
                  <span
                    className="ttip"
                    data-target="moneyness"
                    data-hasqtip="19"
                    data-tip
                    data-for="Moneyness-tooltip"
                  >
                    Moneyness
                  </span>
                  <ReactTooltip
                    id="Moneyness-tooltip"
                    className="search-tooltip"
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    backgroundColor="#F1D031"
                    textColor="#000"
                  >
                    <b>Moneyness: </b>
                    ITM (in-the-money), OTM (out-of-the-money), ATM
                    (at-the-money).
                    <a
                      href="https://www.warrants.com.sg/InvestorAcademy/moneyness"
                      target="_blank"
                    >
                      &nbsp; Click here&nbsp;
                    </a>
                    to learn more about moneyness.
                  </ReactTooltip>
                </th>
                <td className={moneynessClass}>{moneynessdisplaystring}</td>
              </tr>
            </tbody>
          </table>
          <table className="table hidden-md hidden-lg table-closed table-striped">
            <tbody>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="dwsymbol"
                    data-hasqtip="20"
                  >
                    Warrant code
                  </span>
                </th>
                <td className="val ticker">{ticker}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="underlying"
                    data-hasqtip="21"
                  >
                    Underlying
                  </span>
                </th>
                <td className="val underlying_ticker">
                  <a href="/tools/underlying/HSI">{underlyingticker}</a>
                </td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="type" data-hasqtip="22">
                    Type
                  </span>
                </th>
                <td className="val type">{type}</td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="issuer" data-hasqtip="23">
                    Issuer
                  </span>
                </th>
                <td className="val issuer_name">{issuername}</td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="" data-hasqtip="24">
                    Exercise price
                  </span>
                </th>
                <td className="val exercise_price">{exerciseprice}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="mostrecenttradedate"
                    data-hasqtip="25"
                  >
                    Most recent trade date
                  </span>
                </th>
                <td className="val TRADE_DATE">{tradedate}</td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="expiry" data-hasqtip="26">
                    Expiry
                  </span>
                </th>
                <td className="val maturity">{maturity}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="lasttradingdate"
                    data-hasqtip="27"
                  >
                    Last trading date
                  </span>
                </th>
                <td className="val last_trading_date">{lasttradingdate}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="timetomaturity"
                    data-hasqtip="28"
                  >
                    Days to maturity
                  </span>
                </th>
                <td className="val days_to_maturity">{daystomaturity}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="warrantpershare"
                    data-hasqtip="29"
                  >
                    Warrant per share
                  </span>
                </th>
                <td className="val conv_ratio">{convratio}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="effectivegearing"
                    data-hasqtip="30"
                  >
                    Effective gearing (x)
                  </span>
                </th>
                <td className="val effective_gearing">{effectivegearing}</td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="delta" data-hasqtip="31">
                    Delta
                  </span>
                </th>
                <td className="val delta">{delta}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="deltaperdw"
                    data-hasqtip="32"
                  >
                    Delta per warrant
                  </span>
                </th>
                <td className="val delta_per_wrnt">{deltaperwrnt}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="sensitivity"
                    data-hasqtip="33"
                  >
                    Sensitivity
                  </span>
                </th>
                <td className="val sensitivity">{sensitivity}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="breakevenpriceatexpiry"
                    data-hasqtip="34"
                  >
                    Break even price at expiry
                  </span>
                </th>
                <td className="val breakeven_price">{breakevenprice}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="premium"
                    data-hasqtip="35"
                  >
                    Premium(%)
                  </span>
                </th>
                <td className="val premium">{premium}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="impliedvolatility"
                    data-hasqtip="36"
                  >
                    Volatility (%)
                  </span>
                </th>
                <td className="val implied_volatility">{impliedvolatility}</td>
              </tr>
              <tr>
                <th>
                  <span className="ttip" data-target="theta" data-hasqtip="37">
                    Theta (%)
                  </span>
                </th>
                <td className="val theta">{theta}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="intrinsicvalueperdw"
                    data-hasqtip="38"
                  >
                    Intrinsic value per warrant
                  </span>
                </th>
                <td className="val intrinsic_value">{intrinsicvalue}</td>
              </tr>
              <tr>
                <th>
                  <span
                    className="ttip"
                    data-target="moneyness"
                    data-hasqtip="39"
                  >
                    Moneyness
                  </span>
                </th>
                <td className={moneynessClass}>{moneynessdisplaystring}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
class TableGeneral extends Component {
  getData(fieldName, dp = null) {
    if (
      this.props.warrantdata !== null &&
      fieldName in this.props.warrantdata
    ) {
      const value = this.props.warrantdata[fieldName];
      if (dp !== null) {
        return Number(value).toFixed(dp);
      }
      return value;
    }
    return "";
  }

  getTheta() {
    // "last_trading_date": "23 Feb 21"
    const lasttradingdate = this.getData("last_trading_date");
    const currDate = new Date();
    const currDateMS = currDate.getTime();
    if (lasttradingdate.length > 0) {
      const lasttradingdateMS = Date.parse(lasttradingdate);
      const diff = currDateMS - lasttradingdateMS;
      // check if expired
      if (diff <= 24 * 60 * 60 * 1000) {
        return this.getData("theta", 4);
      }
    }
    return "-";
  }

  getMoneynessClass(nc) {
    let cnm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        cnm = "val mny up";
      } else if (value === 2) {
        cnm = "val mny down";
      }
    }
    return cnm;
  }

  getMoneynessName(nc) {
    let nm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        return "ITM";
      } else if (value === 2) {
        return "OTM";
      } else {
        return "ATM";
      }
    }
    return nm;
  }

  render() {
    const hstclose = this.getData("HST_CLOSE");
    const trdprc1 = this.getData("TRDPRC_1");
    const openprc = this.getData("OPEN_PRC");
    const acvol1scaled = this.getData("ACVOL_1_SCALED");
    const high1 = this.getData("HIGH_1");
    const turnoverscaled = this.getData("TURNOVER_SCALED");
    const low1 = this.getData("LOW_1");
    const underlyingprice = this.getData("underlying_price");
    const ticker = this.getData("ticker");
    const effectivegearing = this.getData("effective_gearing");
    const underlyingticker = this.getData("underlying_ticker");
    const delta = this.getData("delta");
    const type = this.getData("type");
    const deltaperwrnt = this.getData("delta_per_wrnt");
    const issuername = this.getData("issuer_name");
    const sensitivity = this.getData("sensitivity");
    const exerciseprice = this.getData("exercise_price");
    const breakevenprice = this.getData("breakeven_price");
    const tradedate = this.getData("TRADE_DATE");
    const premium = this.getData("premium");
    const maturity = this.getData("maturity");
    const impliedvolatility = this.getData("implied_volatility");
    const lasttradingdate = this.getData("last_trading_date");
    const theta = this.getTheta();
    const daystomaturity = this.getData("days_to_maturity") + " days";
    const intrinsicvalue = this.getData("intrinsic_value");
    const convratio = this.getData("conv_ratio");
    const moneyness = this.getData("moneyness");
    const moneynessClass = this.getMoneynessClass(moneyness);
    const moneynessdisplaystring =
      this.getData("percent_moneyness") +
      "% " +
      this.getMoneynessName(moneyness);

    return (
      <div>
        <div id="srtable" style={{ position: "relative" }}>
          <table className="table priorTable">
            <tbody>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Prior
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HST_CLOSE"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {hstclose}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Last trade
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TRDPRC_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {trdprc1}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ border: 0, textAlign: 'left' }}>
                  Open
                </th>
                <td
                  className="srtable-leftval val OPEN_PRC"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {openprc}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Traded volume ('000)
                </th>
                <td className="srtable-rightval val ACVOL_1_SCALED" style={{ textAlign: 'left' }}>
                  {acvol1scaled}
                </td>
              </tr>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left', textAlign: 'left' }}
                >
                  High
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HIGH_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {high1}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Traded value ('000 SGD)
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TURNOVER_SCALED"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {turnoverscaled}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ textAlign: 'left' }}>Low</th>
                <td className="srtable-leftval val LOW_1" style={{ border: 0, textAlign: 'left' }}>
                  {low1}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Underlying price
                </th>
                <td
                  className="srtable-rightval val underlying_price"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {underlyingprice}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
class TermsSummary extends Component {
  getData(data, fieldName) {
    if (data !== null && fieldName in data) {
      return data[fieldName];
    }
    return "";
  }

  // Returning either "upval", "downval", or "" as the CSS class for displaying these up, down or no-change value
  getUpDownClass(nc) {
    if (nc) {
      const ncstring = nc.toString();
      const ncval = Number(nc);

      // Check if 0
      if (ncval === 0) {
        return "";
      }
      // If -nnn ?
      else if (ncstring.length > 1 && ncstring.startsWith("-")) {
        return "val down";
      }
      // If +nnn ?
      else if ((ncstring.length > 1 && ncstring.startsWith("+")) || ncval > 0) {
        return "val up";
      }
    }

    // Any other case, return ""
    return "";
  }

  render() {
    var show = 'table';
    if (this.props.tabNum == 1 || this.props.tabNum == 3) {
      show = 'none'
    }
    const warrantdata = this.props.warrantdata;
    const ticker =
      this.getData(warrantdata, "dsply_nmll") +
      " (" +
      this.getData(warrantdata, "ticker") +
      ")";
    const bidval = this.getData(warrantdata, "BID");
    const bidchg = this.getData(warrantdata, "BID_NETCHNG");
    const bidchgupdown = this.getUpDownClass(bidchg);
    const bidpchg = this.getData(warrantdata, "BID_PCTCHNG") + "%";
    const bidpchgupdown = this.getUpDownClass(bidpchg);

    return (
      <div id="dwsummary" style={{ position: "relative" }}>
        <div className="ricbar">
          <div id="ticker" className="tickerbar bgcolor-03">
            {ticker}
          </div>
          {/* <div>
            <a
              href="/tools/livematrix"
              id="viewlm"
              type="button"
              className="ricbar-btn btn btn-block btn-primary"
            >
              View live matrix
            </a>
          </div> */}
        </div>
        <table id="pricetbl" className="uppertbl" style={{ display: show }}>
          <colgroup>
            <col style={{ backgroundColor: "#E7E8E8", padding: "3px" }} />
            <col
              style={{
                backgroundColor: "#F5F6F7",
                padding: "3px",
                textAlign: "left",
              }}
            />
            <col
              style={{
                backgroundColor: "#F5F6F7",
                padding: "3px",
                textAlign: "right",
              }}
            />
          </colgroup>
          <tbody>
            <tr>
              <td className="uppertbl-col-bid" rowSpan="2" style={{ textAlign: 'left' }}>
                <div className="bidprice lbl">Bid price</div>
                <div id="bidval" className="bidprice val">
                  {bidval}
                </div>
              </td>
              <td className="uppertbl-col-chglbl">Change</td>
              <td id="bidchg" className={"uppertbl-col-chgval " + bidchgupdown}>
                {bidchg}
              </td>
            </tr>
            <tr>
              <td className="uppertbl-col-pchglbl">%Change</td>
              <td
                id="bidpchg"
                className={"uppertbl-col-pchgval " + bidpchgupdown}
              >
                {bidpchg}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

import React from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

import PageHeader from "../../../components/pageHeader/pageHeader";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import tableConfig from "./comparisonConfig";
import WarrantCalculator from "../warrantcalculator/calculator";
import AdvertBox from "../../../components/advert/advert";
import "./comparison.css";

class Underlying extends React.Component {
  constructor(props) {
    super(props);
    this.underlyingOption = null;
  }

  onChange(selected) {
    this.props.onUnderlyingChange(selected);
  }
  componentDidMount() {
    document.title = "Warrant Comparison | Singapore Warrants | Macquarie"
  }
  getUnderlyingOption() {
    if (this.underlyingOption === null) {
      if (this.props.underlying !== null) {
        if ("list" in this.props.underlying) {
          const symbolData = this.props.underlying.list;

          if (Array.isArray(symbolData)) {
            this.underlyingOption = [];
            symbolData.forEach((data) => {
              if ("underlying_ticker" in data) {
                const displayname = MQSGUtil.getUnderlyingTickerDisplayName(
                  data.underlying_ticker,
                  false
                );
                if (displayname !== null) {
                  data["label"] = displayname;
                  data["value"] = data.underlying_ticker;
                  this.underlyingOption.push(data);
                }
              }
            });
          }
        }
      }
    }
    return this.underlyingOption;
  }

  render() {
    const customStyles = {
      menu: (provided) => ({
        ...provided,
        zIndex: 10,
        textAlign: "center",

      }),
      option: (provided, state) => ({
        ...provided,
        color: '#000',
        backgroundColor: '#FFFFFF',
        ':hover': {
          backgroundColor: '#1A73E8',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        justifyContent: 'center'
      })
    };
    return (
      <div id="dropdown-underlying">
        <div>
          <Select
            placeholder="Select underlying"
            options={this.getUnderlyingOption()}
            styles={customStyles}
            onChange={this.onChange.bind(this)}
          // styles={{
          //   option: (provided, state) => ({
          //     ...provided,
          //     textAlign: "center",
          //     // cursor: 'pointer',
          //     // zIndex: 10000,
          //     // backgroundColor: '#FFFFFF',
          //     // color: '#000',
          //     // ':hover': {
          //     //   backgroundColor: '#1A73E8',
          //     // },
          //   }),
          // }}
          />
        </div>
      </div>
    );
  }
}

class WarrantSummary extends React.Component {
  onChangeCheckBox(ticker, ric) {
    const SELECTMAX = 5;
    // first check the ticker is already selected = deselected
    if (this.props.selected.indexOf(ticker) > -1) {
      // no restriction
      this.props.onWarrantChange(ticker, ric);
    } else {
      if (this.count() < SELECTMAX) {
        this.props.onWarrantChange(ticker, ric);
      } else {
        alert("you can only select up to five options");
      }
    }
  }

  isChecked(ticker) {
    if (this.props.selected.indexOf(ticker) > -1) {
      return true;
    }
    return false;
  }

  count() {
    return this.props.selected.length;
  }

  disable() {
    if (this.props.warrantdata === null) {
      return <div className="disabled-area"></div>;
    }
    return <></>;
  }

  onCollapse() {
    this.props.onSummaryCollapse();
  }

  getWarrantList() {
    let warrantList = null;
    if (
      !(this.props.warrantdata == null || this.props.warrantdata.length === 0)
    ) {
      warrantList = this.props.warrantdata.map((warrant) => {
        const link = "/tools/livematrix/" + warrant.ticker;
        var tencentPrice = null;
        var ratio = null;
        if ((warrant.underlying_name.indexOf('TENCENT') != -1)) {
          tencentPrice = Number(warrant.exercise_price).toFixed(3)
          ratio = Number(warrant.conv_ratio).toFixed(3)
        } else {
          tencentPrice = warrant.exercise_price;
          ratio = warrant.conv_ratio;
        }
        return (
          <tr className="data-row" key={warrant.dsply_name}>
            <td style={{ textAlign: "left" }}>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={this.isChecked(warrant.ticker)}
                    onChange={this.onChangeCheckBox.bind(
                      this,
                      warrant.ticker,
                      warrant.ric
                    )}
                  />
                  <Link to={link} className="color-hyperlink">
                    {warrant.dsply_name}
                  </Link>
                </label>
              </div>
            </td>
            <td>{warrant.issuer_code}</td>
            {/* <td>{warrant.exercise_price}</td>
            <td>{warrant.conv_ratio}</td> */}
            <td>{tencentPrice}</td>
            <td>{ratio}</td>
            <td>{warrant.last_trading_date}</td>
          </tr>
        );
      });
    }
    return warrantList;
  }

  render() {
    let panelCollapseClass = "panel-collapse collapse";
    let glyphiconTriangle = "glyphicon-triangle-bottom";
    if (!this.props.collapse) {
      panelCollapseClass += " in";
      glyphiconTriangle = "glyphicon-triangle-top";
    }

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          {/* <a data-toggle="collapse" data-parent="#accordion" href="#collapse_dwcompare2" 
                    className="collapsed" aria-expanded="false">
                    <span className="glyphicon pull-right glyphicon-triangle-bottom" onClick={this.onCollapse.bind(this)}></span>
                    <h4 id="collapse_title2" className="panel-title text-center">
                      Select warrant: <span className="bold">{this.props.count}</span>
                    </h4>
                </a> */}
          {this.disable()}
          <span
            className={"glyphicon pull-right " + glyphiconTriangle}
            style={{ cursor: "pointer" }}
            onClick={this.onCollapse.bind(this)}
          ></span>
          <h4 id="collapse_title2" className="panel-title text-center">
            Select warrant: <span className="bold">{this.count()}</span>
          </h4>
        </div>
        <div
          id="collapse_dwcompare2"
          className={panelCollapseClass}
          style={{ position: "relative" }}
        >
          <div>
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th className="text-center">Warrant name</th>
                  <th className="text-center">Issuer</th>
                  <th className="text-center">Exercise price</th>
                  <th className="text-center">Exercise ratio</th>
                  <th className="text-center">Last trading date</th>
                </tr>
                <tr className="data-row" style={{ display: "none" }}></tr>
                {this.getWarrantList()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

class WarrantComparisonTable extends React.Component {
  getComparisonRow() {
    if (this.props.config !== null) {
      // for each table field/column
      if ("fields" in this.props.config) {
        const rows = this.props.config["fields"].map((field, fieldIndex) => {
          const columns = [];

          // header
          const tooltipID = field.className + "-tooltip";
          const columnHeader = (
            <th key={field.name}>
              <span data-tip data-for={tooltipID} className="ttip">
                {field.name}
              </span>
              <ReactTooltip
                id={tooltipID}
                className="comparison-tooltip"
                delayHide={100}
                place="right"
                type="warning"
                effect="solid"
              >
                {field.tooltip()}
              </ReactTooltip>
            </th>
          );
          columns.push(columnHeader);

          // data
          const warrantdata = this.props.warrantdata;
          if (warrantdata != null && warrantdata.length > 0) {
            warrantdata.forEach((warrant, dataIndex) => {
              columns.push(field.columnData(warrant, dataIndex));
            });
          }
          const row = (
            <tr key={field.className} className={field.className}>
              {columns}
            </tr>
          );
          return row;
        });
        return rows;
      }
    }

    return null;
  }

  render() {
    return (
      <div id="comparetbl" style={{ overflow: 'scroll' }}>
        <table className="table table-striped  tablecom">
          <tbody>{this.getComparisonRow()}</tbody>
        </table>
      </div>
    );
  }
}

class Advertisement extends React.Component {
  render() {
    return (
      <div id="subadvertisment" className="section advertising-banner">
        <div className="swiper-container swiper-container-horizontal">
          {/* <div className="swiper-wrapper" style={{transform: "translate3d(-754px, 0px, 0px)", transitionDuration: "0ms"}}>
                    <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" 
                          data-swiper-slide-index="1" style={{width: "377px"}}>                                                                
                        <a target="_blank" test-data="true" rel="noreferrer"
                          href="http://www.warrants.com.sg/marketnews/highlight?cat=todayhighlight&amp;amp;amp;id=5525">
                          <img style={{width: "100%", height: "auto"}} src="/photo?adv_id=117" alt=""/>
                        </a>                                
                    </div>                      
                </div>
                <div className="swiper-slide swiper-slide-prev swiper-slide-duplicate-next" data-swiper-slide-index="0" style={{width: "377px"}}> 
                    <a target="_blank" test-data="true" rel="noreferrer"
                        href="http://www.warrants.com.sg/education/getstarted?utm_source=rotate_banner&amp;utm_medium=website&amp;utm_campaign=rotate_banner&amp;">
                        <img style={{width: "100%", height: "auto"}} src="/photo?adv_id=113" alt="" />
                    </a>
                </div>                    
                <div className="swiper-slide swiper-slide-active" data-swiper-slide-index="1" style={{width: "377px"}}>                                
                    <a target="_blank" test-data="true" rel="noreferrer"
                        href="http://www.warrants.com.sg/marketnews/highlight?cat=todayhighlight&amp;amp;amp;id=5525">
                        <img style={{width: "100%", height: "auto"}} src="/photo?adv_id=117" alt="" />
                    </a>                                
                </div>
                <div className="swiper-slide swiper-slide-duplicate swiper-slide-next swiper-slide-duplicate-prev" data-swiper-slide-index="0" style={{width: "377px"}}>
                    <a target="_blank" test-data="true" rel="noreferrer"
                        href="http://www.warrants.com.sg/education/getstarted?utm_source=rotate_banner&amp;utm_medium=website&amp;utm_campaign=rotate_banner&amp;">
                        <img style={{width: "100%", height: "auto"}} src="/photo?adv_id=113" alt="" />
                    </a>                                
                </div>
                <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                  <span className="swiper-pagination-bullet"></span>
                  <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                </div>*/}
        </div>
      </div>
    );
  }
}

class QuickLink extends React.Component {
  // suppose after user selected warrant > corresponding underlying information need to feed into this component
  // uprice_3
  // wprice_3
  render() {
    return (
      <div
        className="section qlink-section"
        id="minidwcalc"
        style={{ position: "relative" }}
      >
        <div className="page-header">
          <h2 className="small">Warrant Calculator</h2>
        </div>
        <p>Move the sliders to estimate the bid price of the warrant.</p>
        <div id="wcalbox">
          <table className="hidden-xs hidden-sm">
            <tbody>
              <tr className="wcalbox-row-lbl">
                <td className="wcalbox-col-lbl">Underlying bid price</td>
                <td className="wcol-col-empty"></td>
              </tr>
              <tr className="wcalbox-row-ctr">
                <td className="wcalbox-col-input">
                  <input
                    className="wcalbox-input underlying_bid_price"
                    type="text"
                    maxLength="10"
                    name="bdval"
                    style={{ textAlign: "center" }}
                    defaultValue="0"
                  />
                </td>
                <td className="wcalbox-col-slider">
                  <div className="slider slider-horizontal" id="">
                    <div className="slider-track">
                      <div
                        className="slider-track-low"
                        style={{ left: "0px", width: "0%" }}
                      ></div>
                      <div
                        className="slider-selection"
                        style={{ left: "0%", width: "0%" }}
                      ></div>
                      <div
                        className="slider-track-high"
                        style={{ right: "0px", width: "100%" }}
                      ></div>
                    </div>
                    <div
                      className="tooltip tooltip-main top hide"
                      role="presentation"
                      style={{ left: "0%", marginLeft: "0px" }}
                    >
                      <div className="tooltip-arrow"></div>
                      <div className="tooltip-inner">0</div>
                    </div>
                    <div
                      className="tooltip tooltip-min top hide"
                      role="presentation"
                    >
                      <div className="tooltip-arrow"></div>
                      <div className="tooltip-inner"></div>
                    </div>
                    <div
                      className="tooltip tooltip-max hide top"
                      role="presentation"
                    >
                      <div className="tooltip-arrow"></div>
                      <div className="tooltip-inner"></div>
                    </div>
                    <div
                      className="slider-handle min-slider-handle round"
                      role="slider"
                      aria-valuemin="0"
                      aria-valuemax="20"
                      aria-valuenow="0"
                      tabIndex="0"
                      style={{ left: "0%" }}
                    ></div>
                    <div
                      className="slider-handle max-slider-handle round hide"
                      role="slider"
                      aria-valuemin="0"
                      aria-valuemax="20"
                      aria-valuenow="0"
                      tabIndex="0"
                      style={{ left: "0%" }}
                    ></div>
                  </div>
                  <input
                    className="wcalbox-slider underlying_bid_price"
                    data-slide-id="ups-slider"
                    type="text"
                    data-slider-min="0"
                    data-slider-max="20"
                    data-slider-step="1"
                    data-slider-value="0"
                    data-slider-tooltip="hide"
                    data-value="0"
                    defaultValue="0"
                    style={{ display: "none" }}
                  />
                </td>
              </tr>
              <tr className="wcalbox-row-lbl">
                <td className="wcalbox-col-lbl">Date</td>
                <td></td>
              </tr>
              <tr className="wcalbox-row-ctr">
                <td
                  className="wcalbox-col-input"
                  style={{ position: "relative" }}
                >
                  <input
                    className="wcalbox-input today_date"
                    type="text"
                    name="dateval"
                    readOnly=""
                    style={{ textAlign: "center" }}
                    defaultValue="0"
                  />
                </td>
                <td className="wcalbox-col-slider">
                  <div className="slider slider-horizontal" id="dt-slider">
                    <div className="slider-track">
                      <div
                        className="slider-track-low"
                        style={{ left: "0px", width: "0%" }}
                      ></div>
                      <div
                        className="slider-selection"
                        style={{ left: "0%", width: "0%" }}
                      ></div>
                      <div
                        className="slider-track-high"
                        style={{ right: "0px", width: "100%" }}
                      ></div>
                    </div>
                    <div
                      className="tooltip tooltip-main top hide"
                      role="presentation"
                      style={{ left: "0%", marginLeft: "0px" }}
                    >
                      <div className="tooltip-arrow"></div>
                      <div className="tooltip-inner">1611676800000</div>
                    </div>
                    <div
                      className="tooltip tooltip-min top hide"
                      role="presentation"
                    >
                      <div className="tooltip-arrow"></div>
                      <div className="tooltip-inner"></div>
                    </div>
                    <div
                      className="tooltip tooltip-max hide top"
                      role="presentation"
                    >
                      <div className="tooltip-arrow"></div>
                      <div className="tooltip-inner"></div>
                    </div>
                    <div
                      className="slider-handle min-slider-handle round"
                      role="slider"
                      aria-valuemin="1611676800000"
                      aria-valuemax="20"
                      aria-valuenow="1611676800000"
                      tabIndex="0"
                      style={{ left: "0%" }}
                    ></div>
                    <div
                      className="slider-handle max-slider-handle round hide"
                      role="slider"
                      aria-valuemin="1611676800000"
                      aria-valuemax="20"
                      aria-valuenow="1611676800000"
                      tabIndex="0"
                      style={{ left: "0%" }}
                    ></div>
                  </div>
                  <input
                    className="wcalbox-slider today_date"
                    data-slider-id="dt-slider"
                    type="text"
                    data-slider-min="0"
                    data-slider-max="20"
                    data-slider-step="1"
                    data-slider-value="0"
                    data-slider-tooltip="hide"
                    data-value="1611676800000"
                    defaultValue="1611676800000"
                    style={{ display: "none" }}
                  />
                </td>
              </tr>
              <tr className="wcalbox-row-lbl">
                <td className="wcalbox-col-lbl">Volatility (%)</td>
                <td></td>
              </tr>
              <tr className="wcalbox-row-ctr">
                <td className="wcalbox-col-input">
                  <input
                    className="wcalbox-input implied_volatility"
                    type="text"
                    name="ivval"
                    maxLength="6"
                    style={{ textAlign: "center" }}
                    defaultValue="0"
                  />
                </td>
                <td className="wcalbox-col-slider">
                  <div className="slider slider-horizontal" id="iv-slider">
                    <div className="slider-track">
                      <div
                        className="slider-track-low"
                        style={{ left: "0px", width: "0%" }}
                      ></div>
                      <div
                        className="slider-selection"
                        style={{ left: "0%", width: "0%" }}
                      ></div>
                      <div
                        className="slider-track-high"
                        style={{ right: "0px", width: "100%" }}
                      ></div>
                    </div>
                    <div
                      className="tooltip tooltip-main top hide"
                      role="presentation"
                      style={{ left: "0%", marginLeft: "0px" }}
                    >
                      <div className="tooltip-arrow"></div>
                      <div className="tooltip-inner">10</div>
                    </div>
                    <div
                      className="tooltip tooltip-min top hide"
                      role="presentation"
                    >
                      <div className="tooltip-arrow"></div>
                      <div className="tooltip-inner"></div>
                    </div>
                    <div
                      className="tooltip tooltip-max hide top"
                      role="presentation"
                    >
                      <div className="tooltip-arrow"></div>
                      <div className="tooltip-inner"></div>
                    </div>
                    <div
                      className="slider-handle min-slider-handle round"
                      role="slider"
                      aria-valuemin="10"
                      aria-valuemax="150"
                      aria-valuenow="10"
                      tabIndex="0"
                      style={{ left: "0%" }}
                    ></div>
                    <div
                      className="slider-handle max-slider-handle round hide"
                      role="slider"
                      aria-valuemin="10"
                      aria-valuemax="150"
                      aria-valuenow="10"
                      tabIndex="0"
                      style={{ left: "0%" }}
                    ></div>
                  </div>
                  <input
                    className="wcalbox-slider implied_volatility"
                    data-slider-id="iv-slider"
                    type="text"
                    data-slider-min="0"
                    data-slider-max="20"
                    data-slider-step="1"
                    data-slider-value="0"
                    data-slider-tooltip="hide"
                    data-value="10"
                    defaultValue="10"
                    style={{ display: "none" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="bold_title">Results</h3>
        <table id="ivtable" className="table table-striped-inverse">
          <thead>
            <tr className="row_0">
              <th>Underlying bid price</th>
              <th>Estimated Warrant bid price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="uprice_3 active" style={{ textAlign: "center" }}>
                -
              </td>
              <td className="wprice_3 active" style={{ textAlign: "center" }}>
                -
              </td>
            </tr>
          </tbody>
        </table>
        <div className="section-btn-wapper">
          {/* <a href="/tools/warrantcalculator" style={{minWidth: "190px"}} 
                    onclick="gtag('event', 'GoToWarrantCalculator', {'event_category' : 'Warrant calculator widget',});" 
                    type="button" className="btn btn-primary">Visit the Warrant calculator for more info!
                </a> */}
        </div>
        <div className="loadingindicator"></div>
      </div>
    );
  }
}

class WarrantComparison extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: { underlying: null, label: null, warrant: [], ric: [] },
      underlying: null,
      collapse: true,
      warrantdata: null,
    };
    this.page = "comparison";
  }

  componentDidMount() {
    this.onFetchSymbol();
  }

  initSelection(symbols) {
    const lastselection = MQSGUtil.getLastSelection(this.page);
    let selectedsymbol = null;
    let initsymbol = null;
    if (symbols !== null && Array.isArray(symbols)) {
      symbols.forEach((symbol) => {
        if ("underlying_ric" in symbol) {
          if (initsymbol === null) {
            initsymbol = symbol;
          }
          if (lastselection === symbol.underlying_ric) {
            selectedsymbol = symbol;
          }
        }
      });
    }
    if (selectedsymbol !== null) {
      initsymbol = selectedsymbol;
    }
    if (initsymbol !== null) {
      MQSGUtil.updateLastSelection(this.page, initsymbol.ric);
      if ("underlying_ticker" in initsymbol && "underlying_ric" in initsymbol) {
        const displayname = MQSGUtil.getUnderlyingTickerDisplayName(
          initsymbol.underlying_ticker,
          true
        );
        if (displayname !== null) {
          const option = { ...initsymbol };
          option["label"] = displayname;
          option["value"] = initsymbol.underlying_ticker;
          return option;
        }
      }
    }
    return null;
  }

  onFetchStaticSymbol() {
    const staticData = MQSGUtil.getStaticData("WarrantComparison");
    this.setState({ underlying: staticData.underlyingStaticData });
  }

  onFetchSymbol() {
    if (MQSGUtil.isUsingStaticData("WarrantComparison")) {
      this.onFetchStaticSymbol();
      return;
    }

    const url = MQSGUtil.getAPIBasePath() + "/UnderlyingRankingJSON?mode=2";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ underlying: result });
        },
        (error) => {
          this.setState({ underlying: [] });
        }
      );
  }

  onHandleData(jsonData) {
    let warrantdata = null;
    if ("comparison" in jsonData) {
      if (Array.isArray(jsonData["comparison"])) {
        const len = jsonData["comparison"].length;
        if (len > 1) {
          // 1st element is underlying information not the warrant
          warrantdata = jsonData["comparison"].slice(1);
        }
      }
    }
    return warrantdata;
  }

  onFetchStaticData(underlying) {
    const staticData = MQSGUtil.getStaticData("WarrantComparison");
    if (underlying in staticData.comparisonStaticData) {
      const warrantdata = this.onHandleData(
        staticData.comparisonStaticData[underlying]
      );
      this.setState({ warrantdata, collapse: false });
    } else {
      this.setState({ warrantdata: null });
    }
  }

  onFetchData(underlying) {
    if (MQSGUtil.isUsingStaticData("WarrantComparison")) {
      setTimeout(() => {
        this.onFetchStaticData(underlying);
      }, 100); // pretend fetch
      return;
    }
    const url =
      MQSGUtil.getAPIBasePath() +
      "/ComparisonDataJSON?type=u&ric=" +
      underlying;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const warrantdata = this.onHandleData(result);
          this.setState({ warrantdata, collapse: false });
        },
        (error) => {
          this.setState({ warrantdata: null });
        }
      );
  }

  onUnderlyingChange(option) {
    if (option.underlying_ric !== this.state.underlying) {
      const underlying = option.underlying_ric;
      const label = option.label;
      const selected = { underlying, label, warrant: [], ric: [] };
      this.setState({ selected, warrantdata: null });
      this.onFetchData(underlying);
      MQSGUtil.updateLastSelection(this.page, underlying);
    }
  }

  onSummaryCollapse() {
    this.setState({ collapse: !this.state.collapse });
  }

  onWarrantChange(ticker, ric) {
    const selected = this.state.selected;
    const warrantSelected = selected.warrant.slice();
    const ricSelected = selected.ric.slice();
    const findIndex = warrantSelected.indexOf(ticker);
    if (findIndex > -1) {
      // unchecked selection
      warrantSelected.splice(findIndex, 1);
      ricSelected.splice(findIndex, 1);
    } else {
      // new added selection
      warrantSelected.push(ticker);
      ricSelected.push(ric);
    }
    const selectedNew = { ...selected };
    selectedNew.warrant = warrantSelected;
    selectedNew.ric = ricSelected;
    this.setState({ selected: selectedNew });
  }

  getSelectedWarrantData() {
    const warrantDataSelected = [];
    this.state.selected.warrant.forEach((ticker) => {
      const warrantdata = this.state.warrantdata.find((data) => {
        return data.ticker === ticker;
      });
      if (typeof warrantdata != "undefined") {
        warrantdata["label"] = this.state.selected.label;
        warrantDataSelected.push(warrantdata);
      }
    });
    return warrantDataSelected;
  }
  childMethod() {
    if (this.refs.child) {
      this.refs.child.onClickTradeWarrantsHere()
    }
  }
  render() {
    let ric = null;
    if (
      typeof this.state.selected !== "undefined" &&
      this.state.selected !== null
    ) {
      if ("ric" in this.state.selected) {
        const ricCount = this.state.selected.ric.length;
        if (ricCount > 0) {
          ric = this.state.selected.ric[ricCount - 1];
        }
      }
    }
    var upTime = null;
    if (this.state.warrantdata != null) {
      upTime = this.state.warrantdata[0].update_time;
    }
    return (
      <div onClick={() => this.childMethod()}>
        <div id="dwcompare" className="pageobj">
          <BreadcrumbBar
            link="/home"
            group="Warrant tools"
            title="Warrant comparison"
          />
          <div className="stripe-bg"></div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <div className="section">
                  <PageHeader title="Warrant comparison" ref="child" />
                  <p>Compare warrants and their key factors. </p>
                  <p className="small">
                    You may compare up to five warrants at a time
                  </p>
                  <div className="panel-group" id="accordion">
                    {/* <div className="panel panel-default"></div>*/}
                    <Underlying
                      underlying={this.state.underlying}
                      onUnderlyingChange={(option) =>
                        this.onUnderlyingChange(option)
                      }
                    />
                    <WarrantSummary
                      warrantdata={this.state.warrantdata}
                      collapse={this.state.collapse}
                      selected={this.state.selected.warrant}
                      onSummaryCollapse={() => this.onSummaryCollapse()}
                      onWarrantChange={(ticker, ric) =>
                        this.onWarrantChange(ticker, ric)
                      }
                    />
                  </div>
                  {/* <div id="orientationcontainer" className="show-xs-portrait">
                            <img src=" /img/bullet/rotate.png" alt=""/>
                            <span style={{marginLeft: "10px"}}>Rotate to view more</span>
                        </div> */}
                  <WarrantComparisonTable
                    config={tableConfig}
                    warrantdata={this.getSelectedWarrantData()}
                  />
                  <p className="small">
                    Last updated: <span>{upTime}</span>
                  </p>
                  <hr className="hr-hmgbm" />
                  <p className="small">
                    All market data is delayed 15 mins unless otherwise
                    indicated by timestamp.
                  </p>
                </div>
              </div>
              <div className="hidden-xs hidden-sm col-md-4">
                {/* <script src="https://www.youtube.com/iframe_api" id="youtubeAPI"></script>
                    <Advertisement />
                    <QuickLink /> */}
                <AdvertBox />
                {/* <WarrantCalculator isRightPanel={true} ric={ric} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WarrantComparison;

// 3. Right Side Panel
// 3.1 Advertisement
// 3.2 QuickLink - Warrant Calculator
// 4. underlying - used react-select    - Done
// 5. Max Five selection                - Done
// 6. Table style not correct
// 7. Moneyness value
// 8. Last updated: 11 Feb 21 16:54
